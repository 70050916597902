import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent, DialogContentText, DialogActions } from "@mui/material";

import { ref, onValue, remove } from 'firebase/database';
import { database } from '../../firebaseConfig';
import { AuthContext } from "../../providers/AuthContext";
import { useTranslation } from 'react-i18next';

import DownloadIcon from '@mui/icons-material/Download';

import CampimetryPDF from "../../components/PDFdocuments/CampimetryPDF";


function descendingComparator(a, b, orderBy) {
  // Si el valor no es una cadena, ordena como antes
  if (typeof b[orderBy] === 'string' && typeof a[orderBy] === 'string') {
    return b[orderBy].localeCompare(a[orderBy], 'es', { sensitivity: 'base' });
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// region Table Head
function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected } = props;
  const { t } = useTranslation();

  const headCells = [
    {
      id: 'dateId',
      numeric: false,
      disablePadding: true,
      label: t("historicalTerms.completionDate"),
    },
    {
      id: 'targetEye',
      numeric: false,
      disablePadding: false,
      label: t("common.eye"),
    },
    {
      id: 'campimetryPosition',
      numeric: false,
      disablePadding: false,
      label: t("historicalTerms.campimetrySection"),
    },
    {
      id: 'contrastPattern',
      numeric: false,
      disablePadding: false,
      label: t("historicalTerms.campimetryContrast"),
    },
    {
      id: 'success',
      numeric: false,
      disablePadding: false,
      label: t("historicalTerms.success"),
    },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {numSelected > 0 ?
            <Checkbox
              color="primary"
              disabled={numSelected == 0}
              checked={false}
              indeterminate={numSelected > 0}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
            : null
          }
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            <strong>{headCell.label}</strong>
          </TableCell>
        ))}
        <TableCell padding="checkbox"></TableCell>
      </TableRow>
    </TableHead>
  );
}

// region Table Toolbar
function EnhancedTableToolbar(props) {
  const { numSelected, handleDeleteSelected, handleDownloadSelected } = props;
  const { t } = useTranslation();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} {t("forms.selected")}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {t("historicalTerms.campimetry")}
        </Typography>
      )}

      {numSelected > 0 ? (
        <React.Fragment>
          <Tooltip title={t("historicalTerms.downloadSelected")}>
            <IconButton>
              <DownloadIcon onClick={handleDownloadSelected} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("historicalTerms.deleteSelected")}>
            <IconButton>
              <DeleteIcon onClick={handleDeleteSelected} />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ) : null}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleDeleteSelected: PropTypes.func.isRequired,
  handleDownloadSelected: PropTypes.func.isRequired,
};

// region Main Table
export default function CampimetryHistoryTable() {
  const { t } = useTranslation();

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('dateId');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);

  const { currentUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const { uid } = useParams();

  // region PDF generation
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const [actualDatesSel, setActualDatesSel] = useState([]);
  const startGeneratingPDF = (actualDateSel) => { setActualDatesSel([actualDateSel]); setGeneratingPDF(true); }
  const startGeneratingCombinedPDF = () => {
    if (selected.length !== 0) {
      setActualDatesSel(selected); setGeneratingPDF(true);
    }
  }
  const stopGeneratingPDF = () => setGeneratingPDF(false);
  // endregion

  // region Table loading
  useEffect(() => {

    setIsLoading(true);

    const dataRef = ref(database, `historical/${currentUser.uid}/campimetry/${uid}`);

    onValue(dataRef, (snapshot) => {
      const historicalData = snapshot.val();
      if (!historicalData) {
        setRows([]);
        setIsLoading(false);
        return;
      }
      const mappedData = Object.keys(historicalData).map(dateId =>
        createData(dateId, historicalData[dateId])
      );
      setRows(mappedData);
      setIsLoading(false);
    });
  }, [currentUser]);

  function createData(dateId, data) {
    const campimetryPosition = data.campimetry_position;
    const campimetryTest = data.test_type;
    const contrastPattern = data.contrast_pattern;
    const targetEye = data.target_eye;
    const success = data.success;

    return {
      dateId,
      campimetryPosition,
      campimetryTest,
      contrastPattern,
      targetEye,
      success
    };
  }
  // endregion

  // region Table checkbox
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    // si no está se añade
    if (selectedIndex === -1) {
      // antes de añadirlo hay que comprobar si se puede, ya que si hay algo seleccionado, tiene que tener el mismo ojo y diferente sección
      const checkCorrect = checkMultipleSelection(id);
      if (checkCorrect) {
        newSelected = newSelected.concat(selected, id);
      }
      else {
        newSelected = newSelected.concat(selected);
      }
    }
    // si está se elimina dependiendo de su posicion 
    else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const checkMultipleSelection = (newId) => {
    const newRow = rows.find(row => row.dateId === newId);
    // check all selected rows
    for (let i = 0; i < selected.length; i++) {
      const selectedRow = rows.find(row => row.dateId === selected[i]);
      // if the eye is different, return false
      if (selectedRow.targetEye !== newRow.targetEye) {
        handleSnackbarOpen("No se pueden seleccionar campimetrías de ojos diferentes");
        return false;
      }
      // if the section is the same, return false
      if (selectedRow.campimetryPosition === newRow.campimetryPosition) {
        handleSnackbarOpen("No se pueden seleccionar secciones iguales");
        return false;
      }
    }

    return true;
  };
  const handleSelectAllClick = (event) => {
    setSelected([]);
  };
  // endregion

  // region Table index
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );
  // endregion

  // region Format table content
  const formatDate = (originalDate) => {
    if (originalDate === "-") {
      return "-";
    }

    // date is in format YYYYMMDD-HHMMSS and we want to show DD-MM-YYYY (hh:mm)
    const date = originalDate.split("-")[0];
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    const time = originalDate.split("-")[1];
    const hour = time.substring(0, 2);
    const minutes = time.substring(2, 4);
    return `${day}-${month}-${year} (${hour}:${minutes})`;
  }

  const formatEye = (eye) => {
    if (eye === 0) {
      return t("common.left-o");
    }
    if (eye === 1) {
      return t("common.right-o");
    }
    return t("common.both");
  }

  const formatSuccess = (success) => {
    if (success) {
      return <React.Fragment><CheckIcon color="success" /></React.Fragment>;
    }
    return <React.Fragment><CloseIcon color="error" /></React.Fragment>;
  }

  const getDirection = (position) => {
    switch (position) {
      case "up":
      case "up_25_degrees": return t('common.down');
      case "down":
      case "down_25_degrees": return t('common.up');
      case "left":
      case "left_25_degrees": return t('common.right');
      case "right":
      case "right_25_degrees": return t('common.left');
      default: return "";
    }
  };

  const getDegrees = (testType, defaultDegrees) => {
    switch (testType) {
      case "center_30_degrees": return t('campimetry.thirtyDegrees');
      case "center_20_degrees": return t('campimetry.twentyDegrees');
      case "center_10_degrees": return t('campimetry.tenDegrees');
      case "peripherical_complete_120":
      case "up":
      case "down":
      case "left":
      case "right": return t('campimetry.from30to60Degrees');
      case "peripherical_complete_110":
      case "up_25_degrees":
      case "down_25_degrees":
      case "left_25_degrees":
      case "right_25_degrees": return t('campimetry.from30to55Degrees');
      default: return defaultDegrees;
    }
  };

  const formatPosition = (position, testType) => {
    if (!testType) {
      return position === "center" ? t('campimetry.center') : getDirection(position);
    }

    const direction = ["up", "down", "left", "right", "up_25_degrees", "down_25_degrees", "left_25_degrees", "right_25_degrees"].includes(position) ? getDirection(position) : t('campimetry.center');
    const degrees = getDegrees(testType, "");

    return `${direction} - ${degrees}`.trim();
  };

  const formatContrastPattern = (contrastPattern) => {
    switch (contrastPattern) {
      case "0-1": return t('campimetry.ceroStrategy');
      case "high-medium-low": return t('campimetry.highMediumLow');
      default: return contrastPattern;
    }
  };
  // endregion

  // region Snackbar logic
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // endregion

  // region Delete logic
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const descriptionDeleteDialogRef = useRef(null);
  React.useEffect(() => {
    if (openDeleteDialog) {
      const { current: descriptionDeleteDialog } = descriptionDeleteDialogRef;
      if (descriptionDeleteDialog !== null) {
        descriptionDeleteDialog.focus();
      }
    }
  }, [openDeleteDialog]);

  const startDeletingSelected = () => {
    // vamos a eliminar las campimetrías seleccionadas de firebase
    if (selected.length !== 0) {
      for (let i = 0; i < selected.length; i++) {
        const dateId = selected[i];
        if (dateId != null && dateId !== "-" && dateId !== "") {
          const dataRef = ref(database, `historical/${currentUser.uid}/campimetry/${uid}/${dateId}`);
          try {
            remove(dataRef);
          } catch (error) {
            console.error("Error removing document: ", error);
          }
        }
      }
      setSelected([]);
      setOpenDeleteDialog(false);
    }
  };
  // endregion

  // region HTML
  return (
    <React.Fragment >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Paper sx={{ width: '95%', maxWidth: 1500, mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} handleDeleteSelected={handleOpenDeleteDialog} handleDownloadSelected={startGeneratingCombinedPDF} />
          <TableContainer>
            <Table
              sx={{ minWidth: 350 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                onSelectAllClick={handleSelectAllClick}
                numSelected={selected.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.dateId);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      //hover
                      //onClick={(event) => handleClickRow(event, row.id)}
                      //role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.dateId}
                      selected={isItemSelected}
                    //sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          onClick={(event) => {
                            event.stopPropagation(); // Evita que el evento de clic se propague a la TableRow
                            handleClick(event, row.dateId); // Llama a la función específica del Checkbox
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        width="20%"
                      >
                        {formatDate(row.dateId)}
                      </TableCell>
                      <TableCell width="10%">{formatEye(row.targetEye)}</TableCell>
                      <TableCell width="25%">{formatPosition(row.campimetryPosition, row.campimetryTest)}</TableCell>
                      <TableCell width="25%">{formatContrastPattern(row.contrastPattern)}</TableCell>
                      <TableCell width="15%">{formatSuccess(row.success)}</TableCell>
                      <TableCell width="5%">
                        <IconButton color="primary">
                          <CloudDownloadIcon onClick={() => startGeneratingPDF(row.dateId)} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('forms.rowsPerPage')}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('forms.of')} ${count}`}
          />
        </Paper>
      )}

      {/* Snackbar for warning messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        sx={{
          marginLeft: 15,
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t("historicalTerms.deleteTest")}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionDeleteDialogRef}
            tabIndex={-1}
          >
            {t("historicalTerms.deleteQuestion")}
            <br />
            <em>{t("historicalTerms.deleteInfo")}</em>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>
            {t('close')}
          </Button>
          <Button color="error" variant="contained" onClick={startDeletingSelected}>
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>

      <CampimetryPDF
        isGenerating={generatingPDF}
        stopGenerating={stopGeneratingPDF}
        userUID={currentUser.uid}
        patientUID={uid}
        datesSel={actualDatesSel}
      >
      </CampimetryPDF>
    </React.Fragment>
  );
}
// endregion
