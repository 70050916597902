import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { createTheme, ThemeProvider, Typography, Box, Divider, Grid, Modal, Snackbar, CircularProgress, Alert } from "@mui/material";

import React, { useRef, useState, useEffect } from "react";
import jsPDF from "jspdf";
import PDFForm from "../../components/dialogForms/PDFForm";
import logoImage from "../../assets/dicoptProLogo.png";
import { useTranslation } from 'react-i18next';
import { ref, get } from 'firebase/database';
import { database } from '../../firebaseConfig';
import axios, { formToJSON } from "axios";

const theme = createTheme({
  typography: {
    body2: {
      fontSize: '0.7rem', // Ajusta el tamaño de la letra aquí
    },
  },
});

const CampimetryPDF = ({
  isGenerating,
  stopGenerating,
  userUID,
  patientUID,
  datesSel,
}) => {

  const [patientData, setPatientData] = useState(null);
  const [formData, setFormData] = useState(null);
  const [campimetryData, setCampimetryData] = useState(null);

  const [isPDFPopupOpen, setIsPDFPopupOpen] = useState(false);
  const [isModalLoadingPDF, setIsModalLoadingPDF] = useState(false);
  const [letGeneratePDF, setLetGeneratePDF] = useState(false);
  const reportTemplateRef = useRef(null);

  const { t } = useTranslation();

  const API_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_URL;

  // region PDF Popup logic
  // Lógica para generar el PDF, primero abrimos el formulario para obtener los datos necesarios
  useEffect(() => {
    if (isGenerating) {
      setIsPDFPopupOpen(true);
    }
  }, [isGenerating]);

  const handlePopupClose = () => {
    setIsPDFPopupOpen(false);
    stopGenerating();
  };

  // si se confirma el formulario, se obtienen los datos del paciente y se genera el PDF
  const handleSubmitFormData = (formData) => {
    setIsPDFPopupOpen(false);
    setFormData(formData);
    setIsModalLoadingPDF(true);

    // lanzamos asincrono esto para que se muestre el modal de carga
    generatePDF();
  };
  // endregion

  // region PDF load data
  const fetchPatientData = async () => {
    // patient data is in realtime database with uid as key
    // Define la referencia a los datos que necesitas (ajusta la ruta según tu estructura)
    const dataRef = ref(database, `patients/${userUID}/${patientUID}`);

    // Espera a obtener los datos de Firebase
    const snapshot = await get(dataRef);

    // Verifica si existen los datos
    if (snapshot.exists()) {
      // Obtén el valor de los datos
      setPatientData(snapshot.val());
    } else {
      throw new Error("No data available for the patient");
    }
  };

  const fetchCampimetryData = async () => {
    // si no hay fechas, llamamos a la función clásica que carga los resultados buenos
    if (datesSel === undefined || datesSel.length === 0) {
      const requestData = {
        uid: userUID,
        paciente: patientUID,
      };

      const response = await axios.post(
        `${API_URL}/campimetryData/plot`,
        requestData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.data.error) {
        throw new Error("Error fetching campimetry data");
      }
      // Return the data directly
      generateCampimetryData(response.data);
    }
    // si hay fechas, llamamos a la función que carga los resultados viejos
    else {
      const requestData = {
        uid: userUID,
        paciente: patientUID,
        date: datesSel,
      };

      const response = await axios.post(
        `${API_URL}/campimetryData/plotByDate`,
        requestData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.data.error) {
        throw new Error("Error fetching campimetry data");
      }
      // Return the data directly
      generateCampimetryData(response.data);
    }

  };
  const generateCampimetryData = (auxCampimetryData) => {
    if (auxCampimetryData == null) {
      throw new Error("No data available for the campimetry");
    }
    let actualCampimetryData = {};
    let auxEyeValue = "No especificado";
    switch (auxCampimetryData.eye_value) {
      case 0:
        auxEyeValue = t("common.left-o");
        break;
      case 1:
        auxEyeValue = t("common.right-o");
        break;
      case 2:
        auxEyeValue = t("common.both");
        break;
      default:
        auxEyeValue = "No especificado";
        break;
    }
    actualCampimetryData.eyeValue = auxEyeValue;

    let testDateAux = auxCampimetryData.date_value;
    const minutes = Math.floor(auxCampimetryData.total_time / 60);
    const remainingSeconds = Math.floor(auxCampimetryData.total_time % 60); // Truncate to integer
    actualCampimetryData.timeTest = `${minutes}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;

    actualCampimetryData.oldData = false;
    let sectionsDoneAux = [];
    let totalFakePositivesAux = [];
    let totalFakeNegativesAux = [];

    // vamos a comprobar primero si los datos son de la funcion vieja
    if (auxCampimetryData.section_done === undefined) {
      actualCampimetryData.oldData = true;
      // testDateAux is in format "YYYYMMDD-HHMMSS" and we want to show it as "DD/MM/YYYY - HH:MM:SS"
      testDateAux = testDateAux.slice(6, 8) + "/" + testDateAux.slice(4, 6) + "/" + testDateAux.slice(0, 4) + " - " + testDateAux.slice(9, 11) + ":" + testDateAux.slice(11, 13) + ":" + testDateAux.slice(13, 15);
    }
    else {
      actualCampimetryData.oldData = false;
      sectionsDoneAux = auxCampimetryData.section_done;
      totalFakePositivesAux = auxCampimetryData.total_fake_positives;
      totalFakeNegativesAux = auxCampimetryData.total_fake_negatives;
    }
    actualCampimetryData.testDate = testDateAux;

    // these variables are arrays of values, where each value represents a different section of the campimetry:
    // 0: central
    // 1: left
    // 2: right
    // 3: up
    // 4: down
    // array with the sections done
    actualCampimetryData.sectionsDone = sectionsDoneAux;
    // array with contrast pattern in each section
    actualCampimetryData.contrastPattern = auxCampimetryData.contrast_pattern;
    // array with the eye fixation in each section
    actualCampimetryData.eyeFixation = auxCampimetryData.eye_fixation_sensitivity;
    // array of fake positives in each section
    actualCampimetryData.fakePositives = auxCampimetryData.fake_positives;
    // array of total fake positives in each section
    actualCampimetryData.totalFakePositives = totalFakePositivesAux;
    // array of fake negatives in each section
    actualCampimetryData.fakeNegatives = auxCampimetryData.fake_negatives;
    // array of total fake negatives in each section
    actualCampimetryData.totalFakeNegatives = totalFakeNegativesAux;

    // finally, copy image data
    actualCampimetryData.puntos = auxCampimetryData.puntos;
    actualCampimetryData.valores = auxCampimetryData.valores;
    actualCampimetryData.valores_cruz = auxCampimetryData.valores_cruz;
    actualCampimetryData.valores_equis = auxCampimetryData.valores_equis;
    actualCampimetryData.interpolados_absoluto = auxCampimetryData.interpolados_absoluto;
    actualCampimetryData.interpolados_relativo = auxCampimetryData.interpolados_relativo;

    setCampimetryData(actualCampimetryData);
  };

  const generatePDF = async () => {
    try {
      await fetchPatientData();
      await fetchCampimetryData();
      setLetGeneratePDF(true);
    } catch (error) {
      console.error(error.message)
      handleSnackbarOpen();
      setIsModalLoadingPDF(false);
      stopGenerating();
    }
  };
  // endregion

  // region PDF generation
  // if all data is correctly loaded, generate the PDF
  useEffect(() => {
    if (letGeneratePDF) {
      // Lógica para generar el PDF
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        compress: true,

      });
      // Save the PDF
      pdf.html(reportTemplateRef.current, {
        callback: function (doc) {
          if (!campimetryData.puntos.error) {
            doc.addImage(campimetryData.puntos.img_src, "PNG", 120, 30, 75, 77, "puntos", "FAST");
          }
          if (!campimetryData.valores.error) {
            doc.addImage(campimetryData.valores.img_src, "PNG", 120, 110, 80, 84, "valores", "FAST");
          }
          if (!campimetryData.valores_cruz.error) {
            doc.addImage(campimetryData.valores_cruz.img_src, "PNG", 10, 115, 45, 49, "cruz", "FAST");
          }
          if (!campimetryData.valores_equis.error) {
            doc.addImage(campimetryData.valores_equis.img_src, "PNG", 65, 115, 45, 49, "equis", "FAST");
          }
          if (!campimetryData.interpolados_absoluto.error) {
            doc.addImage(campimetryData.interpolados_absoluto.img_src, "PNG", 10, 200, 85, 70, "absoluto", "FAST");
          }
          if (!campimetryData.interpolados_relativo.error) {
            doc.addImage(campimetryData.interpolados_relativo.img_src, "PNG", 110, 200, 85, 70, "relativo", "FAST");
          }
          doc.addImage(logoImage, "PNG", 25, 280, 70, 9, "logo", "FAST");
          doc.save(`Campimetria ${patientData.name} ${patientData.surname}.pdf`);
        },
        x: 0,
        y: 0,
        width: 210, // Ancho del contenido en mm
        windowWidth: 210 * 3.75 // Ancho de la ventana en px
      });

      setLetGeneratePDF(false);
      setIsModalLoadingPDF(false);
      stopGenerating();
    }
  }, [letGeneratePDF]);
  // endregion

  // region Snackbar logic
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const handleSnackbarOpen = () => {
    setSnackbarMessage("Error al descargar el documento. Si acabas de terminar la prueba, espera unos segundos y vuelve a intentarlo.");
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // endregion

  // region HTML
  const titleColumns = ["", t("common.central"), t("common.right-s"), t("common.left-s"), t("common.down"), t("common.up")];
  const tableFontSize = 9;
  const borderSize = 0;

  const renderTable = (isOldData) => {
    if (isOldData) {
      return (
        <ThemeProvider theme={theme}>
          <Typography variant="body2" sx={{ pl: 4 }}>
            <strong>{t("campimetry.strategy")}:</strong> {campimetryData.contrastPattern}
          </Typography>
          <Typography variant="body2" sx={{ pl: 4 }}>
            <strong>{t("campimetry.eyeFixationSensitivity")}:</strong> {campimetryData.eyeFixation}&deg;
          </Typography>
          <Typography variant="body2" sx={{ pl: 4 }}>
            <strong>{t("campimetry.falsePositives")}:</strong> {campimetryData.fakePositives}
          </Typography>
          {
            campimetryData.contrastPattern === "0-1" ?
              null :
              <Typography variant="body2" sx={{ pl: 4 }}>
                <strong>{t("campimetry.falseNegatives")}:</strong> {campimetryData.fakeNegatives}
              </Typography>
          }
        </ThemeProvider>
      );
    }
    return (
      <Box sx={{ p: 2, pr: 0 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 50 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                {titleColumns.map((title) => (
                  <TableCell sx={{ fontSize: tableFontSize }}>{title}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell key="title" sx={{ fontSize: tableFontSize }}>{t("campimetry.strategy")}</TableCell>
                {campimetryData.contrastPattern.map((value, index) => (
                  <TableCell key={index} sx={{ fontSize: tableFontSize, textAlign: 'center', whiteSpace: 'nowrap' }}>{campimetryData.sectionsDone[index] && value !== "" ? value : '-'}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell key="title" sx={{ fontSize: tableFontSize }}>{t("campimetry.eyeFixationSensitivity")}</TableCell>
                {campimetryData.eyeFixation.map((value, index) => (
                  <TableCell key={index} sx={{ fontSize: tableFontSize, textAlign: 'center' }}>{campimetryData.sectionsDone[index] && value !== -1 ? value + '\u00B0' : '-'}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell key="title" sx={{ fontSize: tableFontSize }}>{t("campimetry.falsePositives")}</TableCell>
                {campimetryData.fakePositives.map((value, index) => (
                  <TableCell key={index} sx={{ fontSize: tableFontSize, textAlign: 'center' }}>{campimetryData.sectionsDone[index] && campimetryData.totalFakePositives[index] !== 0 ? value + '/' + campimetryData.totalFakePositives[index] : '-'}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell key="title" sx={{ fontSize: tableFontSize }}>{t("campimetry.falseNegatives")}</TableCell>
                {campimetryData.fakeNegatives.map((value, index) => (
                  <TableCell key={index} sx={{ fontSize: tableFontSize, textAlign: 'center' }}>{campimetryData.sectionsDone[index] && campimetryData.totalFakeNegatives[index] !== 0 ? value + '/' + campimetryData.totalFakeNegatives[index] : '-'}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  // set size of div to A4 size: 210mm x 297mm
  return (
    <React.Fragment>
      {/* PDF data */}
      {letGeneratePDF ?
        <div ref={reportTemplateRef}>
          <ThemeProvider theme={theme}>
            <Box sx={{ p: 2 }}>
              <Grid container spacing={2} sx={{ pt: 2 }}>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ pl: 2 }}>
                    <strong>{t("forms.name")}:</strong> {patientData?.name} {patientData?.surname}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ pl: 2 }}>
                    <strong>{t("forms.birthDate")}:</strong> {patientData?.birthDate}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ pl: 2 }}>
                    <strong>{t("forms.sex")}:</strong> {formData.gender=="male" ? t("forms.male") : formData.gender=="female"? t("forms.female") : t("forms.other")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ pl: 2 }}>
                    <strong>{t("forms.testDate")}:</strong> {campimetryData.testDate}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </ThemeProvider>
          <Divider variant="middle" sx={{ mb: 2 }}></Divider>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ThemeProvider theme={theme}>
                <Typography variant="body2" sx={{ pl: 4 }}>
                  <strong>{t("commonTest.test")}:</strong> {t("campimetry.name")}
                </Typography>
                <Typography variant="body2" sx={{ pl: 4 }}>
                  <strong>{t("common.eye")}:</strong> {campimetryData.eyeValue}
                </Typography>
                <Typography variant="body2" sx={{ pl: 4 }}>
                  <strong>{t("campimetry.totalTestTime")}:</strong> {campimetryData.timeTest}
                </Typography>
                <Typography variant="body2" sx={{ pl: 4 }}>
                  <strong>{t("pdfGeneration.avEyeRight")}:</strong> {formData.rightEye}
                </Typography>
                <Typography variant="body2" sx={{ pl: 4 }}>
                  <strong>{t("pdfGeneration.avEyeLeft")}:</strong> {formData.leftEye}
                </Typography>
                <Typography variant="body2" sx={{ pl: 4 }}>
                  <strong>{t("campimetry.glasses")}:</strong> {formData.glasses ? t("common.yes") : t("common.no")}
                </Typography>
              </ThemeProvider>
              {renderTable(campimetryData.oldData)}
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box
                sx={{ minWidth: 280, minHeight: 280, border: borderSize, borderColor: 'grey.500', borderRadius: 1 }}
              />
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box
                sx={{ minWidth: 280, minHeight: 280, border: borderSize, borderColor: 'grey.500', borderRadius: 1 }}
              />
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box
                sx={{ minWidth: 280, minHeight: 280, border: borderSize, borderColor: 'grey.500', borderRadius: 1 }}
              />
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box
                sx={{ minWidth: 280, minHeight: 280, border: borderSize, borderColor: 'grey.500', borderRadius: 1 }}
              />
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box
                sx={{ minWidth: 280, minHeight: 280, border: borderSize, borderColor: 'grey.500', borderRadius: 1 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box
                sx={{ minWidth: 200, minHeight: 50, border: borderSize, borderColor: 'grey.500', borderRadius: 1 }}
              />
            </Grid>
            <ThemeProvider theme={theme}>
              <Grid item xs={8} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                <Typography variant="body2" sx={{ pr: 5 }}>
                  V-VISIÓN® | La digitalización de la oftalmología y optometría
                </Typography>
              </Grid>
            </ThemeProvider>
          </Grid>
        </div>
        : null
      }

      {/* PDF Popup for extra data */}
      <PDFForm
        open={isPDFPopupOpen}
        onClose={handlePopupClose}
        onSubmit={handleSubmitFormData}
      ></PDFForm>

      {/* Loading PDF modal */}
      <Modal
        open={isModalLoadingPDF}
        onClose={() => { }}
        aria-labelledby="generating-pdf-title"
        aria-describedby="generating-pdf-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography
            id="generating-pdf-title"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            {t('pdfGeneration.modalTitle')}
          </Typography>
          <Typography id="generating-pdf-description" sx={{ mt: 2 }}>
            {t('pdfGeneration.modalDescription')}
          </Typography>
        </Box>
      </Modal>

      {/* Snackbar for error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        sx={{
          marginLeft: 15,
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};
// endregion

export default CampimetryPDF;
