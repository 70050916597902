import React from "react";
import { useTranslation } from 'react-i18next';

import { Stage, Layer, Text, Rect } from "react-konva";

import { Box } from "@mui/material";

const ContrastSensitivityBars = ({
  contrastSensitivityStatus
}) => {

  const { t } = useTranslation();

  const cpd_values = [[0, 4, 8, 11, 16, 26, 33, 48, 86, 144],
  [0, 5, 10, 16, 19, 33, 48, 48, 86, 144],
  [0, 8, 16, 26, 33, 48, 48, 86, 144, 144],
  [0, 4, 8, 11, 16, 26, 33, 48, 86, 144]];

  // Variables para controlar las propiedades de los rectángulos
  const barConfig = {
    startX: 0,         // Posición X inicial
    startY: 50,         // Posición Y inicial
    barWidth: 150,       // Ancho de cada rectángulo
    barHeight: 50,       // Alto de cada rectángulo
    barWidthExtra: 100,   // Ancho de rectángulo adicional
    barSpacing: 30,      // Espacio entre rectángulos
    verticalGap: 100,     // Espacio vertical entre filas
    cornerRadius: 10,    // Radio de las esquinas redondeadas
    rowCount: 4,         // Número de filas
    colors: {
      red: "#ffcdce",    // Color rojo
      yellow: "#f3e1cd", // Color amarillo
      green: "#cee6ce",   // Color verde
      redMarked: "#ff7070",
      yellowMarked: "#f3e17d",
      greenMarked: "#7ee67e",
      redText: "#6f0414",
      yellowText: "#592b08",
      greenText: "#07360c",
      grey: "#d3d3d3"
    },
    cpdValues: ["1.5 CPD", "3 CPD", "6 CPD", "12 CPD"],  // Valores CPD para cada fila
  };

  // Función para renderizar las barras
  const renderBars = () => {
    // vamos a hacer unas modificaciones cuando haya un elemento actual_test_in_queue, primero vamos a hacer un if para ver si hay un elemento actual_test_in_queue
    let isActualElement = false;
    let actualElement = -1;
    let blueBarWidths = [-1, -1, -1, -1];

    if (contrastSensitivityStatus != undefined) {
      blueBarWidths = [contrastSensitivityStatus.cpd_1_5_step, contrastSensitivityStatus.cpd_3_step, contrastSensitivityStatus.cpd_6_step, contrastSensitivityStatus.cpd_12_step];

      if (("actual_test_in_queue" in contrastSensitivityStatus) && contrastSensitivityStatus.actual_test_in_queue != undefined && contrastSensitivityStatus.actual_test_in_queue > -1) {
        isActualElement = true;
        actualElement = contrastSensitivityStatus.actual_test_in_queue;
      }
    }

    const elements = [];

    for (let i = 0; i < barConfig.rowCount; i++) {
      // vamos a establecer los colores, si el blueBarWidths[i] es -1, significa que no se ha realizado la prueba, por lo que se va a poner un color gris
      // si el actualElement es igual a i, se va a poner un color más intenso
      // en cualquier otro caso, color normal
      let colorRed = barConfig.colors.red;
      let colorYellow = barConfig.colors.yellow;
      let colorGreen = barConfig.colors.green;
      let actualOpacity = 0.4;

      if (blueBarWidths[i] != -1) {
        actualOpacity = 1;
      }

      if (isActualElement && actualElement == i) {
        actualOpacity = 1;
        colorRed = barConfig.colors.redMarked;
        colorYellow = barConfig.colors.yellowMarked;
        colorGreen = barConfig.colors.greenMarked;
      }

      const yPosition = barConfig.startY + (i * barConfig.verticalGap);

      // Añadir texto CPD encima de cada barra
      // si es el elemento actual, se va a añadir un texto que diga "Evaluando..."
      elements.push(
        <Text
          key={`cpd-${i}`}
          x={barConfig.startX + 20}
          y={yPosition - 20}
          text={barConfig.cpdValues[i] + (isActualElement && actualElement == i ? " - Evaluando..." : "")}
          fontSize={16}
          fontStyle="bold"
          fill="#000"
          width={barConfig.barWidth * 2}
          opacity={actualOpacity}
        />
      );

      // Primer rectángulo (rojo) con bordes redondeados a la izquierda
      // si el elemento actual es igual a i, se va a añadir un rectángulo con un color más intenso
      elements.push(
        <Rect
          key={`red-${i}`}
          x={barConfig.startX}
          y={yPosition}
          width={barConfig.barWidth}
          height={barConfig.barHeight}
          fill={colorRed}
          cornerRadius={[barConfig.cornerRadius, 0, 0, barConfig.cornerRadius]}
          opacity={actualOpacity}
        />
      );

      // Texto "bajo" dentro del rectángulo rojo
      elements.push(
        <Text
          key={`red-text-${i}`}
          x={barConfig.startX + 10}
          y={yPosition + 10}
          text={t("contrastSensitivity.low")}
          fontSize={14}
          fontStyle="bold"
          fill={barConfig.colors.redText}
          opacity={actualOpacity}
        />
      );

      // Segundo rectángulo (amarillo) sin bordes redondeados
      // si el elemento actual es igual a i, se va a añadir un rectángulo con un color más intenso
      elements.push(
        <Rect
          key={`yellow-${i}`}
          x={barConfig.startX + barConfig.barWidth}
          y={yPosition}
          width={barConfig.barWidth}
          height={barConfig.barHeight}
          fill={colorYellow}
          opacity={actualOpacity}
        />
      );

      // Texto "medio" dentro del rectángulo amarillo
      elements.push(
        <Text
          key={`yellow-text-${i}`}
          x={barConfig.startX + barConfig.barWidth + 10}
          y={yPosition + 10}
          text={t("contrastSensitivity.medium")}
          fontSize={14}
          fontStyle="bold"
          fill={barConfig.colors.yellowText}
          opacity={actualOpacity}
        />
      );

      // Tercer rectángulo (verde) con bordes redondeados a la derecha
      // si el elemento actual es igual a i, se va a añadir un rectángulo con un color más intenso
      elements.push(
        <Rect
          key={`green-${i}`}
          x={barConfig.startX + (2 * barConfig.barWidth)}
          y={yPosition}
          width={barConfig.barWidth}
          height={barConfig.barHeight}
          fill={colorGreen}
          cornerRadius={[0, barConfig.cornerRadius, barConfig.cornerRadius, 0]}
          opacity={actualOpacity}
        />
      );

      // Texto "alto" dentro del rectángulo verde
      elements.push(
        <Text
          key={`green-text-${i}`}
          x={barConfig.startX + (2 * barConfig.barWidth) + 10}
          y={yPosition + 10}
          text={t("contrastSensitivity.high")}
          fontSize={14}
          fontStyle="bold"
          fill={barConfig.colors.greenText}
          opacity={actualOpacity}
        />
      );

      // Cuadrado adicional con bordes redondeados a la derecha
      elements.push(
        <Rect
          key={`extra-${i}`}
          x={barConfig.startX + (3 * barConfig.barWidth) + barConfig.barSpacing} // Añadimos un pequeño espacio (20) después del último rectángulo
          y={yPosition}
          width={barConfig.barWidthExtra}
          height={barConfig.barHeight}
          fill={barConfig.colors.grey}
          cornerRadius={barConfig.cornerRadius} // Bordes redondeados a la derecha
          opacity={actualOpacity}
        />
      );
    }

    return elements;
  };

  const renderBlueBars = () => {
    if (contrastSensitivityStatus == undefined) return;

    const blueBarWidths = [contrastSensitivityStatus.cpd_1_5_step, contrastSensitivityStatus.cpd_3_step, contrastSensitivityStatus.cpd_6_step, contrastSensitivityStatus.cpd_12_step];
    const elements = [];

    for (let i = 0; i < barConfig.rowCount; i++) {
      // comprobamos si el valor es -1 para indicar que la prueba no se ha realizado, en ese caso se va a poner un texto que diga "No realizado" y no se pone rectangulo
      // 0, significa que no ha visto nada, por lo que se va a poner un texto que diga "No visto" tampoco se pone rectangulo
      let text = "";
      let initialSize = 18;
      let isRect = true;
      if (blueBarWidths[i] == -1) {
        text = "";
        isRect = false;
        initialSize = 15;
      } else if (blueBarWidths[i] == 0) {
        text = "No visto";
        isRect = false;
        initialSize = 15;
      }
      else {
        text = cpd_values[i][blueBarWidths[i]] + "dB";
      }

      const yPosition = barConfig.startY + (i * barConfig.verticalGap);
      if (isRect) {
        // Rectángulo final
        elements.push(
          <Rect
            key={`blue-${i}`}
            x={barConfig.startX + 10}
            y={yPosition + 27}
            width={blueBarWidths[i] * (barConfig.barWidth) / 3.15}
            height={barConfig.barHeight / 3}
            fill={"#1976d2"}
          />
        );
      }


      // Texto en el rectangulo extra
      elements.push(
        <Text
          key={`blue-text-${i}`}
          x={barConfig.startX + (3 * barConfig.barWidth) + barConfig.barSpacing - 15}
          y={yPosition + 10}
          width={barConfig.barWidthExtra} // Establecer el ancho igual al del rectángulo
          text={text}
          fontSize={initialSize}
          fontStyle="bold"
          fill="#000"
          align="right" // Alineación a la derecha
          padding={10} // Padding para que no quede pegado al borde
        />
      );
    }
    return elements;
  };

  return (

    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width={601}
      height={401}
    >
      <Stage width={601} height={401} >
        <Layer>

          {/* Render bars */}
          {renderBars()}
          {renderBlueBars()}
        </Layer>
      </Stage>
    </Box>

  )
};
export default ContrastSensitivityBars;