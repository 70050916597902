import { useTranslation } from 'react-i18next';
import React from "react";
import { Stage, Layer, Circle, Line, Text, Arrow } from "react-konva";
import {
  Box,
  Typography
} from "@mui/material";

const HessLancasterGrid = ({
  isLeftEye,
  isShowingValues,
  hessData
}) => {

  const { t } = useTranslation();

  const renderMainDots = () => {
    const dots = [];
    const originalXPos = [121, 121, 121, 226, 226, 226, 331, 331, 331];
    const originalYPos = [121, 226, 331, 121, 226, 331, 121, 226, 331];

    for (let i = 0; i < 9; i++) {
      dots.push(
        <Circle
          radius={2}
          x={originalXPos[i]}
          y={originalYPos[i]}
          stroke="black"
          strokeWidth={3}
        />
      );
    }

    return dots.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };

  // function to render the lines of the hess lancaster test
  const renderLeftHessLancasterLines = (isLeft, mainData) => {
    if (!mainData) {
      return null;
    }

    const ratio = 333.33;
    const base = 226;
    // if isLeft is true, set base_color with blue (#1976d2) value, otherwise set base_color with red (#E62E1B) value
    const base_color = isLeft ? "#1976d2" : "#E62E1B";
    const main_color = isLeft ? "blue" : "red";
    const base_width = 2;

    const actualPoint = isLeft ? mainData.maxLeftPoint : mainData.maxRightPoint;
    const eyePos = isLeft ? mainData.leftPos : mainData.rightPos;
    const eyeRot = isLeft ? mainData.leftRot : mainData.rightRot;

    if (!eyePos) {
      return null;
    }

    // Create the first line with the first two points, the values are in the array where origin is 0 and end is 1 and the format is [x, y]
    // so we have to check if at least the first two points exist
    if (actualPoint < 1) {
      return null;
    }

    const linePoints = [];
    // to only check once if the points are valid, create a bool array with the values
    const validPoints = eyePos.map((pos) => Math.abs(pos.x) < 0.75 && Math.abs(pos.y) < 0.75);
    const addLine = (p1, p2) => {
      if (validPoints[p1] && validPoints[p2]) {
        linePoints.push([
          base + eyePos[p1].x * ratio, base - eyePos[p1].y * ratio,
          base + eyePos[p2].x * ratio, base - eyePos[p2].y * ratio
        ]);
      }
    };
    for (let i = 1; i < actualPoint - 1; i++) {
      addLine(i, i + 1, i);
    }
    if (actualPoint >= 3) addLine(0, 2);
    if (actualPoint >= 5) addLine(0, 4);
    if (actualPoint >= 7) addLine(0, 6);
    if (actualPoint >= 9) {
      addLine(8, 1);
      addLine(0, 8);
    }
    // set the lines with the linePoints array
    const lines = linePoints.map((points, index) => {
      return (
        <Line
          key={index}
          points={points}
          stroke={base_color}
          strokeWidth={base_width}
        />
      );
    });

    // check if the points are valid, if not, add the fallback lines
    const fallbackPoints = [];
    const fallbackValues = [
      [0, 0, 0, 0],
      [110, 110, 10, 10],
      [226, 110, 226, 10],
      [342, 110, 442, 10],
      [342, 226, 442, 226],
      [342, 342, 442, 442],
      [226, 342, 226, 442],
      [110, 342, 10, 442],
      [110, 226, 10, 226]
    ];
    for (let i = 1; i < actualPoint; i++) {
      if (!validPoints[i]) {
        fallbackPoints.push(fallbackValues[i]);
      }
    }
    // add the fallback lines to lines
    fallbackPoints.forEach((points, index) => {
      lines.push(
        <Arrow
          key={index}
          points={points}
          stroke={base_color}
          strokeWidth={base_width + 3}
        />
      );
    });

    for (let i = 0; i < actualPoint; i++) {
      if (mainData.stimulusType === "punctual") {
        lines.push(
          <Circle
            x={base + eyePos[i].x * ratio}
            y={base - eyePos[i].y * ratio}
            radius={5}
            fill={main_color}
          />
        );
      }
      if (mainData.stimulusType === "vertical") {
        const actualDegree = 90 + Math.round(eyeRot[i].z);
        const newXOrigin = (20 * Math.cos(actualDegree * Math.PI / 180));
        const newYOrigin = (20 * Math.sin(actualDegree * Math.PI / 180));

        lines.push(
          <Line
            points={[
              newXOrigin + (base + eyePos[i].x * ratio),
              - newYOrigin + (base - eyePos[i].y * ratio),
              - newXOrigin + (base + eyePos[i].x * ratio),
              newYOrigin + (base - eyePos[i].y * ratio),
            ]}
            stroke={main_color}
            strokeWidth={base_width + 3}
          />
        );
      }
    }

    // return the lines, but Each child in a list should have a unique "key" prop.
    return lines.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };

  // function to render the lines of the hess lancaster test
  const renderLeftHessLancasterText = (isLeft, mainData) => {

    if (!mainData) {
      return null;
    }

    const ratio = 333.33;
    const base = 226;

    const actualPoint = isLeft ? mainData.maxLeftPoint : mainData.maxRightPoint;
    const eyePos = isLeft ? mainData.leftPos : mainData.rightPos;
    const eyeRot = isLeft ? mainData.leftRot : mainData.rightRot;

    if (!eyePos) {
      return null;
    }

    // Create the first line with the first two points, the values are in the array where origin is 0 and end is 1 and the format is [x, y]
    // so we have to check if at least the first two points exist
    if (actualPoint < 1) {
      return null;
    }

    const validPoints = eyePos.map((pos) => Math.abs(pos.x) < 0.75 && Math.abs(pos.y) < 0.75);
    // compare the original position with the value of the eyePos.
    const originalXPos = [226, 121, 226, 331, 331, 331, 226, 121, 121];
    const originalYPos = [226, 331, 331, 331, 226, 121, 121, 121, 226];

    // loop to check the values
    const texts = [];
    for (let i = 0; i < actualPoint; i++) {
      if (validPoints[i]) {
        const actualXPoint = base + eyePos[i].x * ratio;
        const difXPoint = Math.abs(Math.round((actualXPoint - originalXPos[i]) / 4));

        const actualYPoint = base + eyePos[i].y * ratio;
        const difYPoint = Math.abs(Math.round((actualYPoint - originalYPos[i]) / 4));

        texts.push(
          <Text
            x={base + eyePos[i].x * ratio + 10}
            y={base - eyePos[i].y * ratio + 10}
            text={"x: " + difXPoint + "Δ"}
            fontSize={15}
            fill="black"
            fontFamily="system-ui"
            fontStyle="bold"
          />
        );
        texts.push(
          <Text
            x={base + eyePos[i].x * ratio + 10}
            y={base - eyePos[i].y * ratio + 25}
            text={"y: " + difYPoint + "Δ"}
            fontSize={15}
            fill="black"
            fontFamily="system-ui"
            fontStyle="bold"
          />
        );

        if (mainData.stimulusType === "vertical") {
          const actualDegree = 180 - Math.abs(180 - Math.round(eyeRot[i].z));
          texts.push(
            <Text
              x={base + eyePos[i].x * ratio + 10}
              y={base - eyePos[i].y * ratio + 40}
              text={actualDegree + "°"}
              fontSize={15}
              fill="black"
              fontFamily="system-ui"
              fontStyle="bold"
            />
          );
        }
      }
    }

    // return the lines, but Each child in a list should have a unique "key" prop.
    return texts.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {isLeftEye ? t('hessLancaster.leftEye') : t('hessLancaster.rightEye')}
      </Typography>
      <div style={{ position: 'relative' }}>
        {/* Texto a la izquierda de la Box */}
        <Typography
          variant="body1"
          style={{
            position: 'absolute',
            textAlign: 'center',
            left: -80,
            top: '0%',
            transform: 'translateY(-50%)',
            fontSize: "14px"
          }}
        >
          <pre>
            {isLeftEye ? t('hessLancaster.superiorRectus') : t('hessLancaster.inferiorOblique')}
          </pre>
        </Typography>
        <Typography
          variant="body1"
          style={{
            position: 'absolute',
            textAlign: 'center',
            left: -80,
            top: '50%',
            transform: 'translateY(-50%)',
            fontSize: "14px"
          }}
        >
          <pre>
            {isLeftEye ? t('hessLancaster.lateralRectus') : t('hessLancaster.medialRectus')}
          </pre>
        </Typography>
        <Typography
          variant="body1"
          style={{
            position: 'absolute',
            textAlign: 'center',
            left: -80,
            top: '100%',
            transform: 'translateY(-50%)',
            fontSize: "14px"
          }}
        >
          <pre>
            {isLeftEye ? t('hessLancaster.inferiorRectus') : t('hessLancaster.superiorOblique')}
          </pre>
        </Typography>
        <Box
          width={451}
          height={451}
          style={{
            backgroundSize: "15px 15px",
            backgroundImage: "linear-gradient(to right, grey 1px, transparent 1px),linear-gradient(to bottom, grey 1px, transparent 1px)"
          }}
        >
          <Stage width={451} height={451}>
            <Layer>
              {renderMainDots()}
              {renderLeftHessLancasterLines(isLeftEye,hessData)}
              {isShowingValues ? renderLeftHessLancasterText(isLeftEye,hessData) : null}
            </Layer>
          </Stage>
        </Box>
        <Typography
          variant="body1"
          style={{
            position: 'absolute',
            textAlign: 'center',
            right: -80,
            top: '0%',
            transform: 'translateY(-50%)',
            fontSize: "14px"
          }}
        >
          <pre>
            {isLeftEye ? t('hessLancaster.inferiorOblique') : t('hessLancaster.superiorRectus')}
          </pre>
        </Typography>
        <Typography
          variant="body1"
          style={{
            position: 'absolute',
            textAlign: 'center',
            right: -80,
            top: '50%',
            transform: 'translateY(-50%)',
            fontSize: "14px"
          }}
        >
          <pre>
            {isLeftEye ? t('hessLancaster.medialRectus') : t('hessLancaster.lateralRectus')}
          </pre>
        </Typography>
        <Typography
          variant="body1"
          style={{
            position: 'absolute',
            textAlign: 'center',
            right: -80,
            top: '100%',
            transform: 'translateY(-50%)',
            fontSize: "14px"
          }}
        >
          <pre>
            {isLeftEye ? t('hessLancaster.superiorOblique') : t('hessLancaster.inferiorRectus')}
          </pre>
        </Typography>
      </div>
    </React.Fragment>
  );
}

export default HessLancasterGrid;