import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../providers/AuthContext";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Button,
  Divider,
  Snackbar,
  Alert,
  Card,
  CardContent
} from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";

import StartTestButton from "../../components/buttons/StartTestButton";

const ESnellenForm = () => {
  const { currentUser, updateLastTestDate } = useContext(AuthContext);
  const { uid } = useParams();

  const [eye, setEye] = useState("");
  const [distance, setDistance] = useState("close");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [eSnellenStatus, setESnellenStatus] = React.useState(null);

  const { t } = useTranslation();
  
  useEffect(() => {
    fetchHessLancasterStatus(); // Fetch immediately on mount
    const intervalId = setInterval(fetchHessLancasterStatus, 1000); // Adjust the interval as needed
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [currentUser.uid]); // Depend on uid so if it changes, we re-fetch

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const fetchHessLancasterStatus = async () => {
    const userId = currentUser.uid;

    axios
      .get(`${SERVER_URL}/server/e-snellen-status/${userId}`)
      .then((response) => {
        setESnellenStatus(response.data);
        //console.log("E snellen status fetched successfully", response.data.left_eye_pos);
      })
      .catch((error) => {
        console.error("There was an error fetching the patient data:", error);
      });
  };


  const handleSnackbar = () => {
    setSnackbarMessage(t('commonTest.success'));
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const allFieldsValid = () => {
    return eye !== "";
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      config_test_type: "e_snellen",
      config_eye: eye,
      config_snellen_distance: distance,
      config_user_id: uid,
    };
    const userId = currentUser.uid;

    axios
      .post(
        `${SERVER_URL}/server/dicoptpro-config/${userId}`,
        formData
      )
      .then((response) => {
        console.log("Form submitted successfully", response.data);

        handleSnackbar();
        updateLastTestDate(uid);
      })
      .catch((error) => {
        console.error("Error submitting form", error);
      });

    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const printStatus = () => {
    if(!eSnellenStatus){
      return t('eSnellen.waiting')
    }
    if(eSnellenStatus.actual_user === uid){
      if(eSnellenStatus.in_test){
        return t('eSnellen.running')
      }
      else{
        return t('eSnellen.ended')
      }
    }
    else{
      return t('eSnellen.waiting')
    }
  }

  const printValue = () => {
    if(!eSnellenStatus){
      return ""
    }
    if(eSnellenStatus.actual_user !== uid){
      return ""
    }
    if(eSnellenStatus.in_test){
      return ""
    }

    // check if actual_step is not null
    if(eSnellenStatus.actual_step === null){
      return ""
    }
   
    // calculate logMAR from actual_step
    let aux_step = eSnellenStatus.actual_step;
    if (aux_step > 10) {
      aux_step = 10;
    }
    if(aux_step <= 0){
      return "0.0 decimal - 1.0 logMAR";
    }
    // aux_step is rounded to 1 decimal
    let logMAR = 1 - (aux_step / 10);

    // now we have to check the wrong answers
    if (eSnellenStatus.current_wrong_answers !== null){
      // if the user has made a mistake, we have to calculate the new logMAR
      let wrong_answers = eSnellenStatus.current_wrong_answers;
      if (wrong_answers > 0) {
        logMAR += wrong_answers*0.02;
      }

      // finally, if wrong_answers is greater than 5, we have to check previous wrong answers
      if (wrong_answers > 5) {
        if (eSnellenStatus.previous_wrong_answers !== null) {
          let previous_wrong_answers = eSnellenStatus.previous_wrong_answers;
          if (previous_wrong_answers > 0) {
            logMAR += previous_wrong_answers*0.02;
          }
        }
      }
    }

    // newDecimal is rounded to 2 decimals, but also it is rounded by 0.02 steps, that means, 0.02, 0.04, 0.06, etc.
    const newDecimal = Math.round( (1/(10 ** logMAR)) *50)/50;
    //const newDecimal = (1/(10 ** logMAR));
    return newDecimal.toFixed(2) + " decimal - " + logMAR.toFixed(2) + " logMAR";
  }

  // Inside your component's return statement
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Typography variant="h4" gutterBottom>
        {t('eSnellen.name')}
      </Typography>
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            {t('commonTest.parametersTitle')}
          </Typography>
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel
              htmlFor="glasses-select"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {t('common.eye')}
            </InputLabel>
            <Select
              value={eye}
              label="Eye"
              onChange={(e) => setEye(e.target.value)}
            >
              <MenuItem value="right">{t('common.right-o')}</MenuItem>
              <MenuItem value="left">{t('common.left-o')}</MenuItem>
              <MenuItem value="none">{t('common.both')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            {t('commonTest.optionsTitle')}
          </Typography>
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel
              htmlFor="stimulus-size"
              style={{
                // Adjust the style to allow for wrapping and ensure adequate spacing
                whiteSpace: "normal", // Allow the label to wrap onto multiple lines as needed
                lineHeight: "1.4375em", // Standard line height to maintain vertical rhythm
                paddingTop: "6px", // Add a bit of padding to the top to ensure wrapped text doesn't touch the input border
              }}
            >
              {t('eSnellen.distance')}
            </InputLabel>
            <Select
              label={t('eSnellen.distance')} // Ensure this matches the text of the InputLabel for proper alignment and sizing
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                name: "distance",
                id: "distance",
              }}
              value={distance}
              onChange={(e) => setDistance(e.target.value)}
            >
              <MenuItem value="close">{t('eSnellen.close')}</MenuItem>
              <MenuItem value="far">{t('eSnellen.far')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{ textAlignLast: "center" }}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!allFieldsValid()} // Disable the button if not all fields are valid
          sx={{ width: 150, alignSelf: "center", marginTop: 2 }}
        >
          {t('forms.submit')}
        </Button>
      </Box>
      {/* Divider and additional components */}
      <Divider sx={{ marginBottom: 5, marginTop: 5 }}></Divider>

      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        p={1}
        textAlign={"-webkit-center"}
      >
        {/* First Card */}
        <Card sx={{ width: `calc(50% - 25px)`, marginRight: "25px" }}>
          <CardContent>
            <Typography variant="h6" component="h2">
              {printStatus()}
            </Typography>
            <Typography variant="body1" sx={"black"}>
              {printValue()}
            </Typography>
          </CardContent>
        </Card>

      </Box>

      <Box
          display="flex"
          justifyContent="center"
          width="100%"
          p={1}
          textAlign={"-webkit-center"}
        >
          <StartTestButton />
        </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={handleSnackbarClose}
        sx={{
          marginLeft: 15,
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ESnellenForm;
