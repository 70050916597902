import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../providers/AuthContext";
import { HeartbeatContext } from "../../providers/HeartbeatContext";
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  Slider,
  Button,
  Divider,
  TextField,
  Snackbar,
  Alert,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";

// ICONS
import InfoIcon from '@mui/icons-material/Info';
import FrontJoystick from '../../assets/frontJoystick.png';
import SideJoystick from '../../assets/sideJoystick.png';

import { Stage, Layer, Rect, Line, Circle } from "react-konva";

import StartTestButton from "../../components/buttons/StartTestButton";

import { ref, onValue, off } from 'firebase/database';
import { database } from '../../firebaseConfig';
import AmslerGridPDF from "../../components/PDFdocuments/AmslerGridPDF";

const AmslerGridForm = () => {
  const [eye, setEye] = React.useState("");
  const [gridType, setGridType] = React.useState("");
  const [calibrationRequired, setCalibrationRequired] = React.useState(true);
  const [eyeFixationSensitivity, setEyeFixationSensitivity] = React.useState(4);
  const [escotoma, setescotoma] = React.useState(false);
  const [amslerGridStatus, setAmslerGridStatus] = React.useState(null);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const [backgroundColor, setBackgroundColor] = React.useState("black");
  const [lineColor, setLineColor] = React.useState("red");
  const [fillerColor, setFillerColor] = React.useState("white");
  const [escotomaLines, setEscotomaLines] = React.useState(false);
  const [eyePosition, setEyePosition] = useState({ x: 100, y: 100 });

  const { uid } = useParams();
  const { currentUser, updateLastTestDate } = useContext(AuthContext);
  const { isAppAlive } = useContext(HeartbeatContext);
  const { t } = useTranslation();

  const [openTestParam, setOpenTestParam] = React.useState(false);
  const [openOptionParam, setOpenOptionParam] = React.useState(false);
  const [openInstructions, setOpenInstructions] = React.useState(false);

  const [letGeneratePDF, setLetGeneratePDF] = useState(false);

  // region Read Status
  useEffect(() => {
    if (!isAppAlive) return;
    if (!currentUser || currentUser.uid === null || currentUser.uid === "") return;

    const amslerGridStatusRef = ref(database, `amsler_grid_status/${currentUser.uid}`);
    const fetchAmslerGridStatus = (snapshot) => {
      const amslerGridVal = snapshot.val();
      if (amslerGridVal) {
        setAmslerGridStatus(amslerGridVal);
      }
      else {
        setAmslerGridStatus({
          in_test: false,
          test_locked: false,
          remaining_stimulus: 0,
          eye_fixation_sensitivity: 0,
          matrix: []
        });
      }
    };
    onValue(amslerGridStatusRef, fetchAmslerGridStatus);

    const eyePositionRef = ref(database, `eye_position/${currentUser.uid}`);
    const fetchEyePosition = (snapshot) => {
      const eyeVal = snapshot.val();
      if (eyeVal) {
        // Convert from -1.25 to 1.25 range into 0 to 500 pixel range, inverting Y for gaze position
        const scaledGazeX = (1 + ((eyeVal.x - 12) * 2)) * 200;
        const scaledGazeY = (1 + ((-eyeVal.y - 6) * 2)) * 200;
        setEyePosition({ x: scaledGazeX, y: scaledGazeY });
      }
      else {
        setEyePosition({
          gaze_x: 0,
          gaze_y: 0
        });
      }
    };
    onValue(eyePositionRef, fetchEyePosition);

    return () => {
      off(amslerGridStatusRef, 'value', fetchAmslerGridStatus);
      off(eyePositionRef, 'value', fetchEyePosition);
    };
  }, [isAppAlive]);
  // endregion

  // region Amsler Drawing
  const renderAmslerGridLines = () => {
    if (!escotomaLines) {
      return null;
    }
    const lines = [];
    lines.push(
      <Line
        points={[0, 400, 400, 0]}
        stroke={lineColor}
        strokeWidth={1}
      />
    );
    lines.push(
      <Line
        points={[0, 0, 400, 400]}
        stroke={lineColor}
        strokeWidth={1}
      />
    );
    return lines.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  }

  // function to render the lines of the hess lancaster test
  const renderAmslerGridBoxes = () => {
    // check if hessLancasterStatus is null, if so, return null
    if (!amslerGridStatus) {
      return null;
    }

    if (amslerGridStatus?.actual_user !== uid) {
      return null;
    }

    const actualMatrix = amslerGridStatus?.matrix;
    if (!actualMatrix) {
      return null;
    }

    // loop to create the rest of the lines and store them in an array
    const rects = [];
    // get the number of elements in the matrix
    const n = actualMatrix.length;
    for (let i = 0; i < n; i++) {
      // values in matrix are int where the two first digits are the column (y) and the third and forth are the row (x)
      const x = actualMatrix[i] % 100;
      const y = Math.floor(actualMatrix[i] / 100);
      rects.push(
        <Rect
          x={x * 20 + 1}
          y={y * 20 + 1}
          width={19}
          height={19}
          fill={fillerColor}
        />);
    }

    // return the lines, but Each child in a list should have a unique "key" prop.
    return rects.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };
  // endregion

  // region Info Dialogs
  const handleClickOpenTestParam = () => () => {
    setOpenTestParam(true);
  };
  const handleCloseTestParam = () => {
    setOpenTestParam(false);
  };
  const descriptionTestParamRef = useRef(null);
  React.useEffect(() => {
    if (openTestParam) {
      const { current: descriptionTestParam } = descriptionTestParamRef;
      if (descriptionTestParam !== null) {
        descriptionTestParam.focus();
      }
    }
  }, [openTestParam]);

  const handleClickOpenOptionParam = () => () => {
    setOpenOptionParam(true);
  };
  const handleCloseOptionParam = () => {
    setOpenOptionParam(false);
  };
  const descriptionOptionParamRef = useRef(null);
  React.useEffect(() => {
    if (openOptionParam) {
      const { current: descriptionOptionParam } = descriptionOptionParamRef;
      if (descriptionOptionParam !== null) {
        descriptionOptionParam.focus();
      }
    }
  }, [openOptionParam]);

  const handleClickOpenInstructions = () => () => {
    setOpenInstructions(true);
  };
  const handleCloseInstructions = () => {
    setOpenInstructions(false);
  };
  const descriptionInstructionsRef = useRef(null);
  React.useEffect(() => {
    if (openInstructions) {
      const { current: descriptionInstructions } = descriptionInstructionsRef;
      if (descriptionInstructions !== null) {
        descriptionInstructions.focus();
      }
    }
  }, [openInstructions]);
  // endregion

  // region Snackbar
  const handleSnackbar = () => {
    setSnackbarMessage(t('commonTest.success'));
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // endregion

  // region PDF generation
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const startGeneratingPDF = () => setGeneratingPDF(true);
  const stopGeneratingPDF = () => setGeneratingPDF(false);
  // endregion


  const handleSliderChange = (event, newValue) => {
    setEyeFixationSensitivity(newValue);
  };

  const handleInputChange = (event) => {
    setEyeFixationSensitivity(
      event.target.value === "" ? "" : Number(event.target.value)
    );
  };

  const handleBlur = () => {
    if (eyeFixationSensitivity < 1) {
      setEyeFixationSensitivity(1);
    } else if (eyeFixationSensitivity > 15) {
      setEyeFixationSensitivity(15);
    }
  };

  const handleCalibrationRequieredCheckbox = (event) => {
    setCalibrationRequired(event.target.checked);
  };

  const allFieldsValid = () => {
    return gridType && eye;
  };

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const handleSubmit = (event) => {
    event.preventDefault();

    switch (gridType) {
      case "white":
        setBackgroundColor("black");
        setLineColor("white");
        setFillerColor("white");
        break;
      case "black":
        setBackgroundColor("white");
        setLineColor("black");
        setFillerColor("red");
        break;
      case "red":
        setBackgroundColor("black");
        setLineColor("red");
        setFillerColor("white");
        break;
      default:
        setBackgroundColor("white");
        setLineColor("black");
        setFillerColor("red");
    }
    setEscotomaLines(escotoma);

    const formData = {
      config_test_type: "amslet_grid",
      config_eye: eye,
      config_calibration_required: calibrationRequired,
      config_eye_fixation_sensitivity: eyeFixationSensitivity,
      config_scotoma_help: escotoma,
      config_amsler_grid_type: gridType,
      config_user_id: uid,
    };
    const userId = currentUser.uid;

    axios
      .post(
        `${SERVER_URL}/server/dicoptpro-config/${userId}`,
        formData
      )
      .then((response) => {
        console.log("Form submitted successfully", response.data);

        handleSnackbar();
        updateLastTestDate(uid);
      })
      .catch((error) => {
        console.error("Error submitting form", error);
      });

    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  // Inside your component's return statement
  return (
    <React.Fragment>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Typography variant="h4" gutterBottom sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          {t('amslerGrid.name')}
          <Button color="primary" variant="contained" onClick={handleClickOpenInstructions()} sx={{ ml: 3, borderRadius: 28 }}>
            {t('commonTest.instructions')}
          </Button>
        </Typography>
        <Grid container spacing={2}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              {t('commonTest.parametersTitle')}
              <IconButton onClick={handleClickOpenTestParam()} aria-label="delete" sx={{ ml: 3 }}>
                <InfoIcon color="action" />
              </IconButton>
            </Typography>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="glasses-select"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {t('common.eye')}
              </InputLabel>
              <Select
                value={eye}
                label="Eye"
                onChange={(e) => setEye(e.target.value)}
              >
                <MenuItem value="right">{t('common.right-o')}</MenuItem>
                <MenuItem value="left">{t('common.left-o')}</MenuItem>
                <MenuItem value="none">{t('common.both')}</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="grid-type"
                style={{
                  // Adjust the style to allow for wrapping and ensure adequate spacing
                  whiteSpace: "normal", // Allow the label to wrap onto multiple lines as needed
                  lineHeight: "1.4375em", // Standard line height to maintain vertical rhythm
                  paddingTop: "6px", // Add a bit of padding to the top to ensure wrapped text doesn't touch the input border
                }}
              >
                {t('amslerGrid.gridType')}
              </InputLabel>
              <Select
                label={t('amslerGrid.gridType')} // Ensure this matches the text of the InputLabel for proper alignment and sizing
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  name: "grid-type",
                  id: "grid-type",
                }}
                value={gridType}
                onChange={(e) => setGridType(e.target.value)}
              >
                <MenuItem value="white">{t('amslerGrid.whiteBlack')}</MenuItem>
                <MenuItem value="black">{t('amslerGrid.blackWhite')}</MenuItem>
                <MenuItem value="red">{t('amslerGrid.redBlack')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              {t('commonTest.optionsTitle')}
              <IconButton onClick={handleClickOpenOptionParam()} aria-label="delete" sx={{ ml: 3 }}>
                <InfoIcon color="action" />
              </IconButton>
            </Typography>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Slider
                  aria-labelledby="input-slider"
                  onChange={handleSliderChange}
                  value={eyeFixationSensitivity}
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={1}
                  max={15}
                />
              </Grid>
              <Grid item>
                <TextField
                  value={eyeFixationSensitivity}
                  margin="dense"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: 15,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
              </Grid>
            </Grid>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="left"
              justifyContent="center"
            >
              <FormControlLabel
                fullWidth
                variant="outlined"
                margin="dense"
                control={
                  <Checkbox
                    checked={escotoma}
                    onChange={(e) => setescotoma(e.target.checked)}
                    name="Ayuda escotoma central"
                  />
                }
                label={t('campimetry.scotomaHelp')}
              />
              <FormControlLabel
                fullWidth
                variant="outlined"
                margin="dense"
                control={
                  <Checkbox
                    checked={calibrationRequired}
                    onChange={handleCalibrationRequieredCheckbox}
                    name="calibration"
                  />
                }
                label={t('commonTest.calibration')}
              />
            </Box>

          </Grid>
        </Grid>
        <Box sx={{ textAlignLast: "center" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!allFieldsValid()} // Disable the button if not all fields are valid
            sx={{ width: 200, alignSelf: "center", marginTop: 2 }}
          >
            {t('forms.submit')}
          </Button>
        </Box>
        {/* Divider and additional components */}
        <Divider sx={{ marginBottom: 5, marginTop: 5 }}></Divider>

        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          textAlign={"-webkit-center"}
        >
          <StartTestButton />
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          textAlign={"-webkit-center"}
          marginTop={2}
        >
          <Grid container spacing={2}>
            {/* Left Column */}
            <Grid item lg={12} xl={12} >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width={450}
                  height={450}
                  style={{
                    backgroundColor: `${backgroundColor}`, // Fondo negro
                  }}
                >
                  <Box
                    width={401}
                    height={401}
                    style={{
                      backgroundColor: `${backgroundColor}`, // Fondo negro
                      backgroundSize: "20px 20px",
                      backgroundImage: `linear-gradient(to right, ${lineColor} 1px, transparent 1px),linear-gradient(to bottom, ${lineColor} 1px, transparent 1px)`
                    }}
                  >
                    <Stage width={401} height={401}>
                      <Layer>
                        <Circle
                          radius={2}
                          x={201}
                          y={201}
                          stroke={lineColor}
                          strokeWidth={4}
                        />
                        {renderAmslerGridBoxes()}
                        {renderAmslerGridLines()}
                        <Circle
                          radius={eyeFixationSensitivity * 10}
                          x={201}
                          y={201}
                          stroke="#5DD55D"
                          strokeWidth={4}
                        />
                        <Circle
                          x={eyePosition.x}
                          y={eyePosition.y}
                          radius={5}
                          fill="rgb(54, 162, 235)"
                        />
                      </Layer>
                    </Stage>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ textAlignLast: "center", pt: 2 }}>
          <Button
            variant="contained"
            color="success"
            sx={{ width: 200, alignSelf: "center", marginTop: 2, mx: 2 }}
            onClick={startGeneratingPDF}
          >
            {t('pdfGeneration.downloadPDF')}
          </Button>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={10000}
          onClose={handleSnackbarClose}
          sx={{
            marginLeft: 15,
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>

      <Dialog
        open={openTestParam}
        onClose={handleCloseTestParam}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('commonTest.parametersTitle')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionTestParamRef}
            tabIndex={-1}
          >
            <strong>{t("common.eye")}:</strong> {t('commonTest.eyeParameter1')}
            <ul>
              <li><em>{t("common.right-o")}:</em> {t('commonTest.parameterRight')}</li>
              <li><em>{t("common.left-o")}:</em> {t('commonTest.parameterLeft')}</li>
              <li><em>{t("common.both")}:</em> {t('commonTest.parameterBino')}</li>
            </ul>
            <strong>{t("amslerGrid.gridType")}:</strong>
            <ul>
              <li><em>{t("amslerGrid.blackWhite")}:</em>
                <ul>
                  <li>{t("amslerGrid.paramBlackWhite1")}</li>
                  <li>{t("amslerGrid.paramBlackWhite2")}</li>
                  <li>{t("amslerGrid.paramBlackWhite3")}</li>
                </ul>
              </li>
              <li><em>{t("amslerGrid.whiteBlack")}:</em>
                <ul>
                  <li>{t("amslerGrid.paramWhiteBlack1")}</li>
                  <li>{t("amslerGrid.paramWhiteBlack2")}</li>
                  <li>{t("amslerGrid.paramWhiteBlack3")}</li>
                </ul>
              </li>
              <li><em>{t("amslerGrid.redBlack")}:</em>
                <ul>
                  <li>{t("amslerGrid.paramRedBlack1")}</li>
                  <li>{t("amslerGrid.paramRedBlack2")}</li>
                  <li>{t("amslerGrid.paramRedBlack3")}</li>
                </ul>
              </li>
            </ul>
            <p>
              <strong>{t("forms.send")}:</strong> {t("commonTest.parameterSend")}
            </p>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTestParam}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openOptionParam}
        onClose={handleCloseOptionParam}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('commonTest.optionsTitle')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionOptionParamRef}
            tabIndex={-1}
          >
            <p>
              <strong>{t("campimetry.eyeFixationSensitivity")}:</strong> {t("amslerGrid.optionsEyeTracker")}
            </p>
            <p>
              <strong>{t("campimetry.scotomaHelp")}:</strong> {t("campimetry.optionsScotomaHelp")}
            </p>
            <p>
              <strong>{t("commonTest.calibration")}:</strong> {t("commonTest.optionsCalibration")}
            </p>
            <p>
              <strong>{t("forms.send")}:</strong> {t("commonTest.parameterSend")}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOptionParam}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openInstructions}
        onClose={handleCloseInstructions}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('commonTest.instructions')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionInstructionsRef}
            tabIndex={-1}
          >
            <p>
              {t("amslerGrid.instructions1")}
            </p>
            <strong>{t("commonTest.controls")}:</strong> {t("commonTest.controlData")}
            <ul>
              <li><em>{t("commonTest.buttonA")}:</em> {t("amslerGrid.buttonA")}</li>
              <li><em>{t("commonTest.buttonB")}:</em> {t("commonTest.backMainMenu")}</li>
              <li><em>{t("commonTest.buttonTrigger")}:</em> {t("amslerGrid.trigger")}</li>
            </ul>

            <Grid container spacing={2}>
              {/* Left Column */}
              <Grid item xs={6} >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img height={350} src={FrontJoystick} alt="Logo" />;
                </Box>
              </Grid>
              <Grid item xs={6} >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img height={350} src={SideJoystick} alt="Logo" />;
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6" gutterBottom>
                    <strong>{t("commonTest.controlRight")}</strong>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInstructions}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>

      <AmslerGridPDF
        isGenerating={generatingPDF}
        stopGenerating={stopGeneratingPDF}
        userUID={currentUser.uid}
        patientUID={uid}
        gridData={{
          matrix: amslerGridStatus?.matrix,
          backgroundColor: backgroundColor,
          lineColor: lineColor,
          fillerColor: fillerColor,
          scotomaHelp: escotoma,
          eyeSelected: eye === "right" ? 1 : eye === "left" ? 0 : 2,
        }}
      />

    </React.Fragment>
  );
};

export default AmslerGridForm;
