import { Typography, Box, Modal, Snackbar, CircularProgress, Alert, Grid, Button, Slider } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import PDFHeader from "./PDFHeader";
import { useTranslation } from 'react-i18next';
import { ref, get } from 'firebase/database';
import { database } from '../../firebaseConfig';

import html2canvas from 'html2canvas';
import jsPDF from "jspdf";

import logoImage from "../../assets/dicoptProLogo.png";
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';

const WorthPDF = ({
  isGenerating,
  stopGenerating,
  userUID,
  patientUID,
  testDate,
  worthSliderIndex,
  worthSliderValues,
  worthMarks,
}) => {

  const [patientData, setPatientData] = useState(null);

  const [isModalLoadingPDF, setIsModalLoadingPDF] = useState(false);
  const [letGeneratePDF, setLetGeneratePDF] = useState(false);

  const { t } = useTranslation();

  // region PDF Popup logic
  // Lógica para generar el PDF, primero abrimos el formulario para obtener los datos necesarios
  useEffect(() => {
    if (isGenerating) {
      setIsModalLoadingPDF(true);
      generatePDF();
    }
  }, [isGenerating]);
  // endregion

  // region PDF load data
  const fetchPatientData = async () => {
    // patient data is in realtime database with uid as key
    // Define la referencia a los datos que necesitas (ajusta la ruta según tu estructura)
    const dataRef = ref(database, `patients/${userUID}/${patientUID}`);

    // Espera a obtener los datos de Firebase
    const snapshot = await get(dataRef);

    // Verifica si existen los datos
    if (snapshot.exists()) {
      // Obtén el valor de los datos
      setPatientData(snapshot.val());
    } else {
      throw new Error("No data available for the patient");
    }
  };
  const generatePDF = async () => {
    try {
      await fetchPatientData();
      setLetGeneratePDF(true);
    } catch (error) {
      console.error(error.message)
      handleSnackbarOpen();
      setIsModalLoadingPDF(false);
      stopGenerating();
    }
  };
  // endregion

  // region PDF generation
  const mainHeader = useRef(null);
  const worthSlider = useRef(null);

  useEffect(() => {
    if (letGeneratePDF) {
      // Lógica para generar el PDF
      if (worthSlider.current) {
        setTimeout(async () => {
          try {
            const canvas1 = await html2canvas(worthSlider.current, {scale: 2, useCORS: true });

            const imgData1 = canvas1.toDataURL('image/png');

            // Crear un nuevo PDF
            const pdf = new jsPDF({
              orientation: "portrait",
              unit: "mm",
              format: "a4",
              compress: true,
            });

            pdf.html(mainHeader.current, {
              callback: function (doc) {
                doc.addImage(imgData1, 'PNG', 0, 50, 200, 30, "worthSlider", "FAST"); // Tamaño A4
                doc.addImage(logoImage, "PNG", 25, 280, 70, 9, "logo", "FAST");
                doc.save(`Luces de Worth ${patientData.name} ${patientData.surname}.pdf`);
              },
              x: 0,
              y: 0,
              width: 210, // Ancho del contenido en mm
              windowWidth: 210 * 3.75 // Ancho de la ventana en px
            });
            // Guardar el PDF
            //pdf.save(`Informe_${uid}.pdf`);
          } catch (error) {
            console.error('Error al exportar las secciones a imágenes:', error);
          } finally {
            setLetGeneratePDF(false);
            setIsModalLoadingPDF(false);
            stopGenerating();
          }
        }, 100);
      }
    }
  }, [letGeneratePDF]);
  // endregion

  // region Snackbar logic
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const handleSnackbarOpen = () => {
    setSnackbarMessage("Error al descargar el documento. Si acabas de terminar la prueba, espera unos segundos y vuelve a intentarlo.");
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // endregion

  return (
    <React.Fragment>
      {
        letGeneratePDF ?
          <React.Fragment >
            <div ref={mainHeader}>
              <PDFHeader testDate={testDate}/>
              <Typography variant="h5" sx={{ ml: 5 }}>
                {t("worth.name")}
              </Typography>
            </div>
            <Box
              display="flex"
              justifyContent="center"
              width={1000}
              p={1}
              textAlign={"-webkit-center"}
              sx={{ marginTop: 6 }}
              ref={worthSlider}
            >
              <Grid container spacing={2}>
                <Grid item xl={3}>

                  <AirlineSeatReclineNormalIcon sx={{ fontSize: 80 }} />
                </Grid>
                <Grid item xl={8}>
                  <Box sx={{ width: 650, paddingBottom: 5 }}>
                    <Slider
                      //getAriaLabel={() => 'Temperature range'}
                      value={worthSliderValues}
                      min={0.4}
                      max={4}
                      //onChange={handleChange}
                      valueLabelFormat={(v) => {
                        if (v < 1) {
                          return `${v * 100} cm`;
                        }
                        else {
                          return `${v} m`;
                        }
                      }
                      }
                    //getAriaValueText={valuetext}
                    />
                    <Box sx={{ position: 'relative', height: 3 }}>
                      {worthSliderValues.map((mark) => (
                        <Button
                          key={mark}
                          variant="contained"
                          sx={{
                            position: 'absolute',
                            top: '-35px',
                            left: `${((mark - 0.4) / 3.6) * 100}%`,
                            transform: 'translateX(-50%)',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: 11,
                          }}
                        >
                          {mark < 1 ? `${mark * 100} cm` : `${mark} m`}
                        </Button>
                      ))}
                    </Box>

                    <Box sx={{ position: 'relative', height: 50 }}>
                      {worthSliderValues.map((mark, index) => (
                        <Box
                          key={mark}
                          sx={{
                            position: 'absolute',
                            left: `${((mark - 0.4) / 3.6) * 100}%`,
                            transform: 'translateX(-50%)',
                            textAlign: 'center',
                          }}
                        >
                          {worthMarks[worthSliderIndex[index]].component}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </React.Fragment >
          :
          null
      }
      {/* Loading PDF modal */}
      <Modal
        open={isModalLoadingPDF}
        onClose={() => { }}
        aria-labelledby="generating-pdf-title"
        aria-describedby="generating-pdf-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography
            id="generating-pdf-title"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            {t('pdfGeneration.modalTitle')}
          </Typography>
          <Typography id="generating-pdf-description" sx={{ mt: 2 }}>
            {t('pdfGeneration.modalDescription')}
          </Typography>
        </Box>
      </Modal>

      {/* Snackbar for error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        sx={{
          marginLeft: 15,
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default WorthPDF;