import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Button,
  Divider,
  Snackbar,
  Alert,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slider
} from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { useTranslation } from 'react-i18next';

// ICONS
import InfoIcon from '@mui/icons-material/Info';
import FrontJoystick from '../../assets/frontJoystick.png';
import SideJoystick from '../../assets/sideJoystick.png';

import { AuthContext } from "../../providers/AuthContext";
import { HeartbeatContext } from "../../providers/HeartbeatContext";

import { ref, onValue, off, set } from 'firebase/database';
import { database } from '../../firebaseConfig';

import StartTestButton from "../../components/buttons/StartTestButton";
import WorthPDF from "../../components/PDFdocuments/WorthPDF";

import EndodesviacionImage from "../../assets/worthButtons/endodesviacion_button.PNG";
import ExodesviacionImage from "../../assets/worthButtons/exodesviacion_button.PNG";
import FusionImage from "../../assets/worthButtons/fusion_button.PNG";
import HiperdesviacionODImage from "../../assets/worthButtons/hiperdesviacionOD_button.PNG";
import HiperdesviacionOIImage from "../../assets/worthButtons/hiperdesviacionOI_button.PNG";
import SupresionODImage from "../../assets/worthButtons/supresionOD_button.PNG";
import SupresionOIImage from "../../assets/worthButtons/supresionOI_button.PNG";
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';

const marks = [
  {
    component: <React.Fragment> <img src={FusionImage} alt="Imagen 1" width={60} /><Typography fontSize={12}> Fusión </Typography></React.Fragment>,
  },
  {
    component: <React.Fragment> <img src={HiperdesviacionOIImage} alt="Imagen 1" width={60} /><Typography fontSize={12}> Hiperdes-<br />viacion OI </Typography></React.Fragment>,
  },
  {
    component: <React.Fragment> <img src={HiperdesviacionODImage} alt="Imagen 1" width={60} /><Typography fontSize={12}> Hiperdes-<br />viacion OD </Typography></React.Fragment>,
  },
  {
    component: <React.Fragment> <img src={EndodesviacionImage} alt="Imagen 1" width={60} /><Typography fontSize={12}> Endodes-<br />viacion </Typography></React.Fragment>,
  },
  {
    component: <React.Fragment> <img src={ExodesviacionImage} alt="Imagen 1" width={60} /><Typography fontSize={12}> Exodes-<br />viacion </Typography></React.Fragment>,
  },
  {
    component: <React.Fragment> <img src={SupresionOIImage} alt="Imagen 1" width={60} /><Typography fontSize={12}> Supresión<br />OI </Typography></React.Fragment>,
  },
  {
    component: <React.Fragment> <img src={SupresionODImage} alt="Imagen 1" width={60} /><Typography fontSize={12}> Supresión<br />OD </Typography></React.Fragment>,
  },
  {
    component: null,
  }
];

const WorthTestForm = () => {
  const { currentUser, updateLastTestDate } = useContext(AuthContext);
  const { isAppAlive } = useContext(HeartbeatContext);
  const { uid } = useParams();

  const [movement, setMovement] = useState("static_far");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [optionSelected, setOptionSelected] = useState(-1);

  const [worthStatus, setWorthStatus] = useState({ in_test: false });

  const [worthWebResults, setWorthWebResults] = useState({ break_points: [], option_selected: [] });
  const [worthButtonsEnabled, setWorthButtonsEnabled] = useState(false);
  const [worthLastSend, setWorthLastSend] = useState(false);
  const [worthMarkLastSend, setWorthMarkLastSend] = useState(false);

  const [worthSliderValues, setWorthSliderValues] = useState([0.4, 4]);
  const [worthSliderIndex, setWorthSliderIndex] = useState([7, 7]);

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const { t } = useTranslation();

  const [openOptionParam, setOpenOptionParam] = React.useState(false);
  const [openInstructions, setOpenInstructions] = React.useState(false);

  // check start button
  useEffect(() => {
    if (!isAppAlive) return;
    if (!currentUser || currentUser.uid === null || currentUser.uid === "") return;

    const worthStatusRef = ref(database, `worth_status/${currentUser.uid}`);
    const fetchWorthStatus = (snapshot) => {
      const worthVal = snapshot.val();
      if (worthVal) {
        setWorthStatus(worthVal);
        if (!worthVal.in_test) {
          setWorthButtonsEnabled(false);
        }
        // activate buttons if test is in execution, test is not ended and if test is not in movement
        if (worthVal.in_test && !worthVal.is_ended && !worthVal.light_moving) {
          setWorthButtonsEnabled(true);
        }
        // set last button send when is_ended and in_test
        if (!worthLastSend && worthVal.is_ended && worthVal.in_test) {
          setWorthLastSend(true);
          setWorthButtonsEnabled(true);
        }
      }
      else {
        setWorthStatus({ in_test: false });
        setWorthButtonsEnabled(false);
      }
    };
    onValue(worthStatusRef, fetchWorthStatus);

    return () => {
      off(worthStatusRef, 'value', fetchWorthStatus);
      setWorthButtonsEnabled(false);
      setWorthStatus({ in_test: false });
    };
  }, [isAppAlive]);


  // worth is particular, because break points are stored in the web, only part of them are in the glasses, 
  // so when there is no information in the web and only in status, that means a reload or a previous test.
  // These will be fixed in a posterior update
  const renderWorthStatus = () => {
    // check isAppAlive
    if (!isAppAlive) {
      return (
        <Typography variant="h6" component="h2">
          {t('commonTest.appIsNotAlive')}
        </Typography>
      )
    }
    // check the in_test
    if (!worthStatus.in_test) {
      // if is_ended is true, it means that the final results are shown. Only if the actual user is the same as the actual
      if (worthStatus.is_ended) {
        if (worthStatus.actual_user !== uid) {
          return (
            <Typography variant="h6" component="h2">
              {t('commonTest.resultsAreFromOtherUser')}
            </Typography>
          )
        }
        // as the test is ended, and the user is correct, we show final results
        else {
          // check if there is information in the web, if not, it means the test is reload or old
          if (worthWebResults.break_points.length === 0) {
            return (
              <React.Fragment>
                <Typography variant="h6" component="h2">
                  {t('commonTest.waitingTest')}
                </Typography>
                <Typography variant="body1">
                  {t('commonTest.startButtonSuggestion')}
                </Typography>
              </React.Fragment>
            )
          }
          else {
            const typos = [];
            typos.push(
              <Typography variant="h6" component="h2">
                Resultados finales
              </Typography>
            );
            typos.push(
              <Typography variant="body1">
                {modeCodeToString(worthStatus.status_worth_mode)}
              </Typography>
            );
            // revise all values in worthWebResults
            for (let i = 0; i < worthWebResults.break_points.length; i++) {
              typos.push(
                <Typography variant="body1">
                  Distancia: {Math.round(worthWebResults.break_points[i] * 100) / 100} metros - Estado: {optionCodeToString(worthWebResults.option_selected[i])}
                </Typography>
              )
            }
            if (worthMarkLastSend) {
              typos.push(
                <Typography variant="body1">
                  <br />
                  La prueba ha terminado, apunte los resultados antes de retirar el visor.
                </Typography>
              )
            }
            return typos.map((line, index) => {
              return <React.Fragment key={index}>{line}</React.Fragment>;
            });
          }
        }
      }
      // if in_test and is_ended are false, means that there is no status, so we are waiting to start
      else {
        return (
          <React.Fragment>
            <Typography variant="h6" component="h2">
              {t('commonTest.waitingTest')}
            </Typography>
            <Typography variant="body1">
              {t('commonTest.startButtonSuggestion')}
            </Typography>
          </React.Fragment>
        )
      }
    }
    // now configure the text when the test is in execution
    else {
      const typos = [];
      typos.push(
        <Typography variant="h6" component="h2">
          Prueba en ejecución
        </Typography>
      );
      typos.push(
        <Typography variant="body1">
          {modeCodeToString(worthStatus.status_worth_mode)}
        </Typography>
      );
      // revise all values in worthWebResults
      for (let i = 0; i < worthWebResults.break_points.length; i++) {
        typos.push(
          <Typography variant="body1">
            Distancia: {Math.round(worthWebResults.break_points[i] * 100) / 100} metros - Estado: {optionCodeToString(worthWebResults.option_selected[i])}
          </Typography>
        )
      }
      if (worthStatus.light_moving) {
        typos.push(
          <Typography variant="body1">
            <br />
            Las luces se están desplazando. Pulse el gatillo cuando el paciente indique que las luces han cambiado.
          </Typography>
        )
      }
      else {
        if (!worthButtonsEnabled && !worthLastSend) {
          typos.push(
            <Typography variant="body1">
              <br />
              Ya ha seleccionado una opción, para continuar con el desplazamiento, pulse el gatillo.
            </Typography>
          )
        }
      }
      if (worthMarkLastSend) {
        typos.push(
          <Typography variant="body1">
            <br />
            La prueba ha terminado, apunte los resultados antes de retirar el visor.
          </Typography>
        )
      }
      return typos.map((line, index) => {
        return <React.Fragment key={index}>{line}</React.Fragment>;
      });
    }
  }

  const modeCodeToString = (mode_code) => {
    if (mode_code === "static_far") {
      return t('worth.staticFar');
    }
    else if (mode_code === "static_close") {
      return t('worth.staticClose');
    }
    else if (mode_code === "dynamic_far") {
      return t('worth.dynamicFar');
    }
    else if (mode_code === "dynamic_close") {
      return t('worth.dynamicClose');
    }
    else {
      return "Código erróneo";
    }
  }

  const optionCodeToString = (index) => {
    if (index === 0) {
      return "Fusión"
    }
    else if (index === 1) {
      return "Hiperdesviación OI"
    }
    else if (index === 2) {
      return "Hiperdesviación OD"
    }
    else if (index === 3) {
      return "Endodesviación"
    }
    else if (index === 4) {
      return "Exodesviación"
    }
    else if (index === 5) {
      return "Supresión OI"
    }
    else if (index === 6) {
      return "Supresión OD"
    }
  }

  // region Info Dialogs
  const handleClickOpenOptionParam = () => () => {
    setOpenOptionParam(true);
  };
  const handleCloseOptionParam = () => {
    setOpenOptionParam(false);
  };
  const descriptionOptionParamRef = useRef(null);
  React.useEffect(() => {
    if (openOptionParam) {
      const { current: descriptionOptionParam } = descriptionOptionParamRef;
      if (descriptionOptionParam !== null) {
        descriptionOptionParam.focus();
      }
    }
  }, [openOptionParam]);


  const handleClickOpenInstructions = () => () => {
    setOpenInstructions(true);
  };
  const handleCloseInstructions = () => {
    setOpenInstructions(false);
  };
  const descriptionInstructionsRef = useRef(null);
  React.useEffect(() => {
    if (openInstructions) {
      const { current: descriptionInstructions } = descriptionInstructionsRef;
      if (descriptionInstructions !== null) {
        descriptionInstructions.focus();
      }
    }
  }, [openInstructions]);
  // endregion

  // region Snackbar
  const handleSnackbar = () => {
    setSnackbarMessage(t('commonTest.success'));
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // endregion

  const allFieldsValid = () => {
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      config_test_type: "worth",
      config_worth_mode: movement,
      config_user_id: uid,
    };
    const userId = currentUser.uid;

    axios
      .post(
        `${SERVER_URL}/server/dicoptpro-config/${userId}`,
        formData
      )
      .then((response) => {
        console.log("Form submitted successfully", response.data);

        handleSnackbar();
        updateLastTestDate(uid);
      })
      .catch((error) => {
        console.error("Error submitting form", error);
      });

    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });

    setOptionSelected(-1);
    setWorthButtonsEnabled(false);
    setWorthLastSend(false);
    setWorthMarkLastSend(false);
    setWorthWebResults({ break_points: [], option_selected: [] });

    setWorthSliderValues([0.4, 4]);
    setWorthSliderIndex([7, 7]);
  };

  const handleOptionSelected = (index) => {
    if (optionSelected === index) {
      setOptionSelected(-1);
    }
    else {
      setOptionSelected(index);
    }
  }

  const handleOptionSelectedConfirmation = () => {
    const newWorthData = {
      break_points: [
        ...worthWebResults.break_points,
        worthStatus.break_distance
      ],
      option_selected: [
        ...worthWebResults.option_selected,
        optionSelected
      ]
    };
    setWorthWebResults(newWorthData);

    // break distance is rounded to 2 decimals
    let auxBreackDistance = Math.round(worthStatus.break_distance * 100) / 100;
    if (auxBreackDistance === 0.4) {
      let auxWorthSliderIndex = worthSliderIndex;
      auxWorthSliderIndex[0] = optionSelected;
      setWorthSliderIndex(auxWorthSliderIndex);
    }
    else if (auxBreackDistance === 4) {
      let auxWorthSliderIndex = worthSliderIndex;
      auxWorthSliderIndex[auxWorthSliderIndex.length - 1] = optionSelected;
      setWorthSliderIndex(auxWorthSliderIndex);
    }
    else {
      // when a new value is added, must be added following the order in worthSliderValues
      let auxWorthSliderValues = worthSliderValues;
      let auxWorthSliderIndex = worthSliderIndex;
      let i = 0;
      while (auxWorthSliderValues[i] < auxBreackDistance) {
        i++;
      }
      auxWorthSliderValues.splice(i, 0, auxBreackDistance);
      auxWorthSliderIndex.splice(i, 0, optionSelected);
      setWorthSliderValues(auxWorthSliderValues);
      setWorthSliderIndex(auxWorthSliderIndex);
    }
    setOptionSelected(-1);
    setWorthButtonsEnabled(false);
    if (worthLastSend) {
      setWorthMarkLastSend(true);
      saveHistoryResults(newWorthData);
    }
  }

  // save in realtime database the results of the test
  const saveHistoryResults = async (newData) => {
    if (!currentUser || currentUser.uid === null || currentUser.uid === "") return;
    if (!uid || uid === null || uid === "") return;

    // get the actual time in format YYYYMMDD-HHmmSS
    const date = new Date();
    const dateFormatted = date.toISOString().slice(0, 10).replace(/-/g, "") + "-" + date.toTimeString().slice(0, 8).replace(/:/g, "");
    const configRef = ref(database, `historical/${currentUser.uid}/worth/${uid}/${dateFormatted}`);
    const configVal = {
      break_points: newData.break_points,
      option_selected: newData.option_selected,
      test_type: movement
    };
    await set(configRef, configVal);
  };

  // region PDF generation
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const startGeneratingPDF = () => setGeneratingPDF(true);
  const stopGeneratingPDF = () => setGeneratingPDF(false);
  // endregion

  // region HTML
  // Inside your component's return statement
  return (
    <React.Fragment>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Typography variant="h4" gutterBottom sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          {t('worth.name')}
          <Button color="primary" variant="contained" onClick={handleClickOpenInstructions()} sx={{ ml: 3, borderRadius: 28 }}>
            {t('commonTest.instructions')}
          </Button>
        </Typography>
        <Grid container spacing={2}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              {t('commonTest.optionsTitle')}
              <IconButton onClick={handleClickOpenOptionParam()} aria-label="delete" sx={{ ml: 3 }}>
                <InfoIcon color="action" />
              </IconButton>
            </Typography>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="glasses-select"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {t('worth.movement')}
              </InputLabel>
              <Select
                label={t('worth.movement')} // Ensure this matches the text of the InputLabel for proper alignment and sizing
                value={movement}
                onChange={(e) => setMovement(e.target.value)}
              >
                <MenuItem value="static_far">{t('worth.staticFar')}</MenuItem>
                <MenuItem value="static_close">{t('worth.staticClose')}</MenuItem>
                <MenuItem value="dynamic_far">{t('worth.dynamicFar')}</MenuItem>
                <MenuItem value="dynamic_close">{t('worth.dynamicClose')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ textAlignLast: "center" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!allFieldsValid()} // Disable the button if not all fields are valid
            sx={{ width: 200, alignSelf: "center", marginTop: 2 }}
          >
            {t('forms.submit')}
          </Button>
        </Box>
        {/* Divider and additional components */}
        <Divider sx={{ marginBottom: 5, marginTop: 5 }}></Divider>

        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          textAlign={"-webkit-center"}
        >
          {/* First Card */}
          <Card sx={{ width: `calc(50% - 25px)`, marginRight: "25px" }}>
            <CardContent>
              {renderWorthStatus()}
            </CardContent>
          </Card>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          textAlign={"-webkit-center"}
        >
          <StartTestButton />
        </Box>

        <Divider sx={{ marginBottom: 5, marginTop: 5 }}></Divider>

        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          p={1}
          textAlign={"-webkit-center"}
          sx={{ marginTop: 6 }}
        >

          <AirlineSeatReclineNormalIcon sx={{ fontSize: 80, paddingRight: 7, marginLeft: -7, marginBottom: 5 }} />

          <Box sx={{ width: 700 }}>
            <Slider
              getAriaLabel={() => 'Temperature range'}
              value={worthSliderValues}
              min={0.4}
              max={4}
              //onChange={handleChange}
              valueLabelDisplay="on"
              valueLabelFormat={(v) => {
                if (v < 1) {
                  return `${v * 100} cm`;
                }
                else {
                  return `${v} m`;
                }
              }
              }
            //getAriaValueText={valuetext}
            />
            {/* marks: the mark position is in worthSliderValues, and the component in worthSliderIndex */}
            <Box sx={{ position: 'relative', height: 50 }}>
              {worthSliderValues.map((mark, index) => (
                <Box
                  key={mark}
                  sx={{
                    position: 'absolute',
                    left: `${((mark - 0.4) / 3.6) * 100}%`,
                    transform: 'translateX(-50%)',
                    textAlign: 'center',
                  }}
                >
                  {marks[worthSliderIndex[index]].component}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>


        {worthButtonsEnabled ?
          <React.Fragment>
            <Box
              display="flex"
              justifyContent="center"
              maxWidth="100%"
              p={1}
              textAlign={"-webkit-center"}
            >
              <Grid container justifyContent="center" spacing={2} sx={{ maxWidth: 1400 }}>
                <Grid item sm={6} md={3} xl={1.5}>
                  <Typography variant="body1">
                    {t("worth.fusion")}
                  </Typography>
                  <Button
                    variant={optionSelected === 0 ? "contained" : "outlined"}
                    color="primary"
                    sx={{ alignSelf: "center", paddingY: 1 }}
                    onClick={() => handleOptionSelected(0)}
                  >
                    <img src={FusionImage} alt="" width={80} />
                  </Button>
                </Grid>
                <Grid item sm={6} md={3} xl={1.5}>
                  <Typography variant="body1">
                    {t("worth.hdoi")}
                  </Typography>
                  <Button
                    variant={optionSelected === 1 ? "contained" : "outlined"}
                    color="primary"
                    sx={{ alignSelf: "center", paddingY: 1 }}
                    onClick={() => handleOptionSelected(1)}
                  >
                    <img src={HiperdesviacionOIImage} alt="" width={80} />
                  </Button>
                </Grid>
                <Grid item sm={6} md={3} xl={1.5}>
                  <Typography variant="body1">
                    {t("worth.hdod")}
                  </Typography>
                  <Button
                    variant={optionSelected === 2 ? "contained" : "outlined"}
                    color="primary"
                    sx={{ alignSelf: "center", paddingY: 1 }}
                    onClick={() => handleOptionSelected(2)}
                  >
                    <img src={HiperdesviacionODImage} alt="" width={80} />
                  </Button>
                </Grid>
                <Grid item sm={6} md={3} xl={1.5}>
                  <Typography variant="body1">
                    {t("worth.ed")}
                  </Typography>
                  <Button
                    variant={optionSelected === 3 ? "contained" : "outlined"}
                    color="primary"
                    sx={{ alignSelf: "center", paddingY: 1 }}
                    onClick={() => handleOptionSelected(3)}
                  >
                    <img src={EndodesviacionImage} alt="" width={80} />
                  </Button>
                </Grid>
                <Grid item sm={6} md={3} xl={1.5}>
                  <Typography variant="body1">
                    {t("worth.xd")}
                  </Typography>
                  <Button
                    variant={optionSelected === 4 ? "contained" : "outlined"}
                    color="primary"
                    sx={{ alignSelf: "center", paddingY: 1 }}
                    onClick={() => handleOptionSelected(4)}
                  >
                    <img src={ExodesviacionImage} alt="" width={80} />
                  </Button>
                </Grid>
                <Grid item sm={6} md={3} xl={1.5}>
                  <Typography variant="body1">
                    {t("worth.spoi")}
                  </Typography>
                  <Button
                    variant={optionSelected === 5 ? "contained" : "outlined"}
                    color="primary"
                    sx={{ alignSelf: "center", paddingY: 1 }}
                    onClick={() => handleOptionSelected(5)}
                  >
                    <img src={SupresionOIImage} alt="" width={80} />
                  </Button>
                </Grid>
                <Grid item sm={6} md={3} xl={1.5}>
                  <Typography variant="body1">
                    {t("worth.spod")}
                  </Typography>
                  <Button
                    variant={optionSelected === 6 ? "contained" : "outlined"}
                    color="primary"
                    sx={{ alignSelf: "center", paddingY: 1 }}
                    onClick={() => handleOptionSelected(6)}
                  >
                    <img src={SupresionODImage} alt="" width={80} />
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              p={1}
              textAlign={"-webkit-center"}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ width: 200, alignSelf: "center", marginTop: 2 }}
                onClick={handleOptionSelectedConfirmation}
              >
                {t("worth.setOption")}
              </Button>
            </Box>
          </React.Fragment>
          :
          null
        }
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          p={1}
          textAlign={"-webkit-center"}
        >
          <Button
            variant="contained"
            color="success"
            sx={{ width: 200, alignSelf: "center", marginTop: 2, mx: 2 }}
            onClick={startGeneratingPDF}
          >
            {t('pdfGeneration.downloadPDF')}
          </Button>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={10000}
          onClose={handleSnackbarClose}
          sx={{
            marginLeft: 15,
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>

      <Dialog
        open={openOptionParam}
        onClose={handleCloseOptionParam}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('commonTest.optionsTitle')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionOptionParamRef}
            tabIndex={-1}
          >
            <strong>{t("worth.movement")}:</strong>
            <ul>
              <li><em>{t("worth.staticFar")}:</em> {t("worth.paramStaticFar")}</li>
              <li><em>{t("worth.staticClose")}:</em> {t("worth.paramStaticClose")}</li>
              <li><em>{t("worth.dynamicFar")}:</em> {t("worth.paramDynamicFar")}</li>
              <li><em>{t("worth.dynamicClose")}:</em> {t("worth.paramDynamicClose")}</li>
            </ul>
            <p>
              <strong>{t("forms.send")}:</strong> {t("commonTest.parameterSend")}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOptionParam}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openInstructions}
        onClose={handleCloseInstructions}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('commonTest.instructions')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionInstructionsRef}
            tabIndex={-1}
          >
            <p>
              {t("worth.instructions1")}
            </p>
            <p>
              {t("worth.instructions2")}
            </p>
            <p>
              {t("worth.instructions3")}
            </p>
            <strong>{t("commonTest.controls")}:</strong> {t("commonTest.controlData")}.
            <ul>
              <li><em>{t("commonTest.buttonB")}:</em> {t("commonTest.backMainMenu")}</li>
              <li><em>{t("commonTest.buttonTrigger")}:</em> {t("worth.trigger")}</li>
            </ul>

            <Grid container spacing={2}>
              {/* Left Column */}
              <Grid item xs={6} >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img height={350} src={FrontJoystick} alt="Logo" />;
                </Box>
              </Grid>
              <Grid item xs={6} >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img height={350} src={SideJoystick} alt="Logo" />;
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6" gutterBottom>
                    <strong>{t("commonTest.controlRight")}</strong>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInstructions}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>

      <WorthPDF
        isGenerating={generatingPDF}
        stopGenerating={stopGeneratingPDF}
        userUID={currentUser.uid}
        patientUID={uid}
        worthSliderIndex={worthSliderIndex}
        worthSliderValues={worthSliderValues}
        worthMarks={marks}
      />

    </React.Fragment>
  );
};

export default WorthTestForm;
