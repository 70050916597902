// PermissionsRoute.js
import React from 'react';
import { Navigate, useParams } from "react-router-dom";
import usePermissions from '../hooks/usePermissions';

import { useTranslation } from 'react-i18next';

const PermissionsRoute = ({ requiredPermission, children }) => {
  const { uid } = useParams();
  const noPermissionURL = `/patient/${uid}/update`;
  const { hasPermission, loading } = usePermissions();

  const { t } = useTranslation();

  if (loading) {
    return <div>{t("common.loading")}...</div>;
  }

  if (!hasPermission(requiredPermission)) {
    return <Navigate to={noPermissionURL} replace/>;
  }
  else{
    return <>{children}</>;
  }
};

export default PermissionsRoute;
