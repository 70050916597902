import { Typography, Box, Modal, Snackbar, CircularProgress, Alert } from "@mui/material";

import React, { useRef, useState, useEffect } from "react";
import logoImage from "../../assets/dicoptProLogo.png";
import { useTranslation } from 'react-i18next';
import { ref, get } from 'firebase/database';
import { database } from '../../firebaseConfig';

import HessLancasterGrid from "../../components/Charts/HessLancasterGrid";
import PDFHeader from "./PDFHeader";

import html2canvas from 'html2canvas';
import jsPDF from "jspdf";

const HessLancasterPDF = ({
  isGenerating,
  stopGenerating,
  userUID,
  patientUID,
  testDate,
  hessData,
}) => {

  const [patientData, setPatientData] = useState(null);

  const [isModalLoadingPDF, setIsModalLoadingPDF] = useState(false);
  const [letGeneratePDF, setLetGeneratePDF] = useState(false);

  const { t } = useTranslation();

  // region PDF Popup logic
  // Lógica para generar el PDF, primero abrimos el formulario para obtener los datos necesarios
  useEffect(() => {
    if (isGenerating) {
      setIsModalLoadingPDF(true);
      generatePDF();
    }
  }, [isGenerating]);
  // endregion

  // region PDF load data
  const fetchPatientData = async () => {
    // patient data is in realtime database with uid as key
    // Define la referencia a los datos que necesitas (ajusta la ruta según tu estructura)
    const dataRef = ref(database, `patients/${userUID}/${patientUID}`);

    // Espera a obtener los datos de Firebase
    const snapshot = await get(dataRef);

    // Verifica si existen los datos
    if (snapshot.exists()) {
      // Obtén el valor de los datos
      setPatientData(snapshot.val());
    } else {
      throw new Error("No data available for the patient");
    }
  };

  const generatePDF = async () => {
    try {
      await fetchPatientData();
      setLetGeneratePDF(true);
    } catch (error) {
      console.error(error.message)
      handleSnackbarOpen();
      setIsModalLoadingPDF(false);
      stopGenerating();
    }
  };
  // endregion

  // region PDF generation
  const eyeGridLeft = useRef(null);
  const eyeGridRight = useRef(null);
  const eyeGridValuesLeft = useRef(null);
  const eyeGridValuesRight = useRef(null);
  const mainHeader = useRef(null);
  // if all data is correctly loaded, generate the PDF
  useEffect(() => {
    if (letGeneratePDF) {
      // Lógica para generar el PDF
      if (eyeGridLeft.current && eyeGridRight.current) {
        setTimeout(async () => {
          try {
            const canvas1 = await html2canvas(eyeGridLeft.current, { useCORS: true });
            const canvas2 = await html2canvas(eyeGridRight.current, { useCORS: true });
            const canvas3 = await html2canvas(eyeGridValuesLeft.current, { useCORS: true });
            const canvas4 = await html2canvas(eyeGridValuesRight.current, { useCORS: true });

            const imgData1 = canvas1.toDataURL('image/png');
            const imgData2 = canvas2.toDataURL('image/png');
            const imgData3 = canvas3.toDataURL('image/png');
            const imgData4 = canvas4.toDataURL('image/png');

            // Crear un nuevo PDF
            const pdf = new jsPDF({
              orientation: "portrait",
              unit: "mm",
              format: "a4",
              compress: true,
            });

            pdf.html(mainHeader.current, {
              callback: function (doc) {
                doc.addImage(imgData1, 'PNG', 10, 50, 90, 65, "leftGrid", "FAST"); // Tamaño A4
                doc.addImage(imgData2, 'PNG', 110, 50, 90, 65, "rightGrid", "FAST"); // Tamaño A4
                doc.addImage(imgData3, 'PNG', 10, 140, 90, 65, "leftGridValues", "FAST"); // Tamaño A4
                doc.addImage(imgData4, 'PNG', 110, 140, 90, 65, "rightGridValues", "FAST"); // Tamaño A4
                doc.addImage(logoImage, "PNG", 25, 280, 70, 9, "logo", "FAST");
                doc.save(`Hess-Lancaster ${patientData.name} ${patientData.surname}.pdf`);
              },
              x: 0,
              y: 0,
              width: 210, // Ancho del contenido en mm
              windowWidth: 210 * 3.75 // Ancho de la ventana en px
            });
            // Guardar el PDF
            //pdf.save(`Informe_${uid}.pdf`);
          } catch (error) {
            console.error('Error al exportar las secciones a imágenes:', error);
          } finally {
            setLetGeneratePDF(false);
            setIsModalLoadingPDF(false);
            stopGenerating();
          }
        }, 100);
      }

    }
  }, [letGeneratePDF]);
  // endregion

  // region Snackbar logic
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const handleSnackbarOpen = () => {
    setSnackbarMessage("Error al descargar el documento. Si acabas de terminar la prueba, espera unos segundos y vuelve a intentarlo.");
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // endregion

  return (
    <React.Fragment>
      {
        letGeneratePDF ?
          <React.Fragment>
            <div ref={mainHeader}>
              <PDFHeader testDate={testDate}/>
              <Typography variant="h5" sx={{ ml: 5 }}>
                {t("hessLancaster.name")}
              </Typography>
            </div>
            {/* Only dots left */}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              style={{ paddingBottom: 20 }}
              ref={eyeGridLeft}
              width={700}
            >
              <HessLancasterGrid
                isLeftEye={true}
                isShowingValues={false}
                hessData={hessData}
              />
            </Box>

            {/* Only dots right */}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              style={{ paddingBottom: 20 }}
              ref={eyeGridRight}
              width={700}
            >
              <HessLancasterGrid
                isLeftEye={false}
                isShowingValues={false}
                hessData={hessData}
              />
            </Box>

            {/* Dots and values left */}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              style={{ paddingBottom: 20 }}
              ref={eyeGridValuesLeft}
              width={700}
            >
              <HessLancasterGrid
                isLeftEye={true}
                isShowingValues={true}
                hessData={hessData}
              />
            </Box>

            {/* Dots and values right */}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              style={{ paddingBottom: 20 }}
              ref={eyeGridValuesRight}
              width={700}
            >
              <HessLancasterGrid
                isLeftEye={false}
                isShowingValues={true}
                hessData={hessData}
              />
            </Box>
          </React.Fragment>
          :
          null
      }

      {/* Loading PDF modal */}
      <Modal
        open={isModalLoadingPDF}
        onClose={() => { }}
        aria-labelledby="generating-pdf-title"
        aria-describedby="generating-pdf-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography
            id="generating-pdf-title"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            {t('pdfGeneration.modalTitle')}
          </Typography>
          <Typography id="generating-pdf-description" sx={{ mt: 2 }}>
            {t('pdfGeneration.modalDescription')}
          </Typography>
        </Box>
      </Modal>

      {/* Snackbar for error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        sx={{
          marginLeft: 15,
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default HessLancasterPDF;