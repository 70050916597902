import React, { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { AuthContext } from "../../providers/AuthContext";
import { HeartbeatContext } from "../../providers/HeartbeatContext";
import { useParams } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Button,
  Divider,
  Snackbar,
  Alert,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import axios from "axios";
import { ref, onValue, off, get } from "firebase/database";
import { database } from "../../firebaseConfig";

import StartTestButton from "../../components/buttons/StartTestButton";
import DynamicVAChart from "../../components/Charts/DynamicVAChart";
import DynamicVADirectionTable from "../../components/tables/DynamicVADirectionTable";
import DynamicVAPDF from "../../components/PDFdocuments/DynamicVAPDF";

// ICONS
import InfoIcon from '@mui/icons-material/Info';
import FrontJoystick from '../../assets/frontJoystick.png';
import SideJoystick from '../../assets/sideJoystick.png';

const DynamicVAForm = () => {
  const { currentUser, updateLastTestDate } = useContext(AuthContext);
  const { uid } = useParams();

  const [eye, setEye] = useState("");
  const [movement, setMovement] = useState("down_up");
  const [stimulusVelocity, setStimulusVelocity] = useState([1, 8]);
  const [isShowingValues, setIsShowingValues] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { t } = useTranslation();

  // region Check Status
  const { isAppAlive } = useContext(HeartbeatContext);
  const [dynamicVAStatus, setDynamicVAStatus] = React.useState(null);
  // status is readed at the beginning, even if the app is not alive
  useEffect(() => {
    if (!currentUser || currentUser.uid === null || currentUser.uid === "") return;
    const dynamicVAStatusRef = ref(database, `dynamic_VA_status/${currentUser.uid}`);
    get(dynamicVAStatusRef).then((snapshot) => {
      const dynamicVAVal = snapshot.val();
      if (dynamicVAVal && dynamicVAVal?.actual_user === uid) {
        setDynamicVAStatus(dynamicVAVal);
      }
      else {
        setDynamicVAStatus({
          in_test: false
        });
      }
    });
  }, [currentUser]);

  // status is readed when the app is alive, besides, the status is updated when it changes
  useEffect(() => {
    if (!isAppAlive) return;
    if (!currentUser || currentUser.uid === null || currentUser.uid === "") return;

    const dynamicVAStatusRef = ref(database, `dynamic_VA_status/${currentUser.uid}`);
    const fetchDynamicVAStatus = (snapshot) => {
      const dynamicVAVal = snapshot.val();
      if (dynamicVAVal && dynamicVAVal?.actual_user === uid) {
        setDynamicVAStatus(dynamicVAVal);
      }
      else {
        setDynamicVAStatus({
          in_test: false
        });
      }
    };
    onValue(dynamicVAStatusRef, fetchDynamicVAStatus);

    return () => {
      off(dynamicVAStatusRef, 'value', fetchDynamicVAStatus);
    };
  }, [isAppAlive]);
  // endregion

  // region Test, Option and Instructions Dialogs
  const [openTestParam, setOpenTestParam] = React.useState(false);
  const [openOptionParam, setOpenOptionParam] = React.useState(false);
  const [openInstructions, setOpenInstructions] = React.useState(false);

  const handleClickOpenTestParam = () => () => {
    setOpenTestParam(true);
  };

  const handleCloseTestParam = () => {
    setOpenTestParam(false);
  };

  const descriptionTestParamRef = useRef(null);
  React.useEffect(() => {
    if (openTestParam) {
      const { current: descriptionTestParam } = descriptionTestParamRef;
      if (descriptionTestParam !== null) {
        descriptionTestParam.focus();
      }
    }
  }, [openTestParam]);

  const handleClickOpenOptionParam = () => () => {
    setOpenOptionParam(true);
  };

  const handleCloseOptionParam = () => {
    setOpenOptionParam(false);
  };

  const descriptionOptionParamRef = useRef(null);
  React.useEffect(() => {
    if (openOptionParam) {
      const { current: descriptionOptionParam } = descriptionOptionParamRef;
      if (descriptionOptionParam !== null) {
        descriptionOptionParam.focus();
      }
    }
  }, [openOptionParam]);

  const handleClickOpenInstructions = () => () => {
    setOpenInstructions(true);
  };

  const handleCloseInstructions = () => {
    setOpenInstructions(false);
  };

  const descriptionInstructionsRef = useRef(null);
  React.useEffect(() => {
    if (openInstructions) {
      const { current: descriptionInstructions } = descriptionInstructionsRef;
      if (descriptionInstructions !== null) {
        descriptionInstructions.focus();
      }
    }
  }, [openInstructions]);
  // endregion

  // region PDF generation
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const startGeneratingPDF = () => setGeneratingPDF(true);
  const stopGeneratingPDF = () => setGeneratingPDF(false);
  // endregion

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const handleSnackbar = () => {
    setSnackbarMessage(t('commonTest.success'));
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const allFieldsValid = () => {
    return eye !== "";
  };

  const printStatus = () => {
    return "En desarrollo";
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    // Form data to be sent to the server, stimulusVelocity is an array with min and max values
    const formData = {
      config_test_type: "dynamic_va",
      config_eye: eye,
      config_dynamicVA_direction: movement,
      config_dynamicVA_velocity_min: stimulusVelocity[0] * 5,
      config_dynamicVA_velocity_max: stimulusVelocity[1] * 5,
      config_user_id: uid,
    };
    const userId = currentUser.uid;

    axios
      .post(
        `${SERVER_URL}/server/dicoptpro-config/${userId}`,
        formData
      )
      .then((response) => {
        console.log("Form submitted successfully", response.data);

        handleSnackbar();
        updateLastTestDate(uid);
      })
      .catch((error) => {
        console.error("Error submitting form", error);
      });

    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleChangeVelocity = (event, newValue) => {
    setStimulusVelocity(newValue);
  };

  // Inside your component's return statement
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Typography variant="h4" gutterBottom>
        {t('dva.name')}
        <Button color="primary" variant="contained" onClick={handleClickOpenInstructions()} sx={{ ml: 3, borderRadius: 28 }}>
          {t('commonTest.instructions')}
        </Button>
      </Typography>
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            {t('commonTest.parametersTitle')}
            <IconButton onClick={handleClickOpenTestParam()} aria-label="delete" sx={{ ml: 3 }}>
              <InfoIcon color="action" />
            </IconButton>
          </Typography>
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel
              htmlFor="glasses-select"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {t('common.eye')}
            </InputLabel>
            <Select
              value={eye}
              label="Eye"
              onChange={(e) => setEye(e.target.value)}
            >
              <MenuItem value="right">{t('common.right')}</MenuItem>
              <MenuItem value="left">{t('common.left')}</MenuItem>
              <MenuItem value="none">{t('common.both')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            {t('commonTest.optionsTitle')}
            <IconButton onClick={handleClickOpenOptionParam()} aria-label="delete" sx={{ ml: 3 }}>
              <InfoIcon color="action" />
            </IconButton>
          </Typography>
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel
              htmlFor="movement-type"
              style={{
                // Adjust the style to allow for wrapping and ensure adequate spacing
                whiteSpace: "normal", // Allow the label to wrap onto multiple lines as needed
                lineHeight: "1.4375em", // Standard line height to maintain vertical rhythm
                paddingTop: "6px", // Add a bit of padding to the top to ensure wrapped text doesn't touch the input border
              }}
            >
              {t('dva.movement')}
            </InputLabel>
            <Select
              label={t('dva.movement')} // Ensure this matches the text of the InputLabel for proper alignment and sizing
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                name: "movement-type",
                id: "movement-type",
              }}
              value={movement}
              onChange={(e) => setMovement(e.target.value)}
            >
              <MenuItem value="down_up">&#8593; {t('dva.down_up')}</MenuItem>
              <MenuItem value="up_down">&#8595; {t('dva.up_down')}</MenuItem>
              <MenuItem value="left_right">&#8594; {t('dva.left_right')}</MenuItem>
              <MenuItem value="right_left">&#8592; {t('dva.right_left')}</MenuItem>
              <MenuItem value="downLeft_upRight">&#8599; {t('dva.downLeft_upRight')}</MenuItem>
              <MenuItem value="downRight_upLeft">&#8598; {t('dva.downRight_upLeft')}</MenuItem>
              <MenuItem value="upLeft_downRight">&#8600; {t('dva.upLeft_downRight')}</MenuItem>
              <MenuItem value="upRight_downLeft">&#8601; {t('dva.upRight_downLeft')}</MenuItem>
              <MenuItem value="parableLeft_right">&#8631; {t('dva.parableLeft_right')}</MenuItem>
              <MenuItem value="parableRight_left">&#8630; {t('dva.parableRight_left')}</MenuItem>
              <MenuItem value="random">{t('dva.random')}</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined" margin="dense">
            <Box padding={2}>
              <Typography variant="h6">
                {t('dva.stimulusVelocity')}
              </Typography>
              <Box paddingTop={7} paddingX={7}>
                <Slider
                  getAriaLabel={() => 'Velocity'}
                  value={stimulusVelocity}
                  min={1}
                  max={8}
                  onChange={handleChangeVelocity}
                  // marks are from 5 to 100 with a step of 5 and the km/h label
                  marks={[{ value: 1, label: '5 km/h' }, { value: 8, label: '40 km/h' }]}
                  valueLabelDisplay="on"
                  valueLabelFormat={(v) => {
                    return `${v * 5} km/h`;
                  }}
                //getAriaValueText={valuetext}
                />
              </Box>
              <Typography variant="body2">
                {t('dva.velocityInfo')}
              </Typography>
            </Box>
          </FormControl>

        </Grid>
      </Grid>
      <Box sx={{ textAlignLast: "center" }}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!allFieldsValid()} // Disable the button if not all fields are valid
          sx={{ width: 200, alignSelf: "center", marginTop: 2, paddingX: 4 }}
        >
          {t('forms.submit')}
        </Button>
      </Box>
      {/* Divider and additional components */}
      <Divider sx={{ marginBottom: 5, marginTop: 5 }}></Divider>

      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        textAlign={"-webkit-center"}
      >
        <StartTestButton />
      </Box>

      {/* Status Card 
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        p={1}
        textAlign={"-webkit-center"}
      >
        <Card sx={{ width: `calc(50% - 25px)`, marginRight: "25px" }}>
          <CardContent>
            <Typography variant="h6" component="h2">
              {printStatus()}
            </Typography>
            <Typography variant="body1" sx={"black"}>
              {printValue()}
            </Typography>
          </CardContent>
        </Card>
      </Box>
      */}

      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        textAlign={"-webkit-center"}
        sx={{ marginTop: 5 }}
      >
        <Grid container spacing={2}>
          {/* Left Column */}
          <Grid item lg={12} xl={6} >
            <DynamicVAChart dynamicVAStatus={dynamicVAStatus} isShowingValues={isShowingValues} />
          </Grid>
          <Grid item lg={12} xl={6} >
            <DynamicVADirectionTable dynamicVAStatus={dynamicVAStatus} heightLimit={500}/>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ textAlignLast: "center", pt: 2 }}>
        {/* Button to show or hide the values. When is clicked, change isShowingValues. Besides change variant between contained and outlined */}
        <Button
          variant={isShowingValues ? "outlined" : "contained"}
          color="primary"
          sx={{ alignSelf: "center", marginTop: 2, mx: 2 }}
          onClick={() => setIsShowingValues(!isShowingValues)}
        >
          {/* if isShowingValues is true, show the text "Hide Values", otherwise show the text "Show Values" */}
          {isShowingValues ? t('dva.hideTimes') : t('dva.showTimes')}
        </Button>

        <Button
          variant="contained"
          color="success"
          sx={{ width: 200, alignSelf: "center", marginTop: 2, mx: 2 }}
          onClick={startGeneratingPDF}
        >
          {t('pdfGeneration.downloadPDF')}
        </Button>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={handleSnackbarClose}
        sx={{
          marginLeft: 15,
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={openTestParam}
        onClose={handleCloseTestParam}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('commonTest.parametersTitle')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionTestParamRef}
            tabIndex={-1}
          >
            <p>
              <strong>{t("common.eye")}:</strong> {t('commonTest.eyeParameter1')}
              <ul>
                <li><em>{t("common.right-o")}:</em> {t('commonTest.parameterRight')}</li>
                <li><em>{t("common.left-o")}:</em> {t('commonTest.parameterLeft')}</li>
                <li><em>{t("common.both")}:</em> {t('commonTest.parameterBino')}</li>
              </ul>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTestParam}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openOptionParam}
        onClose={handleCloseOptionParam}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('commonTest.optionsTitle')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionOptionParamRef}
            tabIndex={-1}
          >
            <p>
              {t('dva.optionMovement')}
              <ul>
                <li>&#8593; {t('dva.down_up')}</li>
                <li>&#8595; {t('dva.up_down')}</li>
                <li>&#8594; {t('dva.left_right')}</li>
                <li>&#8592; {t('dva.right_left')}</li>
                <li>&#8599; {t('dva.downLeft_upRight')}</li>
                <li>&#8598; {t('dva.downRight_upLeft')}</li>
                <li>&#8600; {t('dva.upLeft_downRight')}</li>
                <li>&#8601; {t('dva.upRight_downLeft')}</li>
                <li>&#8631; {t('dva.parableLeft_right')}</li>
                <li>&#8630; {t('dva.parableRight_left')}</li>
                <li>{t('dva.random')}: {t('dva.randomInfo')}</li>
              </ul>
            </p>
            <p>
              {t('dva.optionSpeed1')}
              <ul>
                <li>{t('dva.optionSpeed2')}</li>
              </ul>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOptionParam}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openInstructions}
        onClose={handleCloseInstructions}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('commonTest.instructions')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionInstructionsRef}
            tabIndex={-1}
          >
            <p>
              {t('dva.instructions0')}
            </p>
            <p>
              {t('dva.instructions1')}
            </p>
            <p>
              {t('dva.instructions2')}
            </p>
            <p>
              <strong>{t('commonTest.controls')}:</strong> {t("commonTest.controlData")}
              <ul>
                <li><em>{t("commonTest.buttonB")}:</em> {t("commonTest.backMainMenu")}</li>
                <li><em>{t("commonTest.buttonA")}:</em> {t("dva.buttonA")}</li>
                <li><em>{t("commonTest.buttonTrigger")}:</em> {t("dva.buttonTrigger")}</li>
              </ul>
            </p>

            <Grid container spacing={2}>
              {/* Left Column */}
              <Grid item xs={6} >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img height={350} src={FrontJoystick} alt="Logo" />;
                </Box>
              </Grid>
              <Grid item xs={6} >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img height={350} src={SideJoystick} alt="Logo" />;
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6" gutterBottom>
                    <strong>{t("commonTest.controlRight")}</strong>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInstructions}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>

      <DynamicVAPDF
        isGenerating={generatingPDF}
        stopGenerating={stopGeneratingPDF}
        userUID={currentUser.uid}
        patientUID={uid}
        dvaData={dynamicVAStatus}
      >
      </DynamicVAPDF>

    </Box>
  );
};

export default DynamicVAForm;
