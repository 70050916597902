import {
  Box,
  Button,
  CardActionArea
} from "@mui/material";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";

import { Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import usePermissions from '../../hooks/usePermissions';

import CampimetriaImage from "../../assets/mainContentImages/Campimetria.jpg";
import HessLancasterImage from "../../assets/mainContentImages/HessLancaster.jpg";
import SnellenImage from "../../assets/mainContentImages/Snellen.jpg";
import RejillaAmslerImage from "../../assets/mainContentImages/RejillaAmsler.jpg";
import SensibilidadContrasteImage from "../../assets/mainContentImages/SensibilidadContraste.jpg";
import AVDinamicaImage from "../../assets/mainContentImages/AVDinamica.jpg";
import WorthImage from "../../assets/mainContentImages/Worth.jpg";
import ForiaImage from "../../assets/mainContentImages/foriaImage.PNG";

export default function MainContent() {
  const { uid } = useParams();
  const { t } = useTranslation();
  const { hasPermission, loading } = usePermissions();

  if (loading) {
    return <div>{t("common.loading")}...</div>;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Grid container spacing={2}>
        {hasPermission("campimetry") ?
          <Grid item xs={12} md={6} lg={4}>
            <Card sx={{ maxWidth: 500 }}
              style={{
                margin: '20px', // Puedes ajustar este valor según sea necesario
              }}>
              <CardActionArea
                component={Link}
                to={`/patient/${uid}/campimetria`}
              >
                <CardMedia
                  sx={{ height: 200 }}
                  image={CampimetriaImage}
                  title="campimetry"
                />
              </CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {t('campimetry.name')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <p>
                    {t('campimetry.description1')}
                  </p>
                  <p>
                    {t('campimetry.description2')}
                  </p>
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small"
                  component={Link}
                  to={`/patient/${uid}/campimetria`}
                >
                  {t('commonTest.takeTest')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          : null
        }

        {hasPermission("hess_lancaster") ?
          <Grid item xs={12} md={6} lg={4}>
            <Card sx={{ maxWidth: 500 }}
              style={{
                margin: '20px', // Puedes ajustar este valor según sea necesario
              }}>
              <CardActionArea
                component={Link}
                to={`/patient/${uid}/hesslancaster`}
              >
                <CardMedia
                  sx={{ height: 200 }}
                  image={HessLancasterImage}
                  title="hess lancaster"
                />
              </CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {t('hessLancaster.name')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <p>
                    {t('hessLancaster.description1')}
                  </p>
                  <p>
                    {t('hessLancaster.description2')}
                  </p>
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small"
                  component={Link}
                  to={`/patient/${uid}/hesslancaster`}
                >
                  {t('commonTest.takeTest')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          : null
        }

        {hasPermission("foria") ?
          <Grid item xs={12} md={6} lg={4}>
            <Card sx={{ maxWidth: 500 }}
              style={{
                margin: '20px', // Puedes ajustar este valor según sea necesario
              }}>
              <CardActionArea
                component={Link}
                to={`/patient/${uid}/foria`}
              >
                <CardMedia
                  sx={{ height: 200 }}
                  image={ForiaImage}
                  title="foria"
                />
              </CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {t('foria.name')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Test en desarrollo.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small"
                  component={Link}
                  to={`/patient/${uid}/foria`}
                >
                  {t('commonTest.takeTest')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          : null
        }

        {hasPermission("worth_test") ?
          <Grid item xs={12} md={6} lg={4}>
            <Card sx={{ maxWidth: 500 }}
              style={{
                margin: '20px', // Puedes ajustar este valor según sea necesario
              }}>
              <CardActionArea
                component={Link}
                to={`/patient/${uid}/worthtest`}
              >
                <CardMedia
                  sx={{ height: 200 }}
                  image={WorthImage}
                  title="worthTest"
                />
              </CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {t('worth.name')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <p>
                    {t('worth.description1')}
                  </p>
                  <p>
                    {t('worth.description2')}
                  </p>
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small"
                  component={Link}
                  to={`/patient/${uid}/worthtest`}
                >
                  {t('commonTest.takeTest')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          : null
        }

        {hasPermission("amsler_grid") ?
          <Grid item xs={12} md={6} lg={4}>
            <Card sx={{ maxWidth: 500 }}
              style={{
                margin: '20px', // Puedes ajustar este valor según sea necesario
              }}>
              <CardActionArea
                component={Link}
                to={`/patient/${uid}/amslergrid`}
              >
                <CardMedia
                  sx={{ height: 200 }}
                  image={RejillaAmslerImage}
                  title="amslerGrid"
                />
              </CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {t('amslerGrid.name')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <p>
                    {t('amslerGrid.description1')}
                  </p>
                  <p>
                    {t('amslerGrid.description2')}
                  </p>
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small"
                  component={Link}
                  to={`/patient/${uid}/amslergrid`}
                >
                  {t('commonTest.takeTest')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          : null
        }

        {hasPermission("contrast_sensitivity") ?
          <Grid item xs={12} md={6} lg={4}>
            <Card sx={{ maxWidth: 500 }}
              style={{
                margin: '20px', // Puedes ajustar este valor según sea necesario
              }}>
              <CardActionArea
                component={Link}
                to={`/patient/${uid}/contrastsensitivity`}
              >
                <CardMedia
                  sx={{ height: 200 }}
                  image={SensibilidadContrasteImage}
                  title="contrastSensitivity"
                />
              </CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {t('contrastSensitivity.name')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <p>
                    {t('contrastSensitivity.description1')}
                  </p>
                  <p>
                    {t('contrastSensitivity.description2')}
                  </p>
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small"
                  component={Link}
                  to={`/patient/${uid}/contrastsensitivity`}
                >
                  {t('commonTest.takeTest')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          : null
        }

        {hasPermission("esnellen") ?
          <Grid item xs={12} md={6} lg={4}>
            <Card sx={{ maxWidth: 500 }}
              style={{
                margin: '20px', // Puedes ajustar este valor según sea necesario
              }}>
              <CardActionArea
                component={Link}
                to={`/patient/${uid}/visualacuity`}
              >
                <CardMedia
                  sx={{ height: 200 }}
                  image={SnellenImage}
                  title="visualAcuity"
                />
              </CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {t('eSnellen.name')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <p>
                    {t('eSnellen.description1')}
                  </p>
                  <p>
                    {t('eSnellen.description2')}
                  </p>
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small"
                  component={Link}
                  to={`/patient/${uid}/visualacuity`}
                >
                  {t('commonTest.takeTest')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          : null
        }

        {hasPermission("dynamic_va") ?
          <Grid item xs={12} md={6} lg={4}>
            <Card sx={{ maxWidth: 500 }}
              style={{
                margin: '20px', // Puedes ajustar este valor según sea necesario
              }}>
              <CardActionArea
                component={Link}
                to={`/patient/${uid}/dynamicvisualacuity`}
              >
                <CardMedia
                  sx={{ height: 200 }}
                  image={AVDinamicaImage}
                  title="dynamicVisualAcuity"
                />
              </CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {t('dva.name')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <p>
                    {t('dva.description1')}
                  </p>
                  <p>
                    {t('dva.description2')}
                  </p>
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small"
                  component={Link}
                  to={`/patient/${uid}/dynamicvisualacuity`}
                >
                  {t('commonTest.takeTest')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          : null
        }
      </Grid>
    </Box>
  )
}