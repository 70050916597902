import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';

import { ref, onValue, off, set } from 'firebase/database';
import { database } from '../../firebaseConfig';

import {Button} from "@mui/material";

import { AuthContext } from "../../providers/AuthContext";
import { HeartbeatContext } from "../../providers/HeartbeatContext";


const StartTestCalibrationButton = () => {
  const [launchTestButtonEnabled, setLaunchTestButtonEnabled] = useState(false);

  const { currentUser } = useContext(AuthContext);
  const { isAppAlive } = useContext(HeartbeatContext);

  const { t } = useTranslation();

  // check start button
  useEffect(() => {
    if (!isAppAlive) return;
    if (!currentUser || currentUser.uid===null || currentUser.uid==="" ) return;

    const appStatusRef = ref(database, `appStatus/${currentUser.uid}`);
    const handleAppStatusChange = (snapshot) => {
      const appStatus = snapshot.val();
      if (appStatus) {
        // Check if the app is in the idle, is active and the last heartbeat is recent. Time is in UtcNow.ToUnixTimeSeconds from Unity
        const isIdle = appStatus.sceneStatus === 'calibration';
        const isOnline = appStatus.lineStatus === 'online';
        const isActive = isIdle && isOnline;
        setLaunchTestButtonEnabled(isActive);
      } else {
        setLaunchTestButtonEnabled(false);
      }
    };
    onValue(appStatusRef, handleAppStatusChange);
    
    return () => {
      // Cleanup listener on unmount
      off(appStatusRef, 'value', handleAppStatusChange);
      setLaunchTestButtonEnabled(false);
    };
  }, [isAppAlive]);

  // when the start button is clicked, we need to update the database to start the test, in config_web, two variables are set: launchTest is true and launchTestReaded is false
  const handleStartClick = async () => {
    if (!currentUser || currentUser.uid===null || currentUser.uid==="" ) return;

    const configRef = ref(database, `config_web/${currentUser.uid}`);
    const configVal = {
      launchTestCalibration: true,
      launchTestCalibrationReaded: false
    };
    await set(configRef, configVal);
  };

  return (
    <Button
      variant="contained"
      color="success"
      disabled={!launchTestButtonEnabled} // Disable the button if not all fields are valid
      sx={{ width: 200, alignSelf: "center", marginTop: 2, mx: 2 }}
      onClick={handleStartClick}
    >
      {t('commonTest.continueTest')}
    </Button>
  );
};

export default StartTestCalibrationButton;