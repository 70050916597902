import { Typography, Box, Modal, Snackbar, CircularProgress, Alert } from "@mui/material";

import React, { useRef, useState, useEffect } from "react";
import logoImage from "../../assets/dicoptProLogo.png";
import { useTranslation } from 'react-i18next';
import { ref, get } from 'firebase/database';
import { database } from '../../firebaseConfig';

import ContrastSensitivityBars from "../../components/Charts/ContrastSensitivityBars";
import ContrastSensitivity12 from "../../components/Charts/ContrastSensitivity12";
import PDFHeader from "./PDFHeader";

import html2canvas from 'html2canvas';
import jsPDF from "jspdf";

const ContrastSensitivityPDF = ({
  isGenerating,
  stopGenerating,
  userUID,
  patientUID,
  testDate,
  contrastData,
  contrastEye
}) => {
  const [patientData, setPatientData] = useState(null);

  const [isModalLoadingPDF, setIsModalLoadingPDF] = useState(false);
  const [letGeneratePDF, setLetGeneratePDF] = useState(false);

  const { t } = useTranslation();

  // region PDF Popup logic
  // Lógica para generar el PDF, primero abrimos el formulario para obtener los datos necesarios
  useEffect(() => {
    if (isGenerating) {
      setIsModalLoadingPDF(true);
      generatePDF();
    }
  }, [isGenerating]);
  // endregion

  // region PDF load data
  const fetchPatientData = async () => {
    // patient data is in realtime database with uid as key
    // Define la referencia a los datos que necesitas (ajusta la ruta según tu estructura)
    const dataRef = ref(database, `patients/${userUID}/${patientUID}`);

    // Espera a obtener los datos de Firebase
    const snapshot = await get(dataRef);

    // Verifica si existen los datos
    if (snapshot.exists()) {
      // Obtén el valor de los datos
      setPatientData(snapshot.val());
    } else {
      throw new Error("No data available for the patient");
    }
  };

  const generatePDF = async () => {
    try {
      await fetchPatientData();
      setLetGeneratePDF(true);
    } catch (error) {
      console.error(error.message)
      handleSnackbarOpen();
      setIsModalLoadingPDF(false);
      stopGenerating();
    }
  };
  // endregion

  // region PDF generation
  const mainChartRef = useRef(null);
  const oldChartRef = useRef(null);
  const mainHeader = useRef(null);
  // if all data is correctly loaded, generate the PDF
  useEffect(() => {
    if (letGeneratePDF) {
      // Lógica para generar el PDF
      if (mainChartRef.current) {
        setTimeout(async () => {
          try {
            const canvas1 = await html2canvas(mainChartRef.current, {scale: 3,
              useCORS: true,
              allowTaint: true,
              imageTimeout: 0,
              logging: true,
              backgroundColor: null
            });
            const canvas2 = await html2canvas(oldChartRef.current, {scale: 2, useCORS: true});
            const imgData1 = canvas1.toDataURL('image/png',1.0);
            const imgData2 = canvas2.toDataURL('image/png',1.0);

            // Obtener dimensiones de la tabla
            const tableWidth = mainChartRef.current.offsetWidth;
            const tableHeight = mainChartRef.current.offsetHeight;

            // Obtener dimensiones del oldChart
            const oldChartWidth = oldChartRef.current.offsetWidth;
            const oldChartHeight = oldChartRef.current.offsetHeight;

            // Crear un nuevo PDF
            const pdf = new jsPDF({
              orientation: "portrait",
              unit: "mm",
              format: "a4",
              compress: true,
            });

            pdf.html(mainHeader.current, {
              callback: function (doc) {
                doc.addImage(imgData1, 'PNG', 47, 52, tableWidth/5, tableHeight/5, "mainChart", "SLOW"); // Tamaño A4
                doc.addImage(imgData2, 'PNG', 45, 150, oldChartWidth/5, oldChartHeight/5, "oldChart", "FAST");
                doc.addImage(logoImage, "PNG", 25, 280, 70, 9, "logo", "FAST");
                doc.save(`Sensibilidad al contraste ${patientData.name} ${patientData.surname}.pdf`);
              },
              x: 0,
              y: 0,
              width: 210, // Ancho del contenido en mm
              windowWidth: 210 * 3.75 // Ancho de la ventana en px
            });
            // Guardar el PDF
            //pdf.save(`Informe_${uid}.pdf`);
          } catch (error) {
            console.error('Error al exportar las secciones a imágenes:', error);
          } finally {
            setLetGeneratePDF(false);
            setIsModalLoadingPDF(false);
            stopGenerating();
          }
        }, 100);
      }

    }
  }, [letGeneratePDF]);
  // endregion

  // region Snackbar logic
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const handleSnackbarOpen = () => {
    setSnackbarMessage("Error al descargar el documento. Si acabas de terminar la prueba, espera unos segundos y vuelve a intentarlo.");
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // endregion
  return (
    <React.Fragment>
      {
        letGeneratePDF ?
        <React.Fragment>
            <div ref={mainHeader}>
              <PDFHeader testDate={testDate}/>
              <Typography variant="h5" sx={{ ml: 5 }}>
                {t("contrastSensitivity.name")}
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h6" sx={{ mt: 2 }}>
                  {t('common.eye')}: {contrastEye === 1 ? t('common.right-o') : contrastEye === 0 ? t('common.left-o') : t('common.both')}
                </Typography>
              </Box>
            </div>
            
            <Box
              ref={mainChartRef}
            >
              <ContrastSensitivityBars contrastSensitivityStatus={contrastData} />
            </Box>
            <Box
              ref={oldChartRef}
            >
              <ContrastSensitivity12 contrastSensitivityStatus={contrastData} />
            </Box>
          </React.Fragment>
          
          :
          null
      }
      {/* Loading PDF modal */}
      <Modal
        open={isModalLoadingPDF}
        onClose={() => { }}
        aria-labelledby="generating-pdf-title"
        aria-describedby="generating-pdf-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography
            id="generating-pdf-title"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            {t('pdfGeneration.modalTitle')}
          </Typography>
          <Typography id="generating-pdf-description" sx={{ mt: 2 }}>
            {t('pdfGeneration.modalDescription')}
          </Typography>
        </Box>
      </Modal>

      {/* Snackbar for error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        sx={{
          marginLeft: 15,
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default ContrastSensitivityPDF;