import React, { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';

import { useParams } from "react-router-dom";
import { AuthContext } from "../../providers/AuthContext";
import { HeartbeatContext } from "../../providers/HeartbeatContext";

import ContrastSensitivityBars from "../../components/Charts/ContrastSensitivityBars";

import {
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Button,
  Divider,
  Snackbar,
  Alert,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { ref, onValue, off, get } from "firebase/database";
import { database } from "../../firebaseConfig";

import StartTestButton from "../../components/buttons/StartTestButton";
import ContrastSensitivityPDF from "../../components/PDFdocuments/ContrastSensitivityPDF";

// ICONS
import InfoIcon from '@mui/icons-material/Info';
import FrontJoystick from '../../assets/frontJoystick.png';
import SideJoystick from '../../assets/sideJoystick.png';

const ContrastSensitivityForm = () => {
  const [eye, setEye] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const { uid } = useParams();
  const { currentUser, updateLastTestDate } = useContext(AuthContext);
  const { t } = useTranslation();

  // region Check Status
  const { isAppAlive } = useContext(HeartbeatContext);
  const [contrastSensitivityStatus, setContrastSensitivityStatus] = React.useState(null);

  // status is readed at the beginning, even if the app is not alive
  useEffect(() => {
    if (!currentUser || currentUser.uid === null || currentUser.uid === "") return;
    const contrastSensitivityStatusRef = ref(database, `contrast_sensitivity_status/${currentUser.uid}`);
    get(contrastSensitivityStatusRef).then((snapshot) => {
      const contrastSensitivityVal = snapshot.val();
      if (contrastSensitivityVal && contrastSensitivityVal?.actual_user === uid) {
        setContrastSensitivityStatus(contrastSensitivityVal);
      }
      else {
        setContrastSensitivityStatus({
          in_test: false,
          cpd_1_5_step: -1,
          cpd_3_step: -1,
          cpd_6_step: -1,
          cpd_12_step: -1,
          cpd_18_step: -1
        });
      }
    });
  }, [currentUser]);

  // status is readed when the app is alive, besides, the status is updated when it changes
  useEffect(() => {
    if (!isAppAlive) return;
    if (!currentUser || currentUser.uid === null || currentUser.uid === "") return;

    const contrastSensitivityStatusRef = ref(database, `contrast_sensitivity_status/${currentUser.uid}`);
    const fetchConstrastSensitivityStatus = (snapshot) => {
      const contrastSensitivityVal = snapshot.val();
      if (contrastSensitivityVal && contrastSensitivityVal?.actual_user === uid) {
        setContrastSensitivityStatus(contrastSensitivityVal);
      }
      else {
        setContrastSensitivityStatus({
          in_test: false,
          cpd_1_5_step: -1,
          cpd_3_step: -1,
          cpd_6_step: -1,
          cpd_12_step: -1,
          cpd_18_step: -1
        });
      }
    };
    onValue(contrastSensitivityStatusRef, fetchConstrastSensitivityStatus);

    return () => {
      off(contrastSensitivityStatusRef, 'value', fetchConstrastSensitivityStatus);
    };
  }, [isAppAlive]);
  // endregion

  // region PDF generation
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const startGeneratingPDF = () => setGeneratingPDF(true);
  const stopGeneratingPDF = () => setGeneratingPDF(false);
  // endregion

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  // region Snackbar logic
  const handleSnackbar = () => {
    setSnackbarMessage(t('commonTest.success'));
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // endregion

  // region Test, Option and Instructions Dialogs
  const [openTestParam, setOpenTestParam] = React.useState(false);
  const [openOptionParam, setOpenOptionParam] = React.useState(false);
  const [openInstructions, setOpenInstructions] = React.useState(false);

  const handleClickOpenTestParam = () => () => {
    setOpenTestParam(true);
  };

  const handleCloseTestParam = () => {
    setOpenTestParam(false);
  };

  const descriptionTestParamRef = useRef(null);
  React.useEffect(() => {
    if (openTestParam) {
      const { current: descriptionTestParam } = descriptionTestParamRef;
      if (descriptionTestParam !== null) {
        descriptionTestParam.focus();
      }
    }
  }, [openTestParam]);

  const handleClickOpenOptionParam = () => () => {
    setOpenOptionParam(true);
  };

  const handleCloseOptionParam = () => {
    setOpenOptionParam(false);
  };

  const descriptionOptionParamRef = useRef(null);
  React.useEffect(() => {
    if (openOptionParam) {
      const { current: descriptionOptionParam } = descriptionOptionParamRef;
      if (descriptionOptionParam !== null) {
        descriptionOptionParam.focus();
      }
    }
  }, [openOptionParam]);

  const handleClickOpenInstructions = () => () => {
    setOpenInstructions(true);
  };

  const handleCloseInstructions = () => {
    setOpenInstructions(false);
  };

  const descriptionInstructionsRef = useRef(null);
  React.useEffect(() => {
    if (openInstructions) {
      const { current: descriptionInstructions } = descriptionInstructionsRef;
      if (descriptionInstructions !== null) {
        descriptionInstructions.focus();
      }
    }
  }, [openInstructions]);
  // endregion

  // region Form validation
  const allFieldsValid = () => {
    // check if there is at least one CPD selected
    if (!checked.some(Boolean)) {
      return false;
    }
    return eye;
  };

  const checkboxLabels = ["1.5 CPD", "3 CPD", "6 CPD", "12 CPD"];
  const [checked, setChecked] = React.useState(Array(checkboxLabels.length).fill(true));

  const handleParentChange = (event) => {
    setChecked(checked.map(() => event.target.checked));
  };

  const handleChildChange = (index) => (event) => {
    const newChecked = [...checked];
    newChecked[index] = event.target.checked;
    setChecked(newChecked);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // to simplify reading in the backend, we will send the CPD values as an string splitted by semicolon only with checked values
    const cpd = checked.map((value, index) => value ? checkboxLabels[index].split(" ")[0] : "").filter((value) => value !== "").join(";");

    const formData = {
      config_test_type: "contrast_sensitivity",
      config_eye: eye,
      config_contrast_sensitivity_cpd: cpd,
      config_user_id: uid,
    };
    const userId = currentUser.uid;

    axios
      .post(
        `${SERVER_URL}/server/dicoptpro-config/${userId}`,
        formData
      )
      .then((response) => {
        console.log("Form submitted successfully", response.data);

        handleSnackbar();
        updateLastTestDate(uid);
      })
      .catch((error) => {
        console.error("Error submitting form", error);
      });

    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };
  // endregion

  // region HTML
  return (
    <React.Fragment>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Typography variant="h4" gutterBottom>
          {t('contrastSensitivity.name')}
          <Button color="primary" variant="contained" onClick={handleClickOpenInstructions()} sx={{ ml: 3, borderRadius: 28 }}>
            {t('commonTest.instructions')}
          </Button>
        </Typography>
        <Grid container spacing={2}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              {t('commonTest.parametersTitle')}
              <IconButton onClick={handleClickOpenTestParam()} aria-label="delete" sx={{ ml: 3 }}>
                <InfoIcon color="action" />
              </IconButton>
            </Typography>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="glasses-select"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {t('common.eye')}
              </InputLabel>
              <Select
                value={eye}
                label="Eye"
                onChange={(e) => setEye(e.target.value)}
              >
                <MenuItem value="right">{t('common.right-o')}</MenuItem>
                <MenuItem value="left">{t('common.left-o')}</MenuItem>
                <MenuItem value="none">{t('common.both')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              {t('commonTest.optionsTitle')}
              <IconButton onClick={handleClickOpenOptionParam()} aria-label="delete" sx={{ ml: 3 }}>
                <InfoIcon color="action" />
              </IconButton>
            </Typography>

            {/* CPD */}
            <FormControl fullWidth variant="outlined" margin="dense" sx={{ ml: 5 }}>
              <Typography id="input-slider" gutterBottom>
                {t('contrastSensitivity.cpd')}
              </Typography>

              {/* CPD 
              <FormControlLabel
                label="CPDs"
                control={
                  <Checkbox
                    checked={checked.every(Boolean)}
                    indeterminate={checked.some(Boolean) && !checked.every(Boolean)}
                    onChange={handleParentChange}
                  />
                }
              />
              */}
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                ml: 3,
                maxWidth: '600px', // Set the maximum width for the entire row
                '& .MuiFormControlLabel-root': {
                  flex: '1 1 auto',
                  minWidth: '110px', // Adjust this value as needed
                },
                '@media (max-width: 600px)': {
                  flexDirection: 'column',
                },
              }}>
                {checkboxLabels.map((label, index) => (
                  <FormControlLabel
                    key={index}
                    label={label}
                    control={<Checkbox checked={checked[index]} onChange={handleChildChange(index)} />}
                    sx={{ ml: 2 }}
                  />
                ))}
              </Box>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ textAlignLast: "center" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!allFieldsValid()} // Disable the button if not all fields are valid
            sx={{ width: 200, alignSelf: "center", marginTop: 2 }}
          >
            {t('forms.submit')}
          </Button>
        </Box>
        {/* Divider and additional components */}
        <Divider sx={{ marginBottom: 5, marginTop: 5 }}></Divider>

        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          textAlign={"-webkit-center"}
        >
          <StartTestButton />
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          textAlign={"-webkit-center"}
        >
          <ContrastSensitivityBars contrastSensitivityStatus={contrastSensitivityStatus} />
        </Box>

        <Box sx={{ textAlignLast: "center", pt: 2 }}>
          <Button
            variant="contained"
            color="success"
            sx={{ width: 200, alignSelf: "center", marginTop: 2, mx: 2 }}
            onClick={startGeneratingPDF}
          >
            {t('pdfGeneration.downloadPDF')}
          </Button>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={10000}
          onClose={handleSnackbarClose}
          sx={{
            marginLeft: 15,
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>


      <Dialog
        open={openTestParam}
        onClose={handleCloseTestParam}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('commonTest.parametersTitle')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionTestParamRef}
            tabIndex={-1}
          >
            <p>
              <strong>{t("common.eye")}:</strong> {t('commonTest.eyeParameter1')}
              <ul>
                <li><em>{t("common.right-o")}:</em> {t('commonTest.parameterRight')}</li>
                <li><em>{t("common.left-o")}:</em> {t('commonTest.parameterLeft')}</li>
                <li><em>{t("common.both")}:</em> {t('commonTest.parameterBino')}</li>
              </ul>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTestParam}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openOptionParam}
        onClose={handleCloseOptionParam}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('commonTest.optionsTitle')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionOptionParamRef}
            tabIndex={-1}
          >
            <p>
              <strong>{t('contrastSensitivity.cpd')}</strong>: {t('contrastSensitivity.optionCPD')}
              <ul>
                <li>1.5 CPD</li>
                <li>3 CPD</li>
                <li>6 CPD</li>
                <li>12 CPD</li>
              </ul>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOptionParam}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openInstructions}
        onClose={handleCloseInstructions}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('commonTest.instructions')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionInstructionsRef}
            tabIndex={-1}
          >
            <p>
              {t('contrastSensitivity.instructions1')}
            </p>
            <p>
              {t('contrastSensitivity.instructions2')}
            </p>
            <p>
              {t('contrastSensitivity.instructions3')}
            </p>
            <p>
              <strong>{t('commonTest.controls')}:</strong> {t("commonTest.controlData")}
              <ul>
                <li><em>{t("commonTest.buttonB")}:</em> {t("commonTest.backMainMenu")}</li>
                <li><em>{t("commonTest.buttonTrigger")}:</em> {t("contrastSensitivity.trigger")}</li>
              </ul>
            </p>

            <Grid container spacing={2}>
              {/* Left Column */}
              <Grid item xs={6} >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img height={350} src={FrontJoystick} alt="Logo" />;
                </Box>
              </Grid>
              <Grid item xs={6} >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img height={350} src={SideJoystick} alt="Logo" />;
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6" gutterBottom>
                    <strong>{t("commonTest.controlRight")}</strong>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInstructions}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>


      <ContrastSensitivityPDF
        isGenerating={generatingPDF}
        stopGenerating={stopGeneratingPDF}
        userUID={currentUser.uid}
        patientUID={uid}
        contrastData={contrastSensitivityStatus}
        contrastEye={contrastSensitivityStatus?.status_eye === "right" ? 1 : contrastSensitivityStatus?.status_eye === "left" ? 0 : 2}
      >
      </ContrastSensitivityPDF>
    </React.Fragment>
  );
};

export default ContrastSensitivityForm;
