import React, { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../../providers/AuthContext";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useParams, useNavigate, Outlet } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Select,
  Menu,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PatientDrawerComponent from "../../components/PatientDrawerComponent";
import axios from "axios";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LanguageIcon from "@mui/icons-material/Language";

import { useTranslation } from 'react-i18next';

// Custom styling for the Select component
const CustomSelect = styled(Select)({
  "& .MuiSelect-select": {
    paddingLeft: "8px",
    paddingRight: "24px", // Make room for the dropdown arrow
    color: "black", // Change text color to black
    "&:focus": {
      backgroundColor: "transparent", // Removes the background color on focus
    },
  },
  "& .MuiSvgIcon-root": {
    color: "black", // Change arrow icon color to black
  },
  "& .MuiInput-underline:before": {
    borderBottom: "none", // Hide underline by default
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none", // Hide underline on hover
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none", // Hide underline when focused
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none", // Remove border for Outlined variant
  },
  "&:hover": {
    ".MuiOutlinedInput-notchedOutline": {
      border: "none", // Ensure no border on hover for Outlined variant
    },
  },
  "&& .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input": {
    padding: "10px 32px 10px 12px", // Adjust padding for Outlined variant to align text properly
  },
});

export default function Patient() {
  const navigate = useNavigate();
  const { uid } = useParams();
  const [patientSelected, setPatientSelected] = useState(uid);
  const [patientsList, setPatientsList] = useState([]);
  const [patientIndex, setPatientIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout, currentUser } = useContext(AuthContext);
  const [anchorLang, setAnchorLagn] = useState(null);


  const [loading, setLoading] = useState(true);

  const { t, i18n } = useTranslation();

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {

    const userId = currentUser.uid;
    const fetchData = async () => {
      try {
        // Realizar la petición a axios para comprobar los datos necesarios
        const response = await axios.get(`${SERVER_URL}/server/patients/${userId}`);

        const patientsArray = Object.keys(response.data).map((key) => ({
          uid: key,
          ...response.data[key],
        }));

        // finally check if the id is in the list of patients
        const uidChecked = patientsArray.some((patient) => patient.uid === uid);

        // Si la comprobación es exitosa, permitir el acceso a la página web
        if (uidChecked) {
          // Si la comprobación es exitosa, continuar con la renderización
          setPatientsList(patientsArray);
          setPatientSelected(uid);
          setPatientIndex(patientsArray.findIndex((patient) => patient.uid === uid));
          setLoading(false);
        } else {
          navigate('/unauthorized'); // Redirigir a una página de error de autorización
        }
      } catch (error) {
        console.error('Error en la comprobación:', error);
        // En caso de error, redirigir a una página de error o a donde necesites
        navigate('/error');
      }
    };

    // Llamar a la función fetchData cuando el componente se monta
    fetchData();
  }, [currentUser, navigate, uid]); // Dependencia navigate para asegurarnos de que tenemos la versión más reciente del objeto navigate

  const handleLangMenuOpen = (event) => {
    setAnchorLagn(event.currentTarget);
  };

  const handleLangMenuClose = () => {
    setAnchorLagn(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleMenuClose(); // Close the menu after logout
    navigate("/"); // Redirect to login page after logout using navigate
  };

  const handleLocale = (selectedLocale) => {
    setAnchorLagn(null); // Close language menu
    i18n.changeLanguage(selectedLocale);
  }

  // Renderizar un mensaje de carga mientras se verifica la autorización
  if (loading) {
    return <div>{t("common.loading")}...</div>;
  }

  return (
    <React.Fragment>
      <Drawer
        anchor={"left"}
        variant="permanent"
        sx={{ width: 250, overflow: "hidden" }}
        ModalProps={{
          BackdropProps: {
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          },
        }}
      >
        <PatientDrawerComponent />
      </Drawer>
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
          color: "#041e49",
          backgroundColor: "#a4c5fb",
          zIndex: (theme) => theme.zIndex.drawer - 1,
        }}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 0.3 }} />
          <Typography variant="h6">
            {patientsList[patientIndex].name + " " + patientsList[patientIndex].surname}
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton color="inherit"
            component="a"
            href="https://www.canva.com/design/DAF2YAdQ4Mc/Vs4UynorRbULfW7hJcgPkg/view?"
            target="_blank"
            rel="noopener noreferrer">
            <MenuBookIcon />
          </IconButton>
          <IconButton color="inherit" onClick={handleLangMenuOpen}>
            <LanguageIcon />
          </IconButton>
          <Menu
            anchorEl={anchorLang}
            open={Boolean(anchorLang)}
            onClose={handleLangMenuClose}
          >
            <MenuItem onClick={() => handleLocale("en")}>
              {t('language.english')}
            </MenuItem>
            <MenuItem onClick={() => handleLocale("es")}>
              {t('language.spanish')}
            </MenuItem>
          </Menu>
          <IconButton color="inherit" onClick={handleMenuOpen}>
            <AccountCircleIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleLogout}>{t('forms.logOut')}</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          marginLeft: `${250}px`,
          marginTop: 8,
          padding: 3,
          height: "100%",
        }}
      >
        <Outlet />
      </Box>
    </React.Fragment>
  );
}
