import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Box,
} from "@mui/material";
import { useTranslation } from 'react-i18next';

export default function PDFForm({ open, onClose, onSubmit }) {
  const { t } = useTranslation();

  const [gender, setGender] = useState("male");
  const [glasses, setGlasses] = useState(true);
  const [eyeType, setEyeType] = useState("Decimal"); // [Fracción, Decimal]
  const [leftEye, setLeftEye] = useState("1.0");
  const [rightEye, setRightEye] = useState("1.0");
  const [eyeValueOptions, setEyeValueOptions] = useState([]);

  useEffect(() => {
    if (eyeType === "Fracción") {
      setEyeValueOptions([
        "<20/200",
        "20/200",
        "20/160",
        "20/125",
        "20/100",
        "20/80",
        "20/63",
        "20/50",
        "20/40",
        "20/32",
        "20/25",
        "20/20",
        "20/15",
        "20/13",
        "20/10",
        ">20/10"
      ]);
      setLeftEye("20/20");
      setRightEye("20/20");
    } 
    else if (eyeType === "Decimal") {
      setEyeValueOptions([
        "<0.05",
        "0.05",
        "0.1",
        "0.2",
        "0.3",
        "0.4",
        "0.5",
        "0.6",
        "0.7",
        "0.8",
        "0.9",
        "1.0",
        "1.1",
        "1.2",
        ">1.2",
      ]);
      setLeftEye("1.0");
      setRightEye("1.0");
    }
    else {
      setEyeValueOptions([
        t('pdfGeneration.noType')
      ]);
      setLeftEye(t('pdfGeneration.noType'));
      setRightEye(t('pdfGeneration.noType'));
    }
  }, [eyeType]);

  const handleSubmit = () => {
    const formData = { gender, glasses, leftEye, rightEye };
    onSubmit(formData);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          minWidth: "400px",
          width: "100%",
          maxWidth: "600px",
        },
        overflow: "auto",
      }}
    >
      <DialogTitle>{t('pdfGeneration.downloadPDF')}</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel htmlFor="gender-select">
              {t('forms.sex')}
            </InputLabel>
            <Select
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              label="Gender" // Make sure this matches the InputLabel
              inputProps={{
                name: "gender",
                id: "gender-select",
              }}
            >
              <MenuItem value="male">{t('forms.male')}</MenuItem>
              <MenuItem value="female">{t('forms.female')}</MenuItem>
              <MenuItem value="other">{t('forms.other')}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl
            fullWidth
            variant="outlined"
            margin="normal"
            sx={{ minWidth: 120 }}
          >
            <InputLabel
              htmlFor="glasses-select"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {t('pdfGeneration.glassesTitle')}
            </InputLabel>
            <Select
              value={glasses}
              onChange={(e) => setGlasses(e.target.value)}
              label={t('pdfGeneration.glassesTitle')} // This should match the text of InputLabel
              inputProps={{
                name: "glasses",
                id: "glasses-select",
              }}
              // Adjust the label width or apply additional styling as needed
              InputLabelProps={{
                shrink: true,
                style: {
                  maxWidth: "calc(100% - 32px)",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }, // Adjust padding based on your theme
              }}
            >
              <MenuItem value={true}>{t('common.yes')}</MenuItem>
              <MenuItem value={false}>{t('common.no')}</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box mb={2}>
          <FormControl
            fullWidth
            variant="outlined"
            margin="normal"
            sx={{ minWidth: 120 }}
          >
            <InputLabel
              htmlFor="AV Tipo"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {t('pdfGeneration.avEyeType')}
            </InputLabel>
            <Select
              value={eyeType}
              onChange={(e) => setEyeType(e.target.value)}
              label={t('pdfGeneration.avEyeType')} // This should match the text of InputLabel
              inputProps={{
                name: "AV Tipo",
                id: "AV-Tipo-select",
              }}
              // Adjust the label width or apply additional styling as needed
              InputLabelProps={{
                shrink: true,
                style: {
                  maxWidth: "calc(100% - 32px)",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }, // Adjust padding based on your theme
              }}
            >
              <MenuItem value="Fracción">
                {t('pdfGeneration.fraction')}
              </MenuItem>
              <MenuItem value="Decimal">
                {t('pdfGeneration.decimal')}
              </MenuItem>
              <MenuItem value="No indicar">
                {t('pdfGeneration.noType')}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Grid container spacing={2}>
          {/* AV Ojo Derecho */}
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel>{t('pdfGeneration.avEyeRight')}</InputLabel>
              <Select
                value={rightEye}
                label="AV Ojo Derecho"
                onChange={(e) => setRightEye(e.target.value)}
              >
                {eyeValueOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* AV Ojo Izquierdo */}
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel>{t('pdfGeneration.avEyeLeft')}</InputLabel>
              <Select
                value={leftEye}
                label="AV Ojo Izquierdo"
                onChange={(e) => setLeftEye(e.target.value)}
              >
                {eyeValueOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.cancel')}</Button>
        <Button onClick={handleSubmit}>{t('forms.submit')}</Button>
      </DialogActions>
    </Dialog>
  );
}
