import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Typography
} from "@mui/material";
import { useTranslation } from 'react-i18next';

import ChartComponent from "./Charts/ChartComponent";
import StartTestButton from "./buttons/StartTestButton";
import SkipPointButton from "./buttons/SkipPointButton";
import StartTestCalibrationButton from "./buttons/StartTestCalibrationButton";
import RepeatCalibrationButton from "./buttons/RepeatCalibrationButton";

import { AuthContext } from "../providers/AuthContext";
import { HeartbeatContext } from "../providers/HeartbeatContext";

import { ref, onValue, off } from 'firebase/database';
import { database } from '../firebaseConfig';

const CampimetryStateCardsComponent = () => {

  const { currentUser } = useContext(AuthContext);
  const { isAppAlive } = useContext(HeartbeatContext);
  const { uid } = useParams();
  const { t } = useTranslation();

  const [campimetryStatus, setCampimetryStatus] = React.useState({
    in_test: false,
    test_locked: false,
    remaining_stimulus: 0,
    crosshair_position: "center",
    eye_fixation_sensitivity: 0
  });
  const [configStatus, setConfigStatus] = React.useState({
    config_test_type: "none"
  });
  const [mainMessage, setMainMessage] = useState("");
  const [correctConfig, setCorrectConfig] = useState(false);

  // check status
  useEffect(() => {
    if (!isAppAlive) return;
    if (!currentUser || currentUser.uid === null || currentUser.uid === "") return;

    const campimetryStatusRef = ref(database, `campimetry_status/${currentUser.uid}`);
    const fetchCampimetryStatus = (snapshot) => {
      const campimetryVal = snapshot.val();
      if (campimetryVal !== null) {
        setCampimetryStatus(campimetryVal);
      }
      else {
        console.warn("No data received for status");
        setCampimetryStatus({
          in_test: false,
          test_locked: false,
          remaining_stimulus: 0,
          crosshair_position: "center",
          eye_fixation_sensitivity: 0
        });
      }
    };
    onValue(campimetryStatusRef, 
      (snapshot) => {
        try {
          // Procesa los datos
          fetchCampimetryStatus(snapshot);
        } catch (error) {
          console.error("Error in callback:", error);
        }
      },
      (error) => {
      console.error("Error establishing onValue connection:", error);
    });

    return () => {
      off(campimetryStatusRef, 'value', fetchCampimetryStatus);
    };
  }, [isAppAlive]);

  // use effect to read config only once
  useEffect(() => {
    if (!currentUser || currentUser.uid === null || currentUser.uid === "") return;

    const configRef = ref(database, `config/${currentUser.uid}`);
    const fetchConfig = (snapshot) => {
      let auxConfig = snapshot.val();
      if (auxConfig) {
        if (auxConfig.config_test_type === "campimetry") {
          setConfigStatus(auxConfig);
          if (auxConfig.config_user_id === uid) {
            setMainMessage(t('commonTest.currentConfiguration'));
            setCorrectConfig(true);
          }
          else {
            setMainMessage(t('commonTest.testUserDifferent'));
            setCorrectConfig(false);
          }
        }
        else {
          setMainMessage(t('commonTest.testDifferent'));
          setCorrectConfig(false);
        }
      }
      else {
        setCampimetryStatus({
          config_test_type: "none"
        });
        setMainMessage(t('commonTest.testNotLaunched'));
        setCorrectConfig(false);
      }

    };
    onValue(configRef, fetchConfig);

    return () => {
      off(configRef, 'value', fetchConfig);
    };
  }, [currentUser.uid]);

  const renderConfigStatus = () => {
    if (!correctConfig) {
      return (
        <Typography variant="body1">
          {t('commonTest.setSendForm')}
        </Typography>
      );
    }

    const typos = [];

    // let check all variables in configStatus
    // config_eye
    let aux_eye = t('common.left-o');
    if (configStatus.config_eye === "right") {
      aux_eye = t('common.right-o');
    }
    else if (configStatus.config_eye === "none") {
      aux_eye = t('common.both');
    }
    typos.push(
      <Typography variant="body1">
        {t('common.eye')}: <strong>{aux_eye}</strong>
      </Typography>
    );

    // config_campimetry_position
    let aux_position = "";
    if (configStatus.config_campimetry_position === "peripherical_complete_120") {
      aux_position = t('campimetry.pherifericaltwenty');
    }
    else if (configStatus.config_campimetry_position === "peripherical_complete_110") {
      aux_position = t('campimetry.pherifericalten');
    }
    else if (configStatus.config_campimetry_position === "center_30_degrees") {
      aux_position = t('campimetry.center') + " - " + t('campimetry.thirtyDegrees');
    }
    else if (configStatus.config_campimetry_position === "center_20_degrees") {
      aux_position = t('campimetry.center') + " - " + t('campimetry.twentyDegrees');
    }
    else if (configStatus.config_campimetry_position === "center_10_degrees") {
      aux_position = t('campimetry.center') + " - " + t('campimetry.tenDegrees');
    }
    else if (configStatus.config_campimetry_position === "up") {
      aux_position = t('common.down') + " - " + t('campimetry.from30to60Degrees');
    }
    else if (configStatus.config_campimetry_position === "down") {
      aux_position = t('common.up') + " - " + t('campimetry.from30to60Degrees');
    }
    else if (configStatus.config_campimetry_position === "left") {
      aux_position = t('common.right') + " - " + t('campimetry.from30to60Degrees');
    }
    else if (configStatus.config_campimetry_position === "right") {
      aux_position = t('common.left') + " - " + t('campimetry.from30to60Degrees');
    }
    else if (configStatus.config_campimetry_position === "up_25_degrees") {
      aux_position = t('common.down') + " - " + t('campimetry.from30to55Degrees');
    }
    else if (configStatus.config_campimetry_position === "down_25_degrees") {
      aux_position = t('common.up') + " - " + t('campimetry.from30to55Degrees');
    }
    else if (configStatus.config_campimetry_position === "left_25_degrees") {
      aux_position = t('common.right') + " - " + t('campimetry.from30to55Degrees');
    }
    else if (configStatus.config_campimetry_position === "right_25_degrees") {
      aux_position = t('common.left') + " - " + t('campimetry.from30to55Degrees');
    }

    typos.push(
      <Typography variant="body1">
        {t('campimetry.position')}: <strong>{aux_position}</strong>
      </Typography>
    );

    // config_campimetry_contrast_pattern
    let aux_contrast = "";
    if (configStatus.config_campimetry_contrast_pattern === "0-1") {
      aux_contrast = t('campimetry.ceroStrategy');
    }
    else if (configStatus.config_campimetry_contrast_pattern === "4-2-1") {
      aux_contrast = "4-2-1";
    }
    else if (configStatus.config_campimetry_contrast_pattern === "high-medium-low") {
      aux_contrast = t('campimetry.highMediumLow');
    }
    typos.push(
      <Typography variant="body1">
        {t('campimetry.contrastPattern')}: <strong>{aux_contrast}</strong>
      </Typography>
    );

    // config_eye_fixation_sensitivity
    typos.push(
      <Typography variant="body1">
        {t('campimetry.eyeFixationSensitivity')}: <strong>{configStatus.config_eye_fixation_sensitivity}</strong>
      </Typography>
    );

    // config_calibration_required
    typos.push(
      <Typography variant="body1">
        {t('campimetry.calibration')}: <strong>{configStatus.config_calibration_required ? t('common.yes') : t('common.no')}</strong>
      </Typography>
    );

    // config_scotoma_help
    typos.push(
      <Typography variant="body1">
        {t('campimetry.scotomaHelp')}: <strong>{configStatus.config_scotoma_help ? t('common.yes') : t('common.no')}</strong>
      </Typography>
    );

    // config_campimetry_stimulus_size
    let aux_stimulus = t('commonTest.normal');
    if (configStatus.config_campimetry_stimulus_size === 1) {
      aux_stimulus = t('commonTest.grandeI');
    }
    else if (configStatus.config_campimetry_stimulus_size === 2) {
      aux_stimulus = t('commonTest.grandeII');
    }
    typos.push(
      <Typography variant="body1">
        {t('commonTest.stimulusSize')}: <strong>{aux_stimulus}</strong>
      </Typography>
    );

    // config_campimetry_center_size
    let aux_center = t('commonTest.normal');
    if (configStatus.config_campimetry_center_size === 1) {
      aux_center = t('commonTest.grandeI');
    }
    else if (configStatus.config_campimetry_center_size === 2) {
      aux_center = t('commonTest.grandeII');
    }
    typos.push(
      <Typography variant="body1">
        {t('campimetry.centerSize')}: <strong>{aux_center}</strong>
      </Typography>
    );

    // add calibration options if calibration is true
    if (configStatus.config_calibration_required) {
      typos.push(
        <StartTestCalibrationButton />
      );
      typos.push(
        <RepeatCalibrationButton />
      );
    }
    // add skip section button if is peripherical
    if (configStatus.config_campimetry_position === "peripherical_complete_120" || configStatus.config_campimetry_position === "peripherical_complete_110") {
      typos.push(
        <SkipPointButton button_text={t("campimetry.skipSection")} skip_value={1}/>
      );
    }

    return typos.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };

  const renderCampimetryStatus = () => {
    if (!campimetryStatus) {
      return (
        <Typography variant="body1">
          {t('commonTest.testNotLaunched')}
        </Typography>
      );
    }

    const typos = [];

    if (campimetryStatus.in_test) {
      typos.push(
        <Typography variant="body1">
          {t('commonTest.testInProgress')}
        </Typography>
      );
    }
    else {
      typos.push(
        <Typography variant="body1">
          {t('campimetry.waitingCampimetry')}
        </Typography>
      );
    }
    typos.push(
      <Typography variant="body1">
        {t('campimetry.remainingPoints')}: {campimetryStatus.remaining_stimulus}
      </Typography>
    );

    /* ESTO NO FUNCIONA. HAY QUE REVISARLO
    if (campimetryStatus.in_test) {
      if (campimetryStatus.test_locked) {
        typos.push(
          <Typography variant="body1" color="error.main">
            <br />
            {t('campimetry.blocked')}
          </Typography>
        );
      }
      else {
        typos.push(
          <Typography variant="body1" color="success.main">
            <br />
            {t('campimetry.progress')}
          </Typography>
        );
      }
    }*/

    return typos.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  }

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        {/* Existing Cards */}
        <Box display="flex" justifyContent="center" width="100%" p={1}>
          {/* First Card */}
          <Card sx={{ width: `calc(50% - 25px)`, marginRight: "25px" }}>
            <CardContent>
              <Typography variant="h6" component="h2">
                {mainMessage}
              </Typography>
              {renderConfigStatus()}
            </CardContent>
          </Card>

          {/* Second Card */}
          <Card sx={{ width: `calc(50% - 25px)` }}>
            <CardContent>
              <Typography variant="h6" component="h2">
                {t('campimetry.state')}
              </Typography>
              {renderCampimetryStatus()}
            </CardContent>
          </Card>
        </Box>

        <StartTestButton />
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        p={1}
        textAlign={"-webkit-center"}
      >
        <ChartComponent
          circleRadius={campimetryStatus.eye_fixation_sensitivity}
          center={campimetryStatus.crosshair_position}
        />
      </Box>
    </React.Fragment>
  );
};

export default CampimetryStateCardsComponent;
