import React, { useEffect, useState, useContext, useRef } from "react";
import { AuthContext } from "../../providers/AuthContext";
import { HeartbeatContext } from "../../providers/HeartbeatContext";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Button,
  Divider,
  Snackbar,
  Alert,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";
import html2canvas from 'html2canvas';
import jsPDF from "jspdf";

import { Stage, Layer, Circle, Line, Text } from "react-konva";

import { ref, update, onValue, off } from "firebase/database";
import { database } from "../../firebaseConfig";

// ICONS
import InfoIcon from '@mui/icons-material/Info';

import FrontJoystick from '../../assets/frontJoystick.png';
import SideJoystick from '../../assets/sideJoystick.png';
import logoImage from "../../assets/dicoptProLogo.png";
import StartTestButton from "../../components/buttons/StartTestButton";
import PDFHeader from "../../components/PDFdocuments/PDFHeader";

const ForiaForm = () => {
  const { currentUser, updateLastTestDate } = useContext(AuthContext);
  const { isAppAlive } = useContext(HeartbeatContext);
  const { uid } = useParams();

  const [stimulusPos, setStimulusPos] = useState("far"); // far, near
  const [stimulusSize, setStimulusSize] = React.useState(0);
  const [foriaStatus, setForiaStatus] = React.useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isShowingValues, setIsShowingValues] = useState(false);

  const [openTestParam, setOpenTestParam] = React.useState(false);
  const [openOptionParam, setOpenOptionParam] = React.useState(false);
  const [openInstructions, setOpenInstructions] = React.useState(false);

  const [letGeneratePDF, setLetGeneratePDF] = useState(false);

  const { t } = useTranslation();
  const eyeGrid = useRef(null);
  const mainHeader = useRef(null);

  // #region use effect
  useEffect(() => {
    if (letGeneratePDF) {
      // Lógica para generar el PDF
      if (eyeGrid.current) {
        setTimeout(async () => {
          try {
            const canvas1 = await html2canvas(eyeGrid.current, { scale: 2, useCORS: true });
            const imgData1 = canvas1.toDataURL('image/png');

            // Crear un nuevo PDF
            const pdf = new jsPDF({
              orientation: "portrait",
              unit: "mm",
              format: "a4",
              compress: true,
            });

            pdf.html(mainHeader.current, {
              callback: function (doc) {
                doc.addImage(imgData1, 'PNG', 10, 50, 170, 114, "leftGrid", "FAST"); // Tamaño A4
                doc.addImage(logoImage, "PNG", 25, 280, 70, 9, "logo", "FAST");
                doc.save(`Informe_${uid}.pdf`);
              },
              x: 0,
              y: 0,
              width: 210, // Ancho del contenido en mm
              windowWidth: 210 * 3.75 // Ancho de la ventana en px
            });
            // Guardar el PDF
            //pdf.save(`Informe_${uid}.pdf`);
          } catch (error) {
            console.error('Error al exportar las secciones a imágenes:', error);
          } finally {
            setLetGeneratePDF(false);
          }
        }, 100);
      }
    }
  }, [letGeneratePDF]);

  useEffect(() => {
    if (!isAppAlive) return;
    if (!currentUser || currentUser.uid === null || currentUser.uid === "") return;

    const foriaStatusRef = ref(database, `weiss_status/${currentUser.uid}`);
    const fetchForiaStatus = (snapshot) => {
      const foriaVal = snapshot.val();
      if (foriaVal) {
        setForiaStatus(foriaVal);
      }
    };
    onValue(foriaStatusRef, fetchForiaStatus);

    return () => {
      off(foriaStatusRef, 'value', fetchForiaStatus);
    };
  }, [isAppAlive]);
  // #endregion


  const handleClickOpenTestParam = () => () => {
    setOpenTestParam(true);
  };

  const handleCloseTestParam = () => {
    setOpenTestParam(false);
  };

  const descriptionTestParamRef = useRef(null);
  React.useEffect(() => {
    if (openTestParam) {
      const { current: descriptionTestParam } = descriptionTestParamRef;
      if (descriptionTestParam !== null) {
        descriptionTestParam.focus();
      }
    }
  }, [openTestParam]);

  const handleClickOpenOptionParam = () => () => {
    setOpenOptionParam(true);
  };

  const handleCloseOptionParam = () => {
    setOpenOptionParam(false);
  };

  const descriptionOptionParamRef = useRef(null);
  React.useEffect(() => {
    if (openOptionParam) {
      const { current: descriptionOptionParam } = descriptionOptionParamRef;
      if (descriptionOptionParam !== null) {
        descriptionOptionParam.focus();
      }
    }
  }, [openOptionParam]);

  const handleClickOpenInstructions = () => () => {
    setOpenInstructions(true);
  };

  const handleCloseInstructions = () => {
    setOpenInstructions(false);
  };

  const descriptionInstructionsRef = useRef(null);
  React.useEffect(() => {
    if (openInstructions) {
      const { current: descriptionInstructions } = descriptionInstructionsRef;
      if (descriptionInstructions !== null) {
        descriptionInstructions.focus();
      }
    }
  }, [openInstructions]);

  const renderMainDots = () => {
    const dots = [];
    const originalXPos = [121, 121, 121, 226, 226, 226, 331, 331, 331];
    const originalYPos = [121, 226, 331, 121, 226, 331, 121, 226, 331];

    for (let i = 0; i < 9; i++) {
      dots.push(
        <Circle
          radius={2}
          x={originalXPos[i]}
          y={originalYPos[i]}
          stroke="red"
          strokeWidth={3}
        />
      );

      dots.push(
        <Line
          points={[
            originalXPos[i],
            originalYPos[i] - 20,
            originalXPos[i],
            originalYPos[i] + 20
          ]}
          stroke="red"
          strokeWidth={3}
        />
      );
    }

    return dots.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };

  // function to render the lines of the hess lancaster test
  const renderForiaLines = () => {
    const ratio = 333.33;
    const base = 226;

    // check if foriaStatus is null, if so, return null
    if (!foriaStatus) {
      return null;
    }

    if (foriaStatus?.actual_user !== uid) {
      return null;
    }

    const eyePos = foriaStatus?.right_eye_pos;
    const eyeRot = foriaStatus?.right_eye_rot;
    const actualPoint = foriaStatus?.actual_point_right;

    if (!eyePos) {
      return null;
    }

    // Create the first line with the first two points, the values are in the array where origin is 0 and end is 1 and the format is [x, y]
    // so we have to check if at least the first two points exist
    if (actualPoint < 1) {
      return null;
    }

    // loop to create the rest of the lines and store them in an array
    const lines = [];

    for (let i = 0; i < actualPoint; i++) {
      const actualDegree = 90 + Math.round(eyeRot[i].z);
      const newXOrigin = (20 * Math.cos(actualDegree * Math.PI / 180));
      const newYOrigin = (20 * Math.sin(actualDegree * Math.PI / 180));

      const actualXPoint = base + eyePos[i].x * ratio;
      const actualYPoint = base - eyePos[i].y * ratio;

      lines.push(
        <Circle
          radius={2}
          x={actualXPoint}
          y={actualYPoint}
          stroke="green"
          strokeWidth={3}
        />
      );

      lines.push(
        <Line
          points={[
            newXOrigin + (actualXPoint),
            - newYOrigin + (actualYPoint),
            - newXOrigin + (actualXPoint),
            newYOrigin + (actualYPoint),
          ]}
          stroke="green"
          strokeWidth={3}
        />
      );
    }

    // return the lines, but Each child in a list should have a unique "key" prop.
    return lines.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };

  // function to render the lines of the hess lancaster test
  const renderForiaText = () => {
    if (foriaStatus?.actual_user !== uid) {
      return null;
    }

    const ratio = 333.33;
    const base = 226;

    // check if foriaStatus is null, if so, return null
    if (!foriaStatus) {
      return null;
    }

    const eyePos = foriaStatus?.right_eye_pos;
    const eyeRot = foriaStatus?.right_eye_rot;
    const actualPoint = foriaStatus?.actual_point_right;

    if (!eyePos) {
      return null;
    }

    // Create the first line with the first two points, the values are in the array where origin is 0 and end is 1 and the format is [x, y]
    // so we have to check if at least the first two points exist
    if (actualPoint < 1) {
      return null;
    }

    // compare the original position with the value of the eyePos.
    const originalXPos = [226, 121, 226, 331, 331, 331, 226, 121, 121];
    const originalYPos = [226, 331, 331, 331, 226, 121, 121, 121, 226];

    // loop to check the values
    const texts = [];
    for (let i = 0; i < actualPoint; i++) {
      const actualXPoint = base + eyePos[i].x * ratio;
      const auxXPointDif = Math.round((actualXPoint - originalXPos[i]) / 4);
      let xText = "x: ";
      if (auxXPointDif >= 1) {
        xText = "EF: ";
      }
      if (auxXPointDif <= -1) {
        xText = "XF: ";
      }
      const difXPoint = Math.abs(auxXPointDif);

      const actualYPoint = base + eyePos[i].y * ratio;
      const auxYPointDif = Math.round((actualYPoint - originalYPos[i]) / 4);
      let yText = "y: ";
      if (auxYPointDif >= 1) {
        yText = "HF OD: ";
      }
      if (auxYPointDif <= -1) {
        yText = "HF OI: ";
      }
      const difYPoint = Math.abs(auxYPointDif);

      texts.push(
        <Text
          x={base + eyePos[i].x * ratio + 10}
          y={base - eyePos[i].y * ratio + 20}
          text={xText + difXPoint + "Δ"}
          fontSize={15}
          fill="black"
          fontFamily="system-ui"
          fontStyle="bold"
        />
      );
      texts.push(
        <Text
          x={base + eyePos[i].x * ratio + 10}
          y={base - eyePos[i].y * ratio + 35}
          text={yText + difYPoint + "Δ"}
          fontSize={15}
          fill="black"
          fontFamily="system-ui"
          fontStyle="bold"
        />
      );


      const actualDegree = 180 - Math.abs(180 - Math.round(eyeRot[i].z));
      texts.push(
        <Text
          x={base + eyePos[i].x * ratio + 10}
          y={base - eyePos[i].y * ratio + 50}
          text={actualDegree + "°"}
          fontSize={15}
          fill="black"
          fontFamily="system-ui"
          fontStyle="bold"
        />
      );
    }

    // return the lines, but Each child in a list should have a unique "key" prop.
    return texts.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };


  const handleSnackbar = () => {
    setSnackbarMessage(t('commonTest.success'));
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const allFieldsValid = () => {
    return stimulusPos !== "";
  };

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      config_test_type: "weiss",
      config_eye: "right",
      config_weiss_stimulus_type: stimulusPos,
      config_weiss_stimulus_size: stimulusSize,
      config_user_id: uid,
    };
    const userId = currentUser.uid;

    // esto es para resetear el status si el usuario cambia
    if (formData.config_user_id !== foriaStatus?.actual_user) {
      // escribimos, esta vez, directamente en realtime actualizando el status
      const db = database;
      const dbRef = ref(db, `weiss_status/${userId}`);
      update(dbRef, {
        actual_user: formData.config_user_id,
        actual_point_left_punctual: 0,
        actual_point_right_punctual: 0,
        actual_point_left_vertical: 0,
        actual_point_right_vertical: 0
      });
    }

    axios
      .post(
        `${SERVER_URL}/server/dicoptpro-config/${userId}`,
        formData
      )
      .then((response) => {
        console.log("Form submitted successfully", response.data);

        handleSnackbar();
        updateLastTestDate(uid);
      })
      .catch((error) => {
        console.error("Error submitting form", error);
      });

    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  // Inside your component's return statement
  return (
    <React.Fragment>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Typography variant="h4" gutterBottom sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          {t('foria.name')}
          <Button color="primary" variant="contained" onClick={handleClickOpenInstructions()} sx={{ ml: 3, borderRadius: 28 }}>
            {t('commonTest.instructions')}
          </Button>
        </Typography>

        <Grid container spacing={2}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              {t('commonTest.parametersTitle')}
              <IconButton onClick={handleClickOpenTestParam()} aria-label="delete" sx={{ ml: 3 }}>
                <InfoIcon color="action" />
              </IconButton>
            </Typography>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="glasses-select"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {t('foria.distance')}
              </InputLabel>
              <Select
                value={stimulusPos}
                label="StimulusPos"
                onChange={(e) => setStimulusPos(e.target.value)}
              >
                <MenuItem value="far">{t('foria.far')}</MenuItem>
                <MenuItem value="near">{t('foria.close')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              {t('commonTest.optionsTitle')}
              <IconButton onClick={handleClickOpenOptionParam()} aria-label="delete" sx={{ ml: 3 }}>
                <InfoIcon color="action" />
              </IconButton>
            </Typography>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="stimulus-size"
                style={{
                  // Adjust the style to allow for wrapping and ensure adequate spacing
                  whiteSpace: "normal", // Allow the label to wrap onto multiple lines as needed
                  lineHeight: "1.4375em", // Standard line height to maintain vertical rhythm
                  paddingTop: "6px", // Add a bit of padding to the top to ensure wrapped text doesn't touch the input border
                }}
              >
                {t('commonTest.stimulusSize')}
              </InputLabel>
              <Select
                label={t('commonTest.stimulusSize')} // Ensure this matches the text of the InputLabel for proper alignment and sizing
                inputProps={{
                  name: "stimulus-size",
                  id: "stimulus-size",
                }}
                value={stimulusSize}
                onChange={(e) => setStimulusSize(e.target.value)}
              >
                <MenuItem value={0}>{t('commonTest.normal')}</MenuItem>
                <MenuItem value={1}>{t('commonTest.grandeI')}</MenuItem>
                <MenuItem value={2}>{t('commonTest.grandeII')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

        </Grid>

        <Box sx={{ textAlignLast: "center" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!allFieldsValid()} // Disable the button if not all fields are valid
            sx={{ width: 200, alignSelf: "center", marginTop: 2 }}
          >
            {t('forms.submit')}
          </Button>
        </Box>

        {/* Divider and additional components */}
        <Divider sx={{ marginBottom: 5, marginTop: 5 }}></Divider>

        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          textAlign={"-webkit-center"}
        >
          <StartTestButton />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          textAlign={"-webkit-center"}
          sx={{ mt: 2 }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ paddingBottom: 20 }}
          >
            <div style={{ position: 'relative' }}>
              <Box
                width={451}
                height={451}
                sx={{ border: '1px solid black' }}
              >
                <Stage width={451} height={451} >
                  <Layer>
                    {renderMainDots()}
                    {renderForiaLines()}
                    {isShowingValues ? renderForiaText() : null}
                  </Layer>
                </Stage>
              </Box>
              <Box
                style={{
                  position: 'absolute',
                  alignItems: 'center',
                  textAlign: 'center',
                  right: -150,
                  top: '30%',
                  transform: 'translateY(-50%)',
                }}
              >
                <Typography variant="body1" display="flex" alignItems="center" fontSize="14px">
                  <svg width="10" height="30" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '8px', marginBottom: "8px" }}>
                    <line x1="5" y1="0" x2="5" y2="30" stroke="green" stroke-width="3" />
                    <circle cx="5" cy="15" r="4" fill="green" />
                  </svg>
                  {t("foria.right")}
                </Typography>

                <Typography variant="body1" display="flex" alignItems="center" fontSize="14px">
                  <svg width="10" height="30" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '8px', marginBottom: "8px" }}>
                    <line x1="5" y1="0" x2="5" y2="30" stroke="red" stroke-width="3" />
                    <circle cx="5" cy="15" r="4" fill="red" />
                  </svg>
                  {t("foria.left")}
                </Typography>

                <Typography variant="body1" display="flex" alignItems="center" fontSize="14px" marginBottom="8px" marginTop="8px">
                  <strong>XF</strong> = {t("foria.exoforia")}
                </Typography>
                <Typography variant="body1" display="flex" alignItems="center" fontSize="14px" marginBottom="8px">
                  <strong>EF</strong> = {t("foria.endoforia")}
                </Typography>
                <Typography variant="body1" display="flex" alignItems="center" fontSize="14px" marginBottom="8px">
                  <strong>HF</strong> = {t("foria.hiperforia")}
                </Typography>

              </Box>
            </div>
          </Box>
        </Box>

        <Box sx={{ textAlignLast: "center", pt: 2 }}>
          {/* Button to show or hide the values. When is clicked, change isShowingValues. Besides change variant between contained and outlined */}
          <Button
            variant={isShowingValues ? "outlined" : "contained"}
            color="primary"
            sx={{ width: 200, alignSelf: "center", marginTop: 2, mx: 2 }}
            onClick={() => setIsShowingValues(!isShowingValues)}
          >
            {/* if isShowingValues is true, show the text "Hide Values", otherwise show the text "Show Values" */}
            {isShowingValues ? t('commonTest.hideValues') : t('commonTest.showValues')}
          </Button>

          <Button
            variant="contained"
            color="success"
            sx={{ width: 200, alignSelf: "center", marginTop: 2, mx: 2 }}
            onClick={() => setLetGeneratePDF(true)}
          >
            {t("pdfGeneration.downloadPDF")}
          </Button>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={10000}
          onClose={handleSnackbarClose}
          sx={{
            marginLeft: 15,
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>

      <Dialog
        open={openTestParam}
        onClose={handleCloseTestParam}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('commonTest.parametersTitle')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionTestParamRef}
            tabIndex={-1}
          >
            <strong>{t("common.eye")}:</strong> {t("hessLancaster.parametersEye")}
            <ul>
              <li><em>{t("common.right-o")}:</em> {t("hessLancaster.paramRightEye")}</li>
              <li><em>{t("common.left-o")}:</em> {t("hessLancaster.paramLeftEye")}</li>
            </ul>
            <p>
              <strong>{t("forms.send")}:</strong> {t("commonTest.parameterSend")}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTestParam}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openOptionParam}
        onClose={handleCloseOptionParam}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('commonTest.optionsTitle')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionOptionParamRef}
            tabIndex={-1}
          >
            <strong>{t("hessLancaster.stimulusType")}:</strong>
            <ul>
              <li><em>{t("hessLancaster.point")}:</em> {t("hessLancaster.stimulusPoint")}</li>
              <li><em>{t("hessLancaster.vertical")}:</em> {t("hessLancaster.stimulusVertical")}</li>
            </ul>
            <strong>{t("forms.send")}:</strong> {t("commonTest.parameterSend")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOptionParam}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openInstructions}
        onClose={handleCloseInstructions}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('commonTest.instructions')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionInstructionsRef}
            tabIndex={-1}
          >
            <p>
              {t("hessLancaster.instructions2")}
            </p>
            <strong>{t("commonTest.controls")}:</strong> {t("commonTest.controlData")}
            <ul>
              <li><em>{t("commonTest.buttonA")}:</em> {t("hessLancaster.buttonA")}</li>
              <li><em>{t("commonTest.buttonB")}:</em> {t("commonTest.backMainMenu")}</li>
              <li><em>{t("commonTest.buttonTrigger")}:</em> {t("hessLancaster.trigger")}</li>
            </ul>
            <Grid container spacing={2}>
              {/* Left Column */}
              <Grid item xs={6} >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img height={350} src={FrontJoystick} alt="Logo" />;
                </Box>
              </Grid>
              <Grid item xs={6} >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img height={350} src={SideJoystick} alt="Logo" />;
                </Box>
              </Grid>
              <Grid xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6" gutterBottom>
                    <strong>{t("commonTest.controlRight")}</strong>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInstructions}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>

      {
        letGeneratePDF ?
          <React.Fragment>
            <div ref={mainHeader}>
              <PDFHeader />
              <Typography variant="h5" sx={{ ml: 5 }}>
                {t('foria.foria')}
              </Typography>
            </div>
            {/* Only dots left */}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              style={{ paddingBottom: 20, paddingRight: 30 }}
              ref={eyeGrid}
              width={700}
            >
              <div style={{ position: 'relative' }}>
                <Box
                  width={451}
                  height={451}
                  sx={{ border: '1px solid black' }}
                >
                  <Stage width={451} height={451}>
                    <Layer>
                      {renderMainDots()}
                      {renderForiaLines()}
                      {renderForiaText()}
                    </Layer>
                  </Stage>
                </Box>
                <Box
                  style={{
                    position: 'absolute',
                    alignItems: 'center',
                    textAlign: 'center',
                    right: -150,
                    top: '30%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  <Typography variant="body1" display="flex" alignItems="center" fontSize="14px">
                    <svg width="10" height="30" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '8px', marginBottom: "8px" }}>
                      <line x1="5" y1="0" x2="5" y2="30" stroke="green" stroke-width="3" />
                      <circle cx="5" cy="15" r="4" fill="green" />
                    </svg>
                    {t("foria.right")}
                  </Typography>

                  <Typography variant="body1" display="flex" alignItems="center" fontSize="14px">
                    <svg width="10" height="30" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '8px', marginBottom: "8px" }}>
                      <line x1="5" y1="0" x2="5" y2="30" stroke="red" stroke-width="3" />
                      <circle cx="5" cy="15" r="4" fill="red" />
                    </svg>
                    {t("foria.left")}
                  </Typography>

                  <Typography variant="body1" display="flex" alignItems="center" fontSize="14px" marginBottom="8px" marginTop="8px">
                    <strong>XF</strong> = {t("foria.exoforia")}
                  </Typography>
                  <Typography variant="body1" display="flex" alignItems="center" fontSize="14px" marginBottom="8px">
                    <strong>EF</strong> = {t("foria.endoforia")}
                  </Typography>
                  <Typography variant="body1" display="flex" alignItems="center" fontSize="14px" marginBottom="8px">
                    <strong>HF</strong> = {t("foria.hiperforia")}
                  </Typography>

                </Box>
              </div>
            </Box>
          </React.Fragment>
          :
          null
      }
    </React.Fragment>
  );
};

export default ForiaForm;
