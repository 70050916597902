import React, { useContext, useRef, useState } from "react";
import { AuthContext } from "../../providers/AuthContext";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  Slider,
  Button,
  Divider,
  TextField,
  Snackbar,
  Alert,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";
import CampimetryPDF from "../../components/PDFdocuments/CampimetryPDF";
import CampimetryStateCardsComponent from "../../components/CampimetryStateCardsComponent";

// ICONS
import InfoIcon from '@mui/icons-material/Info';
import FrontJoystick from '../../assets/frontJoystick.png';
import SideJoystick from '../../assets/sideJoystick.png';

const CampimetryForm = () => {
  const [campimetrySection, setCampimetrySection] = React.useState("");
  const [eye, setEye] = React.useState("");
  const [stimulusSize, setStimulusSize] = React.useState(0);
  const [centerSize, setCenterSize] = React.useState(0);
  const [calibrationRequired, setCalibrationRequired] = React.useState(true);
  const [eyeFixationSensitivity, setEyeFixationSensitivity] = React.useState(4);
  const [contrastPattern, setContrastPattern] = React.useState("");
  const [campimetryPosition, setCampimetryPosition] = React.useState("");
  const [escotoma, setescotoma] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const { uid } = useParams();
  const { currentUser, updateLastTestDate } = useContext(AuthContext);

  const [openTestParam, setOpenTestParam] = React.useState(false);
  const [openOptionParam, setOpenOptionParam] = React.useState(false);
  const [openInstructions, setOpenInstructions] = React.useState(false);

  const { t } = useTranslation();

  // region Test, Option and Instructions Dialogs
  const handleClickOpenTestParam = () => () => {
    setOpenTestParam(true);
  };

  const handleCloseTestParam = () => {
    setOpenTestParam(false);
  };

  const descriptionTestParamRef = useRef(null);
  React.useEffect(() => {
    if (openTestParam) {
      const { current: descriptionTestParam } = descriptionTestParamRef;
      if (descriptionTestParam !== null) {
        descriptionTestParam.focus();
      }
    }
  }, [openTestParam]);

  const handleClickOpenOptionParam = () => () => {
    setOpenOptionParam(true);
  };

  const handleCloseOptionParam = () => {
    setOpenOptionParam(false);
  };

  const descriptionOptionParamRef = useRef(null);
  React.useEffect(() => {
    if (openOptionParam) {
      const { current: descriptionOptionParam } = descriptionOptionParamRef;
      if (descriptionOptionParam !== null) {
        descriptionOptionParam.focus();
      }
    }
  }, [openOptionParam]);

  const handleClickOpenInstructions = () => () => {
    setOpenInstructions(true);
  };

  const handleCloseInstructions = () => {
    setOpenInstructions(false);
  };

  const descriptionInstructionsRef = useRef(null);
  React.useEffect(() => {
    if (openInstructions) {
      const { current: descriptionInstructions } = descriptionInstructionsRef;
      if (descriptionInstructions !== null) {
        descriptionInstructions.focus();
      }
    }
  }, [openInstructions]);
  // endregion

  // region Snackbar logic
  const handleSnackbar = () => {
    setSnackbarMessage(t('commonTest.success'));
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // endregion

  // region PDF generation
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const startGeneratingPDF = () => setGeneratingPDF(true);
  const stopGeneratingPDF = () => setGeneratingPDF(false);
  // endregion

  // region Form submission
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const handleSliderChange = (event, newValue) => {
    setEyeFixationSensitivity(newValue);
  };

  const handleInputChange = (event) => {
    setEyeFixationSensitivity(
      event.target.value === "" ? "" : Number(event.target.value)
    );
  };

  const handleBlur = () => {
    if (eyeFixationSensitivity < 1) {
      setEyeFixationSensitivity(1);
    } else if (eyeFixationSensitivity > 15) {
      setEyeFixationSensitivity(15);
    }
  };

  const handleCheckboxChange = (event) => {
    setescotoma(event.target.checked);
  };

  const handleCalibrationRequieredCheckbox = (event) => {
    setCalibrationRequired(event.target.checked);
  };

  const allFieldsValid = () => {
    if (campimetrySection === "pheriferical 120") {
      // check if campimetryPosition is not empty and is a valid value for this section
      if (campimetryPosition === "") {
        return false;
      }
      // campimetryPosition possible values are "peripherical_complete_120", "up", "down", "right", "left"
      if (!["peripherical_complete_120", "up", "down", "right", "left"].includes(campimetryPosition)) {
        return false;
      }
    } else if (campimetrySection === "pheriferical 110") {
      // check if campimetryPosition is not empty and is a valid value for this section
      if (campimetryPosition === "") {
        return false;
      }
      // campimetryPosition possible values are "peripherical_complete_110", "up_25_degrees", "down_25_degrees", "right_25_degrees", "left_25_degrees"
      if (!["peripherical_complete_110", "up_25_degrees", "down_25_degrees", "right_25_degrees", "left_25_degrees"].includes(campimetryPosition)) {
        return false;
      }
    } else if (campimetrySection === "central") {
      // check if campimetryPosition is not empty and is a valid value for this section
      if (campimetryPosition === "") {
        return false;
      }
      // campimetryPosition possible values are "center_30_degrees", "center_20_degrees", "center_10_degrees"
      if (!["center_30_degrees", "center_20_degrees", "center_10_degrees"].includes(campimetryPosition)) {
        return false;
      }
    }

    return campimetrySection && eye && contrastPattern && campimetryPosition;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      config_test_type: "campimetry",
      config_eye: eye,
      config_calibration_required: calibrationRequired,
      config_eye_fixation_sensitivity: eyeFixationSensitivity,
      config_campimetry_contrast_pattern: contrastPattern,
      config_campimetry_position: campimetryPosition,
      config_campimetry_stimulus_size: stimulusSize,
      config_campimetry_center_size: centerSize,
      config_scotoma_help: escotoma,
      config_user_id: uid,
    };
    const userId = currentUser.uid;

    axios
      .post(
        `${SERVER_URL}/server/dicoptpro-config/${userId}`,
        formData
      )
      .then((response) => {
        console.log("Form submitted successfully", response.data);

        handleSnackbar();
        updateLastTestDate(uid);
      })
      .catch((error) => {
        console.error("Error submitting form", error);
      });

    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const renderSubCampimetrySection = () => {
    if (campimetrySection === "pheriferical 120") {
      return (
        <FormControl fullWidth margin="normal">
          <InputLabel
            htmlFor="glasses-select"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {t('campimetry.position')}
          </InputLabel>
          <Select
            value={campimetryPosition}
            label="Campimetry position"
            onChange={(e) => setCampimetryPosition(e.target.value)}
          >
            <MenuItem value="peripherical_complete_120">
              {t('commonTest.complete')}
            </MenuItem>
            <MenuItem value="up">{t('common.down')}</MenuItem>
            <MenuItem value="down">{t('common.up')}</MenuItem>
            <MenuItem value="right">{t('common.left')}</MenuItem>
            <MenuItem value="left">{t('common.right')}</MenuItem>
          </Select>
        </FormControl>
      );
    } else if (campimetrySection === "pheriferical 110") {
      return (
        <FormControl fullWidth margin="normal">
          <InputLabel>{t('campimetry.position')}</InputLabel>
          <Select
            value={campimetryPosition}
            label="Campimetry Section"
            onChange={(e) => setCampimetryPosition(e.target.value)}
          >
            <MenuItem value="peripherical_complete_110">
              {t('commonTest.complete')}
            </MenuItem>
            <MenuItem value="up_25_degrees">{t('common.down')}</MenuItem>
            <MenuItem value="down_25_degrees">{t('common.up')}</MenuItem>
            <MenuItem value="right_25_degrees">{t('common.left')}</MenuItem>
            <MenuItem value="left_25_degrees">{t('common.right')}</MenuItem>
          </Select>
        </FormControl>
      );
    } else if (campimetrySection === "central") {
      return (
        <FormControl fullWidth margin="normal">
          <InputLabel>{t('campimetry.position')}</InputLabel>
          <Select
            value={campimetryPosition}
            label="Campimetry position"
            onChange={(e) => setCampimetryPosition(e.target.value)}
          >
            <MenuItem value="center_30_degrees">
              {t('campimetry.thirtyDegrees')}
            </MenuItem>
            <MenuItem value="center_20_degrees">
              {t('campimetry.twentyDegrees')}
            </MenuItem>
            <MenuItem value="center_10_degrees">
              {t('campimetry.tenDegrees')}
            </MenuItem>
          </Select>
        </FormControl>
      );
    } else {
      return (
        <FormControl fullWidth margin="normal">
          <InputLabel>{t('campimetry.position')}</InputLabel>
          <Select
            value={campimetryPosition}
            label="Campimetry position"
            onChange={(e) => setCampimetryPosition(e.target.value)}
          >
            <MenuItem value="peripherical_complete_120">
              {t('commonTest.complete')}
            </MenuItem>
            <MenuItem value="up">{t('common.up')}</MenuItem>
            <MenuItem value="down">{t('common.down')}</MenuItem>
            <MenuItem value="left">{t('common.left')}</MenuItem>
            <MenuItem value="right">{t('common.right')}</MenuItem>
          </Select>
        </FormControl>
      );
    }
  };
  // endregion

  // Inside your component's return statement
  return (
    <React.Fragment>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Typography variant="h4" gutterBottom sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          {t('campimetry.name')}
          <Button color="primary" variant="contained" onClick={handleClickOpenInstructions()} sx={{ ml: 3, borderRadius: 28 }}>
            {t('commonTest.instructions')}
          </Button>
        </Typography>
        <Grid container spacing={2}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              {t('commonTest.parametersTitle')}
              <IconButton onClick={handleClickOpenTestParam()} aria-label="delete" sx={{ ml: 3 }}>
                <InfoIcon color="action" />
              </IconButton>
            </Typography>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="glasses-select"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {t('common.eye')}
              </InputLabel>
              <Select
                value={eye}
                label="Eye"
                onChange={(e) => setEye(e.target.value)}
              >
                <MenuItem value="right">{t('common.right-o')}</MenuItem>
                <MenuItem value="left">{t('common.left-o')}</MenuItem>
                <MenuItem value="none">{t('common.both')}</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="glasses-select"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {t('campimetry.section')}
              </InputLabel>
              <Select
                value={campimetrySection}
                label="Campimetry section"
                onChange={(e) => setCampimetrySection(e.target.value)}
              >
                <MenuItem value="central">{t('campimetry.center')}</MenuItem>
                <MenuItem value="pheriferical 110">
                  {t('campimetry.pherifericalten')}
                </MenuItem>
                <MenuItem value="pheriferical 120">
                  {t('campimetry.pherifericaltwenty')}
                </MenuItem>
              </Select>
            </FormControl>
            {renderSubCampimetrySection()}
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="glasses-select"
                style={{
                  whiteSpace: "normal", // Change to 'normal' to allow the text to wrap
                  lineHeight: "1.4375em", // Maintain readability with standard line height
                  paddingTop: "6px", // Add padding to ensure text does not touch the border
                }}
              >
                {t('campimetry.contrastPattern')}
              </InputLabel>
              <Select
                value={contrastPattern}
                label={t('campimetry.contrastPattern')} // Ensure this matches the InputLabel for proper sizing
                onChange={(e) => setContrastPattern(e.target.value)}
                inputProps={{
                  name: "campimetry-contrast-pattern",
                  id: "glasses-select",
                }}
              >
                <MenuItem value="0-1">{t('campimetry.ceroStrategy')}</MenuItem>
                <MenuItem value="high-medium-low">
                  {t('campimetry.highMediumLow')}
                </MenuItem>
                <MenuItem value="4-2-1">4-2-1</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              {t('commonTest.optionsTitle')}
              <IconButton onClick={handleClickOpenOptionParam()} aria-label="delete" sx={{ ml: 3 }}>
                <InfoIcon color="action" />
              </IconButton>
            </Typography>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="stimulus-size"
                style={{
                  // Adjust the style to allow for wrapping and ensure adequate spacing
                  whiteSpace: "normal", // Allow the label to wrap onto multiple lines as needed
                  lineHeight: "1.4375em", // Standard line height to maintain vertical rhythm
                  paddingTop: "6px", // Add a bit of padding to the top to ensure wrapped text doesn't touch the input border
                }}
              >
                {t('commonTest.stimulusSize')}
              </InputLabel>
              <Select
                label={t('commonTest.stimulusSize')} // Ensure this matches the text of the InputLabel for proper alignment and sizing
                inputProps={{
                  name: "stimulus-size",
                  id: "stimulus-size",
                }}
                value={stimulusSize}
                onChange={(e) => setStimulusSize(e.target.value)}
              >
                <MenuItem value={0}>{t('commonTest.normal')}</MenuItem>
                <MenuItem value={1}>{t('commonTest.grandeI')}</MenuItem>
                <MenuItem value={2}>{t('commonTest.grandeII')}</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="center-size"
                style={{
                  whiteSpace: "normal", // Allow the label to wrap
                  lineHeight: "1.4375em", // Default line height from MUI theme
                  paddingTop: "6px", // Provide some padding to the top when label wraps
                }}
              >
                {t('campimetry.centerSize')}
              </InputLabel>
              <Select
                // Ensuring the `label` prop is set to match the `InputLabel` text
                label={t('campimetry.centerSize')}
                inputProps={{
                  name: "centersize",
                  id: "center-size",
                }}
                value={centerSize}
                onChange={(e) => setCenterSize(e.target.value)}
              >
                <MenuItem value={0}>{t('commonTest.normal')}</MenuItem>
                <MenuItem value={1}>{t('commonTest.grandeI')}</MenuItem>
                <MenuItem value={2}>{t('commonTest.grandeII')}</MenuItem>
              </Select>
            </FormControl>
            <Typography id="input-slider" gutterBottom>
              {t('campimetry.eyeFixationSensitivity')}
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Slider
                  aria-labelledby="input-slider"
                  onChange={handleSliderChange}
                  value={eyeFixationSensitivity}
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={1}
                  max={15}
                />
              </Grid>
              <Grid item>
                <TextField
                  value={eyeFixationSensitivity}
                  margin="dense"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: 15,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
              </Grid>
            </Grid>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="left"
              justifyContent="center"
            >
              <FormControlLabel
                variant="outlined"
                margin="dense"
                control={
                  <Checkbox
                    checked={escotoma}
                    onChange={handleCheckboxChange}
                    name="Ayuda escotoma central"
                  />
                }
                label={t('campimetry.scotomaHelp')}
              />
              <FormControlLabel
                variant="outlined"
                margin="dense"
                control={
                  <Checkbox
                    checked={calibrationRequired}
                    onChange={handleCalibrationRequieredCheckbox}
                    name="calibration"
                  />
                }
                label={t('campimetry.calibration')}
              />
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ textAlignLast: "center" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!allFieldsValid()} // Disable the button if not all fields are valid
            sx={{ width: 200, alignSelf: "center", marginTop: 2 }}
          >
            {t('forms.submit')}
          </Button>
        </Box>
        {/* Divider and additional components */}
        <Divider sx={{ marginBottom: 5, marginTop: 5 }}></Divider>
        <CampimetryStateCardsComponent />

        <Box sx={{ textAlignLast: "center" }}>
          <Button
            variant="contained"
            color="success"
            onClick={startGeneratingPDF}
            sx={{ width: 200, alignSelf: "center", marginTop: 2 }}
          >
            {t('pdfGeneration.downloadPDF')}
          </Button>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          sx={{
            marginLeft: 15,
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
      <Dialog
        open={openTestParam}
        onClose={handleCloseTestParam}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('commonTest.parametersTitle')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionTestParamRef}
            tabIndex={-1}
          >
            <strong>{t("common.eye")}:</strong> {t('commonTest.eyeParameter1')}
            <ul>
              <li><em>{t("common.right-o")}:</em> {t('commonTest.parameterRight')}</li>
              <li><em>{t("common.left-o")}:</em> {t('commonTest.parameterLeft')}</li>
              <li><em>{t("common.both")}:</em> {t('commonTest.parameterBino')}</li>
            </ul>
            <strong>{t("campimetry.section")}:</strong> {t("campimetry.parameterSection")}:
            <ul>
              <li><em>{t("common.central")}:</em> {t("campimetry.sectionCentral")}</li>
              <li><em>{t("campimetry.pherifericalten")}:</em> {t("campimetry.sectionPeripheral1")}</li>
              <li><em>{t("campimetry.pherifericaltwenty")}:</em> {t("campimetry.sectionPeripheral2")}</li>
            </ul>
            <strong>{t("campimetry.position")}:</strong> {t("campimetry.parameterPosition")}:
            <ul>
              <li>
                <em>{t("campimetry.positionCentral")}:</em>
                <ul>
                  <li>{t("campimetry.positionCentral10")}</li>
                  <li>{t("campimetry.positionCentral20")}</li>
                  <li>{t("campimetry.positionCentral30")}</li>
                </ul>
              </li>
              <li>
                <em>{t("campimetry.positionPeripheralTen")}:</em>
                <ul>
                  <li>{t("campimetry.positionPeripheralTenUp")}</li>
                  <li>{t("campimetry.positionPeripheralTenDown")}</li>
                  <li>{t("campimetry.positionPeripheralTenLeft")}</li>
                  <li>{t("campimetry.positionPeripheralTenRight")}</li>
                  <li>{t("campimetry.positionPeripheralTenComplete")}</li>
                </ul>
              </li>
              <li>
                <em>{t("campimetry.positionPeripheralTwenty")}:</em>
                <ul>
                  <li>{t("campimetry.positionPeripheralTwentyUp")}</li>
                  <li>{t("campimetry.positionPeripheralTwentyDown")}</li>
                  <li>{t("campimetry.positionPeripheralTwentyLeft")}</li>
                  <li>{t("campimetry.positionPeripheralTwentyRight")}</li>
                  <li>{t("campimetry.positionPeripheralTwentyComplete")}</li>
                </ul>
              </li>
            </ul>
            <strong>{t("campimetry.contrastPattern")}:</strong> {t("campimetry.parameterContrast")}:
            <ul>
              <li><em>{t("campimetry.ceroStrategy")}:</em> {t("campimetry.contrastCero")}</li>
              <li><em>{t("campimetry.highMediumLow")}:</em> {t("campimetry.contrast4intervals")}</li>
              <li><em>4 - 2 - 1:</em> {t("campimetry.contrast421")}</li>
            </ul>
            <strong>{t("forms.send")}:</strong> {t("commonTest.parameterSend")}

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTestParam}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openOptionParam}
        onClose={handleCloseOptionParam}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('commonTest.optionsTitle')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionOptionParamRef}
            tabIndex={-1}
          >
            <strong>{t("commonTest.stimulusSize")}:</strong> {t("campimetry.optionsStimulusSize")}:
            <ul>
              <li><em>{t("commonTest.normal")}:</em> {t("campimetry.stimulusSizeNormal")}</li>
              <li><em>{t("commonTest.grandeI")}:</em> {t("campimetry.stimulusSizeGrandeI")}</li>
              <li><em>{t("commonTest.grandeII")}:</em> {t("campimetry.stimulusSizeGrandeII")}</li>
            </ul>
            <strong>{t("campimetry.centerSize")}:</strong> {t("campimetry.optionsCenterSize")}:
            <ul>
              <li><em>{t("commonTest.normal")}:</em> {t("campimetry.centerSizeNormal")}</li>
              <li><em>{t("commonTest.grandeI")}:</em> {t("campimetry.centerSizeGrandeI")}</li>
              <li><em>{t("commonTest.grandeII")}:</em> {t("campimetry.centerSizeGrandeII")}</li>
            </ul>
            <p>
              <strong>{t("campimetry.eyeFixationSensitivity")}:</strong> {t("campimetry.optionsEyeFixationSensitivity")}
            </p>
            <p>
              <strong>{t("campimetry.scotomaHelp")}:</strong> {t("campimetry.optionsScotomaHelp")}
            </p>
            <p>
              <strong>{t("commonTest.calibration")}:</strong> {t("commonTest.optionsCalibration")}
            </p>
            <p>
              <strong>{t("forms.send")}:</strong> {t("commonTest.parameterSend")}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOptionParam}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openInstructions}
        onClose={handleCloseInstructions}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('commonTest.instructions')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionInstructionsRef}
            tabIndex={-1}
          >
            <p>
              {t("campimetry.instructions1")} <strong>{t("commonTest.buttonTrigger")}</strong>
            </p>
            <p>
              {t("campimetry.instructions2")}
            </p>
            <p>
              {t("campimetry.instructions3")}
            </p>
            <strong>{t("commonTest.controls")}:</strong> {t("commonTest.controlData")}
            <ul>
              <li><em>{t("commonTest.buttonB")}:</em> {t("commonTest.backMainMenu")}</li>
              <li><em>{t("commonTest.buttonTrigger")}:</em> {t("campimetry.trigger")}</li>
            </ul>

            <Grid container spacing={2}>
              {/* Left Column */}
              <Grid item xs={6} >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img height={350} src={FrontJoystick} alt="Logo" />;
                </Box>
              </Grid>
              <Grid item xs={6} >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img height={350} src={SideJoystick} alt="Logo" />;
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6" gutterBottom>
                    <strong>{t("commonTest.controlRight")}</strong>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInstructions}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>

      <CampimetryPDF
        isGenerating={generatingPDF}
        stopGenerating={stopGeneratingPDF}
        userUID={currentUser.uid}
        patientUID={uid}
      >
      </CampimetryPDF>

    </React.Fragment>
  );
};

export default CampimetryForm;
