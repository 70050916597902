import React from "react";

import { Box, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import { useTranslation } from 'react-i18next';

const DynamicVADirectionTable = ({
  dynamicVAStatus,
  heightLimit=2000
}) => {
  const { t } = useTranslation();

  const menuItemSymbols = {
    "down_up": "↑",
    "up_down": "↓",
    "left_right": "→",
    "right_left": "←",
    "downLeft_upRight": "↗",
    "downRight_upLeft": "↖",
    "upLeft_downRight": "↘",
    "upRight_downLeft": "↙",
    "parableLeft_right": "↷",
    "parableRight_left": "↶",
    "random": ""
  };
  const avValues = ["1.0", "0.9", "0.8", "0.7", "0.6", "0.5", "0.4", "0.3", "0.2", "0.1", "0.0"];

  function createData(velocity, av, hits, mistakes, skips) {
    return { velocity, av, hits, mistakes, skips };
  }

  function createTable() {
    if (!dynamicVAStatus || !dynamicVAStatus?.m_AnswerData) {
      return (
        <Typography variant="h6" gutterBottom>
          {t("forms.noData")}
        </Typography>
      );
    }
    const combinedData = {};

    // Combine elements with the same velocity and step
    dynamicVAStatus.m_AnswerData.forEach((element) => {
      const key = `${element.velocity}-${element.step}`;
      if (!combinedData[key]) {
        combinedData[key] = {
          velocity: element.velocity,
          av: avValues[element.step],
          hits: [],
          mistakes: [],
          skips: []
        };
      }

      if (element.is_skip) {
        combinedData[key].skips.push(menuItemSymbols[element.direction]);
      } else if (!element.correct) {
        combinedData[key].mistakes.push(menuItemSymbols[element.direction]);
      } else {
        combinedData[key].hits.push(menuItemSymbols[element.direction]);
      }
    });

    const rows = Object.values(combinedData).map(data =>
      createData(
        data.velocity,
        data.av,
        data.hits.join(", "),
        data.mistakes.join(", "),
        data.skips.join(", ")
      )
    );

    const mergedRows = rows.reduce((acc, row) => {
      const lastRow = acc[acc.length - 1];
      if (lastRow && lastRow.velocity === row.velocity) {
        lastRow.rowSpan += 1;
        lastRow.data.push(row);
      } else {
        acc.push({ ...row, rowSpan: 1, data: [row] });
      }
      return acc;
    }, []);


    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          {t("dva.movement")}: {t("dva." + dynamicVAStatus.direction)}
        </Typography>
        <TableContainer component={Paper} sx={{ maxWidth: 470, maxHeight: heightLimit, border: '1px solid grey' }}>
          <Table size="small" aria-label="a dense table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">{t("dva.axisVelocity")}</TableCell>
                <TableCell align="center">{t("dva.axisAV2")}</TableCell>
                <TableCell align="center">{t("dva.hits")}</TableCell>
                <TableCell align="center">{t("dva.misses")}</TableCell>
                <TableCell align="center">{t("dva.skips")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mergedRows.map((row, rowIndex) => (
                row.data.map((data, dataIndex) => (
                  <TableRow
                    key={`${row.velocity}-${dataIndex}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {dataIndex === 0 && (
                      <TableCell rowSpan={row.rowSpan} align="center">
                        {row.velocity}
                      </TableCell>
                    )}
                    <TableCell align="center"> {data.av} </TableCell>
                    <TableCell align="center"> {data.hits} </TableCell>
                    <TableCell align="center"> {data.mistakes} </TableCell>
                    <TableCell align="center"> {data.skips} </TableCell>
                  </TableRow>
                ))
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ marginTop: 2 }}
    >
      {createTable()}
    </Box>
  );
};
export default DynamicVADirectionTable;