import React, { useState, useContext } from "react";
import { AuthContext } from "../../providers/AuthContext";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  Box,
  Dialog,
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

import { useTranslation } from 'react-i18next';

import { ref, push } from 'firebase/database';
import { database } from '../../firebaseConfig';

export default function AddPatientDialog({ isOpen, onClose }) {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState(""); // Changed from description to surname
  const [birthDate, setBirthDate] = useState(""); // Changed from age to birthDate
  const { currentUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state to true
    let fechaActual = new Date();

    // Obtener el año, mes y día
    let año = fechaActual.getFullYear();
    let mes = String(fechaActual.getMonth() + 1).padStart(2, '0'); // Los meses son 0-indexados, por lo que sumamos 1
    let día = String(fechaActual.getDate()).padStart(2, '0');

    // Formatear la fecha en YYYY-MM-DD
    let creationDate = `${año}-${mes}-${día}`;
    handleAddPatient({ name, surname, birthDate, creationDate }); // Use surname and birthDate in the submission
  };

  const handleAddPatient = async (patientData) => {
    const patientsRef = ref(database, `patients/${currentUser.uid}`);
    // We can also chain the two calls together
    await push(patientsRef,patientData);
    onClose(); // Hide the form after successful submission
    setIsLoading(false);
    setName("");
    setSurname(""); // Reset surname
    setBirthDate(""); // Reset birthDate
  };

  const allFieldsValid = () => {
    return name && surname && birthDate;
  };


  return (
    <Dialog onClose={onClose} open={isOpen}>
      <Card>
        <CardContent>
          <Typography variant="h6">{t('mainMenu.addPatient')}</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label={t('forms.name')}
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label={t('forms.surname')} // Changed label to Surname
              value={surname} // Bind to surname state
              onChange={(e) => setSurname(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label={t('forms.birthDate')} // Changed label to Birth Date
              type="date" // Change type to date for birth date selection
              value={birthDate} // Bind to birthDate state
              onChange={(e) => setBirthDate(e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true, // Ensure the label shrinks to accommodate the date input
              }}
            />
            <Box sx={{ textAlignLast: "center" }}>
              {
                isLoading ?
                  <CircularProgress />
                  :
                  <Button type="submit" variant="contained" color="primary" disabled={!allFieldsValid()}>
                    {t('mainMenu.addPatient')}
                  </Button>
              }
            </Box>
          </form>
        </CardContent>
      </Card>
    </Dialog>
  );
}
